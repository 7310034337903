import styled from 'styled-components';
import OrderComplete from './order-complete';
import Analytics from 'lib/user-analytics';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { actionClearMap } from 'store/Map/actions';

const OrderCompletePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClose = () => {
        dispatch(actionClearMap());
        Analytics.Event('Checkout', 'Clicked Close button on Checkout confirmation');
        navigate('/satellite');
    };

    return (
        <Container>
            <ContentHeader>
                <h3>Order Complete</h3>
            </ContentHeader>
            <ContentBody>
                <OrderComplete handleClose={onClose} />
            </ContentBody>
        </Container>
    );
};

export default OrderCompletePage;

const Container = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

const ContentHeader = styled.div`
    position: fixed;
    justify-content: space-between;
    top: 60px;
    height: 60px;
    width: 100vw;
    display: flex;
    background-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.primary.color};
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 10px;
    z-index: 1;

    h3 {
        color: ${(props) => props.theme.primary.color};
        justify-content: center;
        align-self: center;
        display: flex;
        font-size: ${(props) => props.theme.fontSize.large};
        font-weight: 600;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        height: 55px;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        padding-top: 0px;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }
`;

const ContentBody = styled.div`
    padding: 120px 10px 10px 10px;
    display: flex;
    justify-content: center;
`;
