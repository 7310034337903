import React, { useState } from 'react';
import styled from 'styled-components';
import Drawer from 'components/Drawer/drawer';
import DrawerContent from 'components/Drawer/drawer-content';
import DrawerHeader from 'components/Drawer/drawer-header';
import { useDispatch } from 'react-redux';
import { StyledButton } from 'components/Shared/Buttons/styled-button';
import ShoppingCartDrawer from 'components/ShoppingCart/shoppingcart-drawer';
import Analytics from 'lib/user-analytics';
import { Tooltip } from 'reactstrap';
import { actionClearMap } from 'store/Map/actions';
import SatelliteSelectProduct from './satellite-select-product';

const SatelliteImageSearch = () => {
    const dispatch = useDispatch();
    const [showToolTip, setShowToolTip] = useState(false);

    const resetAreaOfInterest = () => {
        dispatch(actionClearMap());
        Analytics.Event('Satellite search', 'Reset area of interest');
    };

    return (
        <React.Fragment>
            <Drawer>
                <DrawerHeader
                    title="Satellite Image Search"
                    subTitle="Select your product"
                    handleBack={resetAreaOfInterest}
                />
                <DrawerContent>
                    <Content>
                        <Tooltip
                            placement="top"
                            isOpen={showToolTip}
                            target="ResetOAI"
                            toggle={() => setShowToolTip(!showToolTip)}
                        >
                            Clear search results and select a different Area of Interest
                        </Tooltip>
                        <Button onClick={resetAreaOfInterest} id="ResetOAI">
                            Reset Order Window
                        </Button>

                        <SatelliteSelectProduct />
                    </Content>
                </DrawerContent>
            </Drawer>
            <ShoppingCartDrawer />
        </React.Fragment>
    );
};

export default SatelliteImageSearch;

const Content = styled.div`
    margin: 0 auto;
    padding: 12px;
    display: block;
    text-align: center;
    width: 100%;
    min-height: 130px;
`;

const Button = styled(StyledButton)`
    width: 100%;
    margin-bottom: 7px;
    text-transform: unset;
`;
