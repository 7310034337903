import React from 'react';
import styled from 'styled-components';

// TODO:  Landing page for mobile
const Mobile = () => {
    return (
        <MobileBackground>
            <h2>Pics from Space is only available on a desktop</h2>
            <Gameboy src="/assets/landing-page/Device-with-GIF_landing-page.gif" />
            <WatermarkContainer>
                <Logo src="/assets/logos/soar-logo-WATERMARK.png" />
            </WatermarkContainer>
        </MobileBackground>
    );
};

export default Mobile;

const MobileBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/assets/landing-page/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h2 {
        text-align: center;
        margin-top: 40px;
        padding: 6px;
        color: ${(props) => props.theme.primary.color};
    }
`;

const Gameboy = styled.img`
    width: 300px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
`;
const WatermarkContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
`;

const Logo = styled.img`
    width: 130px;
    height: 30px;
`;
