import { combineReducers } from 'redux';
// Allowed location for using 'connected-react-router'
// eslint-disable-next-line no-restricted-imports
import { connectRouter } from 'connected-react-router';

import appReducer from './App/reducer';
import mapReducer from './Map/reducer';
import satelliteArchiveReducer from './Map/SatelliteArchive/reducer';
import satelliteOverlayReducer from './Map/SatelliteOverlay/reducer';
import purchasesReducer from './Purchases/reducer';
import tasksReducer from './Map/Tasks/reducer';
import shoppingCartReducer from './ShoppingCart/reducer';

import { history } from './history';

export const state = combineReducers({
    router: connectRouter(history),
    appDomain: appReducer,
    mapDomain: mapReducer,
    satelliteArchiveDomain: satelliteArchiveReducer,
    satelliteOverlayDomain: satelliteOverlayReducer,
    purchasesDomain: purchasesReducer,
    tasksDomain: tasksReducer,
    shoppingCartDomain: shoppingCartReducer,
});

export type AppState = ReturnType<typeof state>;

export interface Action {
    type: string;
    payload?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
