import { useDispatch, useSelector } from 'react-redux';
import LoginRegisterDialog from './login-register-dialog';
import { LoginModalMode } from './login-enum';
import { selectShowLoginDialog } from '../../store/App/selectors';
import { actionShowLoginDialog } from '../../store/App/actions';

const LoginDialog = () => {
    const showLoginDialog = useSelector(selectShowLoginDialog);
    const dispatch = useDispatch();

    if (showLoginDialog) {
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => dispatch(actionShowLoginDialog(false))}
            />
        );
    }

    return null;
};

export default LoginDialog;
