import React, { useState } from 'react';
import styled from 'styled-components';
import { SatelliteArchiveImageryDTO } from '../../../../../api/api-supplier';
import Analytics from 'lib/user-analytics';
import ImagePreview from 'components/ShoppingCart/image-preview';
//import SatelliteArchiveItemPrice from './Price/satellite-archive-item-price';

const moment = require('moment');

interface SatelliteArchivalItemProps {
    result: SatelliteArchiveImageryDTO;
    isSelected: boolean;
    setResultActive: (result: SatelliteArchiveImageryDTO) => void;
    setResultInActive: () => void;
    setResultMouseOver: (result: SatelliteArchiveImageryDTO) => void;
    setResultMouseOut: (result: SatelliteArchiveImageryDTO) => void;
}

const SatelliteArchiveItem = (props: SatelliteArchivalItemProps) => {
    const onMouseOver = () => {
        //if (!props.isSelected) {
        props.setResultMouseOver(props.result);
        //}
    };

    const onMouseOut = () => {
        if (!props.isSelected) {
            props.setResultMouseOut(props.result);
        }
    };

    const onClick = () => {
        if (props.isSelected) {
            props.setResultInActive();
            Analytics.Event('Satellite Search', 'Deselect Satellite Archive Item');
        } else {
            props.setResultActive(props.result);
            Analytics.Event('Satellite Search', 'Clicked Satellite Archive Item');
        }
    };

    return (
        <React.Fragment>
            <ItemContainer
                className={props.isSelected ? 'selected' : ''}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <ItemPreviewContainer>
                    <PreviewContainer>
                        <ImagePreview previewUrl={props.result.previewUrl} />
                    </PreviewContainer>

                    {/* <img src={
                                isError ? '/assets/image-unavailable/preview_unavailable.svg' : props.result.previewUrl
                            }
                            onError={() => setIsError(true)}
                        */}

                    <InfoContainer>
                        <InfoDate>{moment(props.result.date).format('MMM Do YYYY')}</InfoDate>
                        <InfoResolution>{`${props.result.resolutionInCm}cm/pixel`}</InfoResolution>
                        <InfoCloudCover>{props.result.cloud}% cloud cover</InfoCloudCover>
                        {/* <InfoNadir>{props.result.rollAngle}&#176; nadir</InfoNadir> */}
                    </InfoContainer>
                </ItemPreviewContainer>

                {/* {props.isSelected ? <SatelliteArchiveItemPrice /> : null} */}
            </ItemContainer>
        </React.Fragment>
    );
};

export default SatelliteArchiveItem;

const ItemContainer = styled.div`
    cursor: pointer;
    margin: 12px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-bottom: none;
    border-radius: ${(props) => props.theme.borderRadius};

    &:hover,
    &.selected {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
`;

const ItemPreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PreviewContainer = styled.div`
    div {
        img {
            width: 76px;
            height: 76px;
            border-top-left-radius: ${(props) => props.theme.borderRadius};
            border-bottom-left-radius: ${(props) => props.theme.borderRadius};
        }
        &.unavailable {
            img {
                height: auto;
            }
        }
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 8px;
`;

const InfoCloudCover = styled.span`
    color: ${(props) => props.theme.primary.text};
`;

const InfoDate = styled.div`
    color: ${(props) => props.theme.primary.text};
`;
const InfoResolution = styled.div`
    color: ${(props) => props.theme.primary.text};
`;
