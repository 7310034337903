import { LatLng } from 'leaflet';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import GeoUtil from '../../lib/geo-util';
import AutocompleteSearchBox from './autocomplete-search-box';
import ApiAutocomplete, { AutocompleteDTO, AutocompleteType, getCoordinateFromSearchTerm } from 'api/api-autocomplete';
import GoogleAnalytics from 'lib/google-analytics';

interface SoarAutocompleteProps {
    autocompleteType: AutocompleteType[];
    onLatlngSelected?: (position: LatLng) => void;
    onSearchClear?: () => void;
    placeholderAddress?: string;
}

const SoarAutocomplete = (props: SoarAutocompleteProps) => {
    const DEBOUNCE_TIME = 1000; //milliseconds

    const [searchResults, setSearchResults] = useState<AutocompleteDTO[]>([]);
    const [searchTerm] = useState(() => new Subject<string>());
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        const subscription = searchTerm
            .pipe(
                filter((value) => value.length > 2),
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((_) => setIsSearching(true)),
                switchMap((value) => {
                    setSearchResults([]);
                    if (value.length > 2) {
                        const coordinate = getCoordinateFromSearchTerm(value);
                        if (coordinate)
                            return Promise.resolve<AutocompleteDTO[]>([
                                {
                                    type: 'COORDINATE',
                                    title: `Coordinate: ${coordinate.lat}, ${coordinate.lng}`,
                                    latlng: coordinate,
                                },
                            ]);
                        else return ApiAutocomplete.autocomplete(value, props.autocompleteType);
                    } else return Promise.resolve<AutocompleteDTO[]>([]);
                })
            )
            .subscribe((next) => {
                setSearchResults(next);
                setIsSearching(false);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchResultSelected = (searchResult: AutocompleteDTO) => {
        if (searchResult.type === 'ADDRESS' && searchResult.geometryWKT) {
            GoogleAnalytics.Search(searchResult.title, 'search_result_suggestion');
            const latlng = GeoUtil.latLngFromWKT(searchResult.geometryWKT);
            if (props.onLatlngSelected) {
                props.onLatlngSelected(latlng);
            }
        } else if (searchResult.type === 'COORDINATE' && searchResult.latlng) {
            GoogleAnalytics.Search(searchResult.title, 'coordinate');
            if (props.onLatlngSelected) {
                props.onLatlngSelected(searchResult.latlng);
            }
        } else if (searchResult.type === 'ADDRESS' && searchResult.magicKey) {
            GoogleAnalytics.Search(searchResult.title, 'address');
            ApiAutocomplete.autocompleteFromMagic(searchResult.magicKey)
                .then((res) => {
                    if (res.length > 0) {
                        const latlng = GeoUtil.latLngFromWKT(res[0].geometryWKT);
                        if (props.onLatlngSelected) {
                            props.onLatlngSelected(latlng);
                        }
                    } else {
                        throw new Error('Suggestions has incorrect magicKey');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.info(`Suggestions is missing location`);
        }
        setSearchResults([]);
        searchTerm.next('');
    };

    return (
        <AutocompleteSearchBox
            searchResults={searchResults}
            onFetchRequest={(search) => {
                if (search.length < 3) {
                    setSearchResults([]);
                    props.onSearchClear?.();
                }
                searchTerm.next(search);
            }}
            onSearchResultSelected={(searchResult) => {
                handleSearchResultSelected(searchResult);
            }}
            placeholder={'Search for location or place...'}
            isLoading={isSearching}
            onEnterKey={(search) => {
                const coordinate = getCoordinateFromSearchTerm(search);
                if (coordinate && props.onLatlngSelected) {
                    searchTerm.next(`${coordinate.lat}, ${coordinate.lng}`);
                    GoogleAnalytics.Search(search, 'coordinate');
                    props.onLatlngSelected(coordinate);
                } else {
                    GoogleAnalytics.Search(search);
                }
            }}
        />
    );
};

export default SoarAutocomplete;
