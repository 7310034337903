import React from 'react';
import { Route, Routes as ReactRoutes, Navigate, useNavigationType, NavigationType } from 'react-router-dom';
import LandingPage from './components/LandingPage/landing-page';
import CGSTLLicenseAgreement from './components/PrivacyAgreement/cgstl-eula';
import { PrivacyAgreementPage } from './components/PrivacyAgreement/privacy-agreement';
import Skymap50TermsAndConditions from './components/PrivacyAgreement/skymap50-satellite-terms-and-conditions';
import { LoginModalMode } from './components/Registration/login-enum';
import PageNotFoundError from './components/Error/error-page-not-found';
import { TermsAndConditionsPage } from './components/PrivacyAgreement/terms-and-conditions';
import ShoppingCartPage from 'components/ShoppingCart/shoppingcart-page';
import CheckoutPage from 'components/ShoppingCart/checkout-page';
import MapLayout from 'components/MapView/map-layout';
import PageLayout from 'components/PrivacyAgreement/page-layout';
import DashboardLayout from 'components/Dashboard/dashboard-layout';
import AccountSettings from 'components/Dashboard/AccountSettings/account-settings';
import DashboardTermsAndCondition from 'components/Dashboard/dashboard-terms-and-condition';
import Logout from './components/Registration/logout';
import MapContainer from 'components/MapView/map-container';
import SatelliteOrders from 'components/Dashboard/SatelliteOrders/satellite-orders';
import LoginRegisterPage from 'components/Registration/login-register-page';
import OrderCompletePage from 'components/ShoppingCart/order-complete-page';
import DashboardPrivacyPolicy from 'components/Dashboard/dashboard-privacy-policy';

const Routes = () => {
    const navigationType = useNavigationType();

    return (
        <ReactRoutes>
            <Route path="/home" element={<Navigate replace to="/" />} />
            <Route path="/index.html" element={<Navigate replace to="/" />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/" element={<MapLayout />}>
                <Route path="satellite" element={<React.Fragment />} />
                <Route path="login" element={<LoginRegisterPage loginMode={LoginModalMode.LOGIN} />} />
                <Route
                    path="password_reset"
                    element={<LoginRegisterPage loginMode={LoginModalMode.PASSWORD_RESET} />}
                />
                <Route path="register" element={<LoginRegisterPage loginMode={LoginModalMode.REGISTER} />} />
                <Route
                    path="forgot_password"
                    element={<LoginRegisterPage loginMode={LoginModalMode.FORGOT_PASSWORD} />}
                />
            </Route>
            <Route path="/" element={<PageLayout />}>
                <Route path="cart" element={<ShoppingCartPage />} />
                {/* checkout shouldn't be refreshed as there's issue with stripe script */}
                <Route
                    path="checkout"
                    element={navigationType !== NavigationType.Pop ? <CheckoutPage /> : <Navigate replace to="/cart" />}
                />
                <Route path="order-complete" element={<OrderCompletePage />} />
                <Route path="terms" element={<TermsAndConditionsPage />} />
                <Route path="privacy" element={<PrivacyAgreementPage />} />
                <Route path="skymap50-terms" element={<Skymap50TermsAndConditions />} />
                <Route path="cg_sat_eula" element={<CGSTLLicenseAgreement />} />
            </Route>
            <Route path="/dashboard" element={<DashboardLayout />}>
                <Route index element={<Navigate replace to="satellite-queue" />} />
                <Route path="account-settings" element={<AccountSettings />} />
                <Route path="satellite-queue" element={<SatelliteOrders />} />
                <Route path="terms" element={<DashboardTermsAndCondition />} />
                <Route path="privacy" element={<DashboardPrivacyPolicy />} />
            </Route>
            <Route path="/logout" element={<Logout />} />
            <Route path="/task/:id" element={<MapContainer />} />

            <Route path="*" element={<PageNotFoundError />} />
        </ReactRoutes>
    );
};

export default Routes;
