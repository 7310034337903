import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autosuggest';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import './autocomplete-search-box.css';
import { useWindowHeight } from '@react-hook/window-size';
import { AutocompleteDTO } from 'api/api-autocomplete';

/**
 * Don't use this component directly.  It is responsible for rendering the autocomplete results
 * Use `soar-autocomplete.tsx` instead
 */
interface AutocompleteSearchBoxProps {
    placeholder?: string;
    searchResults: AutocompleteDTO[];
    onSearchResultSelected: (result: AutocompleteDTO) => void;
    onFetchRequest: (search: string) => void;
    onEnterKey: (search: string) => void;
    isLoading: boolean;
}

const AutocompleteSearchBox = (props: AutocompleteSearchBoxProps) => {
    const [value, setValue] = useState('');
    const [deviceHeight, setDeviceHeight] = useState<number | undefined>(undefined);

    const height = useWindowHeight();

    const NAVBAR_HEIGHT = 46;

    useEffect(() => {
        if (!deviceHeight) {
            setDeviceHeight(window.innerHeight);
        }
    }, [deviceHeight]);

    return (
        <SearchBox
            height={
                deviceHeight === height
                    ? 'max-content'
                    : `${(deviceHeight || 0) - window.innerHeight - NAVBAR_HEIGHT}px`
            }
            className="autocomplete-search-box"
            style={{ zIndex: 2000 }}
        >
            <Autocomplete
                suggestions={props.searchResults}
                onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                    props.onSearchResultSelected(suggestion);
                    setValue(suggestionValue);
                }}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    if (value.length > 2) props.onFetchRequest(value);
                    else props.onFetchRequest('');
                }}
                onSuggestionsClearRequested={() => {
                    //called when input is not in focus
                    // console.log('onSuggestionsClearRequested');
                }}
                getSuggestionValue={(suggestion) => {
                    return suggestion.title;
                }}
                renderSuggestion={(suggestion) => {
                    return (
                        <React.Fragment>
                            {suggestion.type === 'ADDRESS' || suggestion.type === 'COORDINATE' ? (
                                <img
                                    className="react-autosuggest-result-icon"
                                    src="/assets/marker-icon-black-outline.svg"
                                />
                            ) : null}

                            <span> {suggestion.title}</span>
                        </React.Fragment>
                    );
                }}
                renderInputComponent={(inputProps) => {
                    return (
                        <div className="inputContainer">
                            <i className="fa fa-search react-autosuggest-search-icon" />
                            {/* @ts-ignore */}
                            <input value={value} {...inputProps} />
                            {props.isLoading ? <i className="fa fa-spinner fa-spin react-autosuggest-spinner" /> : null}

                            {!props.isLoading && value ? (
                                <i
                                    className="fa fa-times react-autosuggest-close-search"
                                    onClick={() => {
                                        setValue('');
                                        props.onFetchRequest('');
                                    }}
                                />
                            ) : null}
                        </div>
                    );
                }}
                inputProps={{
                    spellCheck: false,
                    placeholder: props.placeholder ? props.placeholder : 'Search for a location or place...',
                    value: value,
                    onFocus: () => {
                        Analytics.Event('Search bar', 'Opened Search Tool');
                    },
                    onChange: (event, { newValue, method }) => {
                        //eslint-disable-line @typescript-eslint/no-unused-vars
                        setValue(newValue);
                    },
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            props.onEnterKey(value);
                        }
                    },
                }}
            />
        </SearchBox>
    );
};

export default AutocompleteSearchBox;

interface SearchBoxProps {
    height: string;
}

const SearchBox = styled.div<SearchBoxProps>`
    .react-autosuggest__suggestions-container--open {
        overflow-y: auto;

        @media only screen and (max-width: 600px) {
            height: ${(props) => props.height};
        }
    }
`;
