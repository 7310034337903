import styled from 'styled-components';
import React from 'react';

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <React.Fragment>
            <Button onClick={scrollToTop} title="Back to Top">
                ↑
            </Button>
        </React.Fragment>
    );
};

export default ScrollToTopButton;

const Button = styled.button`
    width: 50px;
    height: 40px;
    box-shadow: ${(props) => props.theme.boxShadow.shallow};
    border-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.primary.text};
    bottom: 10px;
    right: 10px;
    color: ${(props) => props.theme.primary.color};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.large};
`;
