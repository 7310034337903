import { SatelliteArchiveOrderDTO } from 'api/api-supplier';

export default class LocalStorageUtil {
    public static saveShoppingCart(orders: SatelliteArchiveOrderDTO[]) {
        try {
            localStorage.setItem('cartOrders', JSON.stringify(orders));
            return true;
        } catch {
            return false;
        }
    }

    public static getShoppingCart(): SatelliteArchiveOrderDTO[] {
        try {
            const cartOrders = localStorage.getItem('cartOrders');
            if (cartOrders) {
                return JSON.parse(cartOrders);
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public static getCountry(): string {
        try {
            const country = localStorage.getItem('country');
            return country ?? '';
        } catch {
            return '';
        }
    }

    public static saveCountry(country: string) {
        try {
            localStorage.setItem('country', country);
            return true;
        } catch {
            return false;
        }
    }
}
