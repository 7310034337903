import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionSatelliteMouseOut, actionSatelliteSetFeatureInactive } from 'store/Map/SatelliteArchive/actions';

import ApiSupplier, { SatelliteArchiveImageryDTO } from 'api/api-supplier';
import { selectSatelliteAOI, selectSatelliteAOIError } from 'store/Map/SatelliteArchive/selectors';

import Analytics from 'lib/user-analytics';
import { actionShoppingCartOpenDrawer } from 'store/ShoppingCart/actions';
import { selectOpenDrawerForOrder } from 'store/ShoppingCart/selectors';
import { Tooltip } from 'reactstrap';
import SatelliteLoading from './satellite-loading';
import { FlatButton } from 'components/Shared/Buttons/styled-button';
import SatelliteArchiveResults from './Archive/satellite-archive-results';
import GeoUtil from 'lib/geo-util';
import TurfGeoUtil from 'lib/turf-geo-util';

const SatelliteSelectProduct = () => {
    const dispatch = useDispatch();
    const selectedOrderType = useSelector(selectOpenDrawerForOrder);
    const satelliteAOI = useSelector(selectSatelliteAOI);
    const satelliteAOIError = useSelector(selectSatelliteAOIError);

    const [archiveResults, setArchiveResults] = useState<SatelliteArchiveImageryDTO[]>([]);
    const [isLoadingResults, setIsLoadingResults] = useState(false);
    const [satelliteSearchError, setSatelliteSearchError] = useState<Error | undefined>(undefined);
    const [showToolTip, setShowToolTip] = useState(false);
    const [allowNewCollect, setAllowNewCollect] = useState(true);

    useEffect(() => {
        if (satelliteAOI) {
            setIsLoadingResults(true);
            setArchiveResults([]);
            setSatelliteSearchError(undefined);

            if (satelliteAOIError) {
                setSatelliteSearchError(satelliteAOIError);
                return;
            }

            const wkt = GeoUtil.latLngBoundsToWKT(satelliteAOI);
            const aoiArea = TurfGeoUtil.areaWkt(wkt);
            setAllowNewCollect(aoiArea >= 50 && aoiArea < 1000);

            ApiSupplier.searchArchives(satelliteAOI, 'CGSTL', ['A50', 'A75', 'A100'])
                .then((res) => {
                    setArchiveResults(res);
                })
                .catch((err) => {
                    setSatelliteSearchError(err);
                })
                .finally(() => {
                    setIsLoadingResults(false);
                });
        }
    }, [satelliteAOI, satelliteAOIError, dispatch]);

    const handleNewCollect = () => {
        if (!allowNewCollect) return;

        dispatch(actionShoppingCartOpenDrawer(selectedOrderType === 'NEWCOLLECT' ? undefined : 'NEWCOLLECT'));
        dispatch(actionSatelliteSetFeatureInactive());
        dispatch(actionSatelliteMouseOut());
        Analytics.Event('Satellite Search', 'Click New Collect button');
    };

    const render = (): JSX.Element => {
        if (satelliteSearchError) {
            if (selectedOrderType) {
                return <React.Fragment />;
            }
            return (
                <ErrorContainer>
                    {satelliteSearchError.cause === 'aoi-restricted-area' ? (
                        <span>
                            😬Oops! Due to access restrictions Pics from Space is not available in this area at the
                            moment.
                            <br />
                            Please check back soon.
                        </span>
                    ) : (
                        <span>
                            <i className="fa fa-exclamation-circle mr-1"></i>
                            Sorry 😌 <br /> {satelliteSearchError.message}
                        </span>
                    )}
                </ErrorContainer>
            );
        }

        if (isLoadingResults) {
            return <SatelliteLoading />;
        }

        return (
            <>
                <NewCollectButton
                    className={`${selectedOrderType === 'NEWCOLLECT' ? 'selected' : ''} ${
                        !allowNewCollect ? 'disabled' : ''
                    }`}
                    onClick={handleNewCollect}
                    id="NewCollect"
                >
                    <ButtonImage src="/assets/floating-drawer-icons/icon-new-collect-brown.svg" />
                    <ButtonText>Take a new image from space!</ButtonText>
                </NewCollectButton>
                <Tooltip
                    placement="bottom"
                    isOpen={showToolTip}
                    target="NewCollect"
                    toggle={() => setShowToolTip(!showToolTip)}
                >
                    {allowNewCollect
                        ? ` Order new satellite image for the selected Order Window`
                        : `For new images, the Order Window area should have minimum 50km² and less than 1000km²`}
                </Tooltip>

                <SatelliteArchiveResults archiveResults={archiveResults} />
            </>
        );
    };

    return render();
};

export default SatelliteSelectProduct;

const NewCollectButton = styled(FlatButton)`
    cursor: pointer;
    width: 100%;
    margin: 12px 0;
    border: 2px solid ${(props) => props.theme.secondary.color}8a;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover,
    &.selected {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    &.disabled {
        opacity: 0.6;
    }
`;

const ButtonImage = styled.img`
    padding: 10px;
    width: 55px;
    height: 65px;
    border-top-left-radius: ${(props) => props.theme.borderRadius};
    border-bottom-left-radius: ${(props) => props.theme.borderRadius};
    margin-left: 5px;
`;

const ButtonText = styled.div`
    text-align: center;
    margin-right: 10px;
`;

const ErrorContainer = styled.div`
    margin: 15px 2px 5px;
    border: 1px solid #6e322b;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
`;
