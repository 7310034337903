import ApiUser from '../../api/api-user';
import { UserDTO } from '../../api/model';
import UserHelper from '../../lib/user-helper';
import { CognitoAuthResultType } from '../../lib/cognito-util';
import { actionTypes as at } from './constants';

export const actionShowLoginDialog = (show: boolean) => {
    return {
        type: at.SHOW_LOGIN_DIALOG,
        payload: show,
    };
};

export const actionGetMyProfile = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.API_GET_MY_PROFILE,
        });
        try {
            const result: UserDTO = await ApiUser.getMyUserProfile();
            dispatch(actionGetMyProfileSuccess(result));
        } catch (err) {
            dispatch(actionGetMyProfileError());
        }
    };
};

export const actionGetMyProfileSuccess = (user: UserDTO) => {
    return {
        type: at.API_GET_MY_PROFILE_SUCCESS,
        payload: user,
    };
};

const actionGetMyProfileError = () => {
    return {
        type: at.API_GET_MY_PROFILE_ERROR,
        payload: {},
    };
};

export const actionLoginSuccess = (authResult: CognitoAuthResultType) => {
    return async (dispatch, getState) => {
        UserHelper.saveToken(authResult);
        dispatch({
            type: at.API_LOGIN_SUCCESS,
        });
        try {
            const user: UserDTO = await ApiUser.getMyUserProfile();
            dispatch(actionGetMyProfileSuccess(user));
        } catch (err) {
            dispatch(actionGetMyProfileError());
        }
    };
};

export const actionLoginError = () => {
    return {
        type: at.API_LOGIN_ERROR,
        payload: {},
    };
};
