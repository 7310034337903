import styled from 'styled-components';

export interface SelectItem {
    value: string;
    text: string;
}

interface SelectDropdownProps {
    items: SelectItem[];
    value?: string;
    setValue: (value: string) => void;
}

const SelectDropdown = ({ items, value: selectedValue, setValue }: SelectDropdownProps) => {
    return (
        <Container>
            <Select
                value={selectedValue}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            >
                {items.map((t, index) => {
                    return (
                        <Option key={index} value={t.value}>
                            {t.text}
                        </Option>
                    );
                })}
            </Select>
        </Container>
    );
};

export default SelectDropdown;

const Container = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 35px;
    // border: 1px solid ${(props) => props.theme.primary.text}36;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const Select = styled.select`
    background-color: ${(props) => props.theme.primary.color};
    width: 100%;
    background-position: right;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline-width: 0;
    color: ${(props) => props.theme.primary.text};
    margin: 2px 8px 2px 0px;
    border: none;
    padding: 0px 7px;
    display: block;
    font-size: 1rem;
    cursor: pointer;

    background-image: url(/assets/arrows/down-arrow.svg);
    background-size: 15px;
    background-repeat: no-repeat;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme.secondary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.secondary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme.primary.color};
        border-radius: 4px;
    }
`;

const Option = styled.option`
    color: ${(props) => props.theme.primary.text};
    padding: 2px 4px;
`;

// const SelectIcon = styled.div`
//     img {
//         height: 16px;
//         margin-top: -6px;
//         opacity: 0.6;
//     }
//     height: 22px;
//     margin: 2px;
//     margin-right: 0px;
//     padding: 0 6px 0 5px;
//     display: inline-block;
// `;
