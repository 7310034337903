// Allowed location for using connected-react-router
// eslint-disable-next-line no-restricted-imports
import { routerMiddleware } from 'connected-react-router';
import { AnyAction, applyMiddleware, compose, createStore, Store, StoreEnhancer } from 'redux';
import ReduxThunk from 'redux-thunk';
import { history } from './history';
import { state } from './root-reducer';
import { uriEnhancer } from './uri-enhancers';

const routeMiddleware = routerMiddleware(history);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enhancer: StoreEnhancer<any> = compose(
    applyMiddleware(routeMiddleware),
    applyMiddleware(ReduxThunk),
    uriEnhancer
);

const store: Store<AnyAction> = createStore(state, undefined, enhancer);

// @ts-ignore
if (window.Cypress) {
    // @ts-ignore
    window.store = store;
}

export default store;
