import axios from 'axios';
import Api from './api';
import { LatLng } from 'leaflet';

export type AutocompleteType = 'ADDRESS' | 'SEARCH' | 'COORDINATE';

export interface AutocompleteDTO {
    type: AutocompleteType;
    title: string;
    description?: string;
    listingId?: number;
    geometryWKT?: string;
    extentWKT?: string;
    magicKey?: string;
    userId?: string;
    latlng?: LatLng;
}

let CANCEL_TOKEN = axios.CancelToken.source();

export default class ApiAutocomplete extends Api {
    public static autocomplete(text: string, types: AutocompleteType[], limit?: number): Promise<AutocompleteDTO[]> {
        const query = {
            text,
            types: types.join(','),
            limit: limit || 10,
        };
        return this.axios
            .get('/v1/platform/autocomplete', { params: query, cancelToken: CANCEL_TOKEN.token, cache: false })
            .then((res) => res.data.list);
    }

    public static autocompleteFromMagic(magicKey: string): Promise<AutocompleteDTO[]> {
        return this.axios
            .get(`/v1/platform/autocomplete/magic/${magicKey}`, { cache: false })
            .then((res) => res.data.list);
    }

    public static generateAutoCompleteCancelToken() {
        CANCEL_TOKEN = axios.CancelToken.source();
    }

    public static cancelAutoComplete(cancelReason: string) {
        // Allow a reason for cancellation to be added
        CANCEL_TOKEN.cancel(cancelReason);
        // Generate a new token each time a request is cancelled;
        ApiAutocomplete.generateAutoCompleteCancelToken();
    }
}

export const getCoordinateFromSearchTerm = (searchText: string): LatLng | null => {
    const search = searchText.replace('Coordinate:', '').trim();
    let split;

    //  Separated by a comma
    if (search.includes(',')) {
        split = search
            .replace(/\s+/, '')
            .split(',')
            .filter((word) => word != '');
    }

    //  Separated by white space
    else {
        split = search.split(/\s+/).filter((word) => word != '');
    }

    if (split.length == 2) {
        const lat = parseFloat(split[0]);
        const lng = parseFloat(split[1]);

        if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;

        if (lat && lat !== 0 && !isNaN(lat) && lng && lng !== 0 && !isNaN(lng)) {
            return new LatLng(lat, lng);
        }
    }

    return null;
};
