import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    Dropdown as BootstrapDropdown,
    DropdownItem as BootstrapDropdownItem,
    DropdownMenu as BootstrapDropdownMenu,
    DropdownToggle as BootstrapDropdownToggle,
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
} from 'reactstrap';
import styled from 'styled-components';
import { UserDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import UserHelper from 'lib/user-helper';

interface NavbarProfileProps {
    userProfile: UserDTO;
}

const NavbarProfile = (props: NavbarProfileProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // I want to be able to click either the toggle or the profile to toggle.
    // We need this timeout latch to prevent a close triggering twice.
    // Placing the toggle only on the NavItem breaks dismiss when clicking outside the dropdown
    const [disableToggle, setDisableToggle] = useState(false);

    const toggleDropdown = () => {
        if (!disableToggle) {
            setDropdownOpen(!dropdownOpen);
            setDisableToggle(true);
            setTimeout(() => {
                setDisableToggle(false);
            }, 100);
        }
    };

    const profileName = props.userProfile.name ? props.userProfile.name : 'Soar User';

    const handleViewDashboard = () => {
        Analytics.Event('Navigation bar', 'Clicked Dashboard');
    };

    const onClickLogout = () => {
        Analytics.Event('Navigation bar', 'Clicked sign out');
    };

    return (
        <React.Fragment>
            <NavItem onClick={toggleDropdown}>
                <Avatar
                    data-cy="profile-avatar"
                    alt="profile-image"
                    src={props.userProfile.avatarUrl || UserHelper.getDefaultAvatar(props.userProfile.userId as string)}
                />
                <NavLink>
                    <AvatarNameLink title={profileName}>{profileName}</AvatarNameLink>
                </NavLink>

                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle color="link" caret className="large-nav" />
                    <DropdownToggle color="link" className="small-nav" />

                    <DropdownMenu right>
                        <DropdownHeader header>
                            Signed in as
                            <br />
                            <Strong>{profileName}</Strong>
                        </DropdownHeader>
                        <DropdownItem divider />
                        <DropdownItemLink>
                            <NavLinkDropdown
                                onClick={handleViewDashboard}
                                to="/dashboard/satellite-queue"
                                tag={RRNavLinkStyled}
                            >
                                My Dashboard
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItem divider />
                        <DropdownItemLink>
                            <NavLinkDropdown onClick={onClickLogout} to="/logout" tag={RRNavLinkStyled}>
                                Sign out
                            </NavLinkDropdown>
                        </DropdownItemLink>
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        </React.Fragment>
    );
};

export default NavbarProfile;

const Dropdown = styled(BootstrapDropdown)``;

const DropdownToggle = styled(BootstrapDropdownToggle)`
    color: ${(props) => props.theme.primary.text};
    width: 8px;
    padding-left: 0;
    margin-left: -5px;
    margin-top: 12px;
    margin-bottom: 12px;

    &:hover {
        color: ${(props) => props.theme.primary.hover};
    }

    &:after {
        display: block;
    }

    &.large-nav {
        @media (max-width: 1000px) {
            visibility: hidden;
        }
    }

    &.small-nav {
        @media (min-width: 1000px) {
            visibility: hidden;
        }
    }
`;

const DropdownMenu = styled(BootstrapDropdownMenu)`
    width: 320px;
    right: 0;
    background: ${(props) => props.theme.secondary.color};
    border: 2px solid ${(props) => props.theme.primary.color};
    color: ${(props) => props.theme.secondary.text};
    margin-top: 28px;
    border-radius: 6px;
`;

const DropdownHeader = styled(BootstrapDropdownItem)`
    color: ${(props) => props.theme.white};
    font-weight: bolder;
    font-size: 1.2rem;
`;

const DropdownItem = styled(BootstrapDropdownItem)`
    font-size: 1.1rem;
    background: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.white};

    &.dropdown-divider {
        border-top: 1px solid ${(props) => props.theme.primary.color};
    }

    &:hover {
        background: ${(props) => props.theme.secondary.color};
        color: ${(props) => props.theme.secondary.hover};
    }
`;

const DropdownItemLink = styled(BootstrapDropdownItem)`
    padding: 0;
`;
const Avatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 3px solid ${(props) => props.theme.secondary.color};
    margin-top: 3px;
    @media (max-width: 1000px) {
        margin-right: -55px;
    }
`;

const AvatarNameLink = styled.span`
    color: ${(props) => props.theme.primary.text};
    @media (max-width: 1000px) {
        display: none;
    }
`;

const NavLink = styled(BootstrapNavLink)`
    color: ${(props) => props.theme.primary.text};
    cursor: pointer;

    // 1401px used so the overflow hidden does not interfere with the collapsing navigation
    @media (min-width: 1001px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavLinkDropdown = styled<any>(BootstrapNavLink)`
    font-size: 1.1rem;
    background: ${(props) => props.theme.secondary.color} !important;
    color: ${(props) => props.theme.secondary.text};
    padding: 0.25rem 1.5rem !important;
    text-decoration: none !important;

    &:hover {
        color: ${(props) => props.theme.white};
    }
`;

const RRNavLinkStyled = styled(RRNavLink)`
    padding: 0;
`;

const NavItem = styled(BootstrapNavItem)`
    color: ${(props) => props.theme.secondary.text};
    display: flex;
    user-select: none;
`;

const Strong = styled.strong`
    display: block;
    margin-top: 3px;
    color: ${(props) => props.theme.primary.color};
    overflow: hidden;
    text-overflow: ellipsis;
`;
