import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    borderRadius: '4px',
    white: '#FFFFFF',
    black: '#000000',
    primary: {
        color: '#E9D7AD',
        text: '#6E322B',
        hover: '#736a54',
    },
    secondary: {
        color: '#6E322B',
        text: '#E9D7AD',
        hover: '#E9D7AD',
    },
    fontSize: {
        default: '1.0rem',
        large: '1.4rem',
        small: '0.7rem',
    },
    boxShadow: {
        deep: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        shallow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
};
