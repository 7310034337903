import ApiSupplier from 'api/api-supplier';
import { OrderDetailsDTO } from 'api/model';
import React, { useEffect, useState } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import OpacitySlider from './opacity-slider';

const Tasks = () => {
    const map = useMap();
    const location = useLocation();
    const dispatch = useDispatch();
    const [orderDetails, setOrderDetails] = useState<OrderDetailsDTO | undefined>(undefined);
    const [opacity, setOpacity] = useState(100);

    // TODO: Make sure user is logged in
    // These can be done in satellite-drawer.tsx
    // TODO: Present some UI if the user doesn't have permission for that task
    // TODO: Loading spinner
    // TODO: Error handling

    useEffect(() => {
        const locationParts = location.pathname.split('/');
        if (locationParts.length === 3) {
            const taskId = location.pathname.split('/')[2];
            if (taskId && Number(taskId)) {
                ApiSupplier.getOrderDetails(Number(taskId))
                    .then((task) => {
                        setOrderDetails(task);
                        //some map function is running so wait 500ms
                        setTimeout(() => {
                            map.flyToBounds(task.boundingBox);
                        }, 500);
                    })
                    .catch(() => {
                        alert('An error occurred loading the task. Please try again later');
                    });
            }
        }
    }, [location, dispatch, map]);

    if (orderDetails && orderDetails.tileUrl) {
        return (
            <React.Fragment>
                <TileLayer
                    url={orderDetails.tileUrl}
                    bounds={orderDetails.boundingBox}
                    zIndex={1000}
                    tms={true}
                    opacity={opacity / 100}
                />
                <OpacitySlider onChange={setOpacity} />
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

export default Tasks;
