import React from 'react';
import styled from 'styled-components';

const PoweredBySoar = () => {
    return (
        <WatermarkContainer>
            <Logo src="/assets/logos/soar-logo-WATERMARK.png" />
        </WatermarkContainer>
    );
};

export default PoweredBySoar;

const WatermarkContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 10px;
    z-index: 88888;
`;

const Logo = styled.img`
    width: 110px;
    height: 24px;
`;
