import styled from 'styled-components';
import PageContainer from './page-container';
import React from 'react';

export const CGSTLLicenseAgreementPage = () => {
    return (
        <PageContainer header={<CGAgreementHeader />}>
            <LicenseAgreement />
        </PageContainer>
    );
};

export const CGAgreementHeader = () => {
    return (
        <React.Fragment>
            <h3>END USER LICENCE AGREEMENT FOR CG SATELLITE DATA PRODUCTS</h3>
            <p>
                Last updated: July 1<sup>st</sup>&nbsp;2023
            </p>
        </React.Fragment>
    );
};

const LicenseAgreement = () => {
    return (
        <Container>
            <Body>
                <div className="section">
                    <div>
                        <p>
                            The End-User Licence Agreement (“EULA”) refers to the Usage Agreement for JILIN-1 image data
                            products provided by CG Satellite for the end users, who shall be deemed to accept and agree
                            to be bound by clauses hereof this Agreement during the cause of:{' '}
                        </p>
                        <ol>
                            <li>paying for Product;</li>
                            <li>accepting delivery of Product;</li>
                            <li>downloading, installing, or manipulating the Product on any computer;</li>
                            <li>making any derivations based on the Product;</li>
                            <li>damaging or destroying the Product.</li>
                        </ol>
                        <p>This EULA shall be complied by and between the End-User and CG Satellite.</p>
                        <h3 className="privacy-description" style={{ fontWeight: 'bold' }}>
                            I TERMS
                        </h3>
                        The following terms have the following meaning within this EULA
                        <br />
                        <br />
                        <ul>
                            <b>"End User"</b> means an individual user, or legal commercial business organisation or
                            branches in its residential country, or the government organisation that provided with the
                            Product and accepts this EULA.
                            <br />
                            <b>"Affiliated User"</b> means the legal commercial business or government organisation that
                            participates in a project with the End-User shall be notified by the End-User and accepted
                            by CG Satellite.
                            <br />
                            <b>"Area of Interest (AOI)"</b> means the geographical location and area selected by the
                            End-User, who is hereby granted rights stipulated hereof this EULA.
                            <br />
                            <b>"Derivative Works"</b> means any derivative product or information developed by the
                            End-User from the Product, which does not contain any imagery data from the Product and is
                            irreversible and uncoupled from the source imagery data of the Product.
                            <br />
                            <b>"Extract:</b> means the extract of a Product or Value-Added Product
                            <br />
                            <b>"Imagery"</b> means JILIN-1 satellite imagery provided by CG Satellite to the End-User.
                            <br />
                            <b>"Product"</b> means imagery with regard to the AOI.
                            <br />
                            <b>"Value-Added Product (VAP)"</b> means Product developed by the End-User by modifying the
                            Product via technical manipulations based on image data of the Product delivered by CG
                            Satellite.
                        </ul>
                        <h3 className="privacy-description" style={{ fontWeight: 'bold' }}>
                            II Authorization Licence.
                        </h3>
                        <p>
                            Refers to the rights granted with regard to the AOI ONLY, not for any part of the IMAGERY
                            excluding the Area of Interest.
                        </p>
                        <p>
                            <b>2.1 Licenced Uses</b>
                        </p>
                        <p>
                            CG Satellite hereby grants the End-User with non-exclusive and non-transferable licence,
                            which permits the End-User when proceeding the following:
                        </p>
                        <ol type="a">
                            <li>
                                to install the Product on as many individual computers as needed in its premises
                                (including cloud resources that the End-User has at his disposal), including internal
                                computer network (with the express exclusion of the Internet, except as provided under
                                paragraph (g) below) for the Permitted Uses specified in this Article 2.1
                            </li>
                            <li>
                                to make an unlimited number of copies of the Product for the Permitted Uses specified in
                                this Article 2.1
                            </li>
                            <li>to use the Product for its own internal needs</li>
                            <li>
                                to alter or modify the Product to produce VAP and/or Derivative Works and use for its
                                internal needs
                            </li>
                            <li>
                                to make the Product and/or any VAP available to contractors and consultants, only for
                                use on behalf of the End-User, subject to such contractors and consultants agreeing in
                                writing.
                            </li>
                            <li>
                                to post one Extract on an Internet site, in an Internet-compatible image format (without
                                associated metadata), with the credit mentioned in 3.2 conspicuously displayed.
                            </li>
                            <li>
                                The posting of such Extract shall be used for End-User’s promotion purposes only and may
                                in no event allow downloading of the Extract posted or allow a third party to access the
                                Product or VAP as a stand-alone file, nor be used to distribute, sell, assign, dispose
                                of, lease, sublicence or transfer such Extract.
                            </li>
                            <li>
                                to print one Extract, and to distribute such print for promotion purposes only. Such
                                print shall include the credit as stand for in 3.4 conspicuously displayed
                            </li>
                            <li>
                                to share the Product and/or any VAP with Affiliated End-Users in the framework of a
                                Joint Project, subject to the following cumulative conditions:
                            </li>
                            <ul>
                                <li>the End-User shall have detailed in the order form accepted by CG Satellite:</li>
                                <ul>
                                    <li>the name, site address of each Affiliated End-User</li>
                                    <li>
                                        the details of the Joint Project in which the End-User and the Affiliated
                                        End-Users are cooperating and for which the Product is ordered
                                    </li>
                                </ul>
                                <li>
                                    the End-User shall guarantee that each Affiliated End-User complies with the
                                    provisions and restrictions provided in the present EULA and shall indemnify and
                                    hold CG Satellite harmless in connection thereto.{' '}
                                </li>
                            </ul>
                        </ol>
                        All rights not expressly granted by CG Satellite under the present Article 2.1 are hereby
                        retained by CG Satellite.
                        <br />
                        <p></p>
                        <b>2.2 Forbidden Uses</b>
                        <br />
                        <br />
                        The End-User recognizes and agrees that the Product is and shall remain the property of CG
                        Satellite and/or its licences and contains proprietary information of CG Satellite and thus is
                        provided to the End-User on a confidential basis.
                        <br />
                        The End-User shall not, and shall guarantee that any contractor or consultant engaged as per the
                        provisions of Article 2.1(e) or any AFFILIATED End-User does not:
                        <br />
                        <ol type="a">
                            <li>sell, rent or lease any Product or VAP</li>
                            <li>alter or remove any copyright notice on the Product</li>
                            <li>do anything not expressly permitted under Article 2.1</li>
                        </ol>
                        <p></p>
                        <h3 className="privacy-description" style={{ fontWeight: 'bold' }}>
                            III INTELLECTUAL PROPERTY RIGHTS
                        </h3>
                        3.1 The Product is protected by Chinese and international copyright laws.
                        <br />
                        <br />
                        3.2 The Product, VAP and/or Extract, when displayed and/or printed in accordance with the
                        Permitted Uses specified in Article 2.1 shall include the CG Satellite logo and the year of
                        acquisition, Distribution CG”.
                        <br />
                        <p></p>
                        <h3 className="privacy-description" style={{ fontWeight: 'bold' }}>
                            IV. WARRANTY - LIABILITY
                        </h3>
                        4.1 CG Satellite warrants that it has sufficient ownership rights in the Product to make the
                        Product available to the End-User under the terms hereof.
                        <br />
                        <br />
                        4.2 The Product is complex; CG Satellite does not warrant that the Product is free of bugs,
                        errors, defects or omissions, and that operation of the Product will be error- free or
                        uninterrupted nor that all non-conformities will or can be corrected. It does not warrant that
                        the Product shall meet the End-User’s requirements or expectations or shall be fit for the
                        End-User’s intended purposes. There are no express or implied warranties of fitness or
                        merchantability given in connection with the sale or use of this Product.
                        <br />
                        <br />
                        4.3 CG Satellite disclaims all other warranties or liabilities not expressly provided in
                        Articles 4.1 and 4.2.
                        <br />
                        <br />
                        4.4 In case the Product delivered directly by CG Satellite is substantially different from the
                        applicable technical specifications of the Product ordered or from the ordered area of interest
                        or if the medium on which the Product is supplied by CG Satellite to the End-User is defective,
                        as demonstrated by the End-User and accepted by CG Satellite, CG Satellite shall, at its sole
                        discretion and subject to prior return of the Product and all copies thereof to CG Satellite,
                        either replace the concerned Product or refund the price paid by the End-User for the Product.
                        Any such claim shall be notified to CG Satellite within seven days after delivery of the Product
                        by CG Satellite. After this period, the Product shall be considered as being compliant with the
                        specifications and unconditionally and irrevocably accepted by the End-User.
                        <br />
                        <br />
                        4.5 In no event shall CG Satellite, nor anybody having contributed to the development and/or
                        production and/or delivery of the Product, be liable for any claim, damage or loss incurred by
                        the End-User and AFFILIATED END-USERS, including without limitation indirect, compensatory,
                        consequential, incidental, special, incorporeal or exemplary damages arising out of the use of,
                        or inability to use, the Product and shall not be subject to legal action in this respect.
                        <br />
                        <br />
                        4.6 The financial cumulative liability of CG Satellite and of anybody having contributed to the
                        development and/or production and/or delivery of the Product shall not in any case exceed the
                        price paid by the End-User to CG Satellite for the Product from which such loss or damage
                        directly arose.
                        <br />
                        <h3 className="privacy-description" style={{ fontWeight: 'bold' }}>
                            IV. SUPPLEMENTARY
                        </h3>
                        5.1 This EULA is valid from the time of Delivery of the Product to the End User and shall not
                        expire.
                        <br />
                        <br />
                        5.2 CG Satellite may, in addition to all other remedies to which it may be entitled under this
                        EULA or at law, terminate immediately this EULA by notice in writing if the End-User or any
                        AFFILIATED End-User breaches any provision hereof. The End-User shall have no claim to any kind
                        of refund in this case. Upon termination, the End-User shall return to CG all the Product and
                        VAP.
                        <br />
                        <br />
                        5.3 CG Satellite disclaims all other warranties or liabilities not expressly provided in
                        Articles 4.1 and 4.2.
                        <br />
                        <br />
                        5.4 The End-User shall not transfer part or all of this EULA unless it has obtained CG
                        Satellite’s prior written consent.
                        <br />
                        <br />
                        5.5 In the event that any provision of this EULA is declared invalid or unenforceable, the
                        remaining provisions hereof shall be applicable.
                        <br />
                    </div>
                </div>
            </Body>
        </Container>
    );
};

export default CGSTLLicenseAgreementPage;

const Container = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

const Body = styled.div`
    margin: 30px 40px;

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
    }

    h3 {
        text-align: left;
        font-size: ${(props) => props.theme.fontSize.large};
        font-weight: 600;
        color: ${(props) => props.theme.primary.text};
        margin-top: 30px;
    }

    ul {
        list-styled: disc;
        padding-inline-start: 0px;
        a {
            color: ${(props) => props.theme.primary.text};
            font-weight: 600;
            text-decoration: underline;
        }
    }

    li {
        color: ${(props) => props.theme.primary.text};

        a {
            color: ${(props) => props.theme.primary.text};
            font-weight: 600;
            text-decoration: underline;
        }
    }
`;
