import styled from 'styled-components';

import { Modal, ModalBody } from 'reactstrap';
import ImageSlider from 'react-image-comparison-slider';

import Analytics from 'lib/user-analytics';
import { StyledButton } from 'components/Shared/Buttons/styled-button';
import { Products } from 'api/api-supplier';

interface ComparisonModalProps {
    isOpen: boolean;
    toggle: () => void;
    productKey?: string;
}

const ComparisonModal = ({ isOpen, toggle, productKey }: ComparisonModalProps) => {
    const product = productKey ? Products[productKey] : Products['CGA50'];
    const handleClose = () => {
        Analytics.Event('Image comparison', 'Clicked button to close');
        toggle();
    };

    type ProductPreviewDetail = {
        cmPixel: number;
        buttonText: string;
        labelLeft: string;
        labelRight: string;
        imageLeft: string;
        imageRight: string;
        url: string;
    };

    const comparisonImageDetails: ProductPreviewDetail[] = [
        {
            cmPixel: 100,
            buttonText: 'See 100cm per pixel sample on Soar',
            labelLeft: '100 cm/pixel',
            labelRight: '50 cm/pixel',
            imageLeft: '/assets/skymap50-comparison/cg-100cm-sample.png',
            imageRight: '/assets/skymap50-comparison/cg-50cm-sample.png',
            url: 'https://soar.earth/maps/18595',
        },
        {
            cmPixel: 75,
            buttonText: 'See 75cm per pixel sample on Soar',
            labelLeft: '100 cm/pixel',
            labelRight: '75 cm/pixel',
            imageLeft: '/assets/skymap50-comparison/cg-100cm-sample.png',
            imageRight: '/assets/skymap50-comparison/cg-75cm-sample.png',
            url: 'https://soar.earth/maps/18778',
        },
        {
            cmPixel: 50,
            buttonText: 'See 50cm per pixel sample on Soar',
            labelLeft: '100 cm/pixel',
            labelRight: '50 cm/pixel',
            imageLeft: '/assets/skymap50-comparison/cg-100cm-sample.png',
            imageRight: '/assets/skymap50-comparison/cg-50cm-sample.png',
            url: 'https://soar.earth/maps/18776',
        },
    ];

    const getProductPreviewDetails = (product): ProductPreviewDetail => {
        return (
            comparisonImageDetails.find((details) => details.cmPixel === product.cmPixel) || comparisonImageDetails[0]
        );
    };

    const selectedProduct = getProductPreviewDetails(product);
    return (
        <ModalContainer isOpen={isOpen} toggle={toggle}>
            <Body>
                <ImageContainer>
                    <ImageSlider
                        sliderWidth={3}
                        sliderColor="#E9D7AD"
                        handleColor="#6E322B"
                        handleBackgroundColor="#E9D7AD"
                        image1={selectedProduct.imageRight}
                        image2={selectedProduct.imageLeft}
                        rightLabelText={selectedProduct.labelRight}
                        leftLabelText={selectedProduct.labelLeft}
                    />
                </ImageContainer>
                <ButtonContainer>
                    <Button onClick={() => window.open(selectedProduct.url, '_blank')}>
                        {selectedProduct.buttonText}
                    </Button>
                    <Button className="comparison-modal-button" onClick={handleClose}>
                        Close
                    </Button>
                </ButtonContainer>
            </Body>
        </ModalContainer>
    );
};

export default ComparisonModal;

const ModalContainer = styled(Modal)`
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 10vh;
`;

const Body = styled(ModalBody)`
    position: absolute;
    border-radius: 4px;
    margin-left: -150px;
    width: 630px;
    height: 550px;
    background-color: ${(props) => props.theme.primary.color};
`;

const ImageContainer = styled.div`
    width: 598px;
    height: 460px;
    z-index: 999;

    img {
        height: 100% !important;
        width: unset;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Button = styled(StyledButton)`
    margin: 16px 45px 15px 45px;
`;
