import DrawerContent from '../../drawer-content';
import DrawerHeader from '../../drawer-header';
import Drawer from '../../drawer';
import SatelliteZoomInHint from './satellite-zoom-in-hint';

const SatelliteZoomIn = () => {
    return (
        <Drawer>
            <DrawerHeader title={'Satellite Image Search'} subTitle="Select your Area of Interest" />
            <DrawerContent>
                <SatelliteZoomInHint />
            </DrawerContent>
        </Drawer>
    );
};

export default SatelliteZoomIn;
