import React from 'react';
import PageContainer from './page-container';
import styled from 'styled-components';
import ScrollToTopButton from './scroll-to-top-button';

export const TermsAndConditionsPage = () => {
    return (
        <PageContainer header={<TermsAndConditionsHeader />}>
            <TermsAndConditions />
        </PageContainer>
    );
};

export const TermsAndConditionsHeader = () => {
    return (
        <React.Fragment>
            <h3>Terms of Service</h3>
            <p>
                Last updated: July 1<sup>st</sup>&nbsp;2023
            </p>
        </React.Fragment>
    );
};

const TermsAndConditions = () => {
    return (
        <TermsAndConditionsContainer>
            <TermsAndConditionsBody>
                <h4>1. ACCEPTANCE OF TERMS</h4>
                <ul>
                    <li>
                        1.1. The Pics From Space website is operated by Soar.Earth Ltd and related entities (together
                        "We", "Us", "Our") and may publish from time to time changes or variations to these terms
                        (collectively, the "Terms").
                    </li>
                    <li>
                        1.2. The user ("You", "Your") agrees to and is bound by the Terms of Service as set out below
                        and in any modified or additional terms that We may publish from time to time (collectively, the
                        "Terms").
                    </li>
                    <li>
                        1.3. We may change these Terms from time to time. Your continued access or use of the Site
                        constitutes your acceptance of such changes.
                    </li>
                    <li>
                        1.4. Your access and use of the Site will be subject to the current version of the Terms, rules
                        and guidelines posted on the Site at the time of such use.
                    </li>
                    <li>1.5. If You do not agree to these Terms, do not access or use this Site.</li>
                </ul>

                <h4>2. General Terms</h4>
                <ul>
                    <li>
                        2.1 We operate this website located at{' '}
                        <a href="https://picsfromspace.com">https://picsfromspace.com</a> (the “Site ”) which includes
                        text, images, audio, code and other materials (collectively, the “Content ”) and all the
                        features, and services provided. The Site, and any other features, tools, materials, or other
                        services offered from time to time by Us are referred to here as the “Service.”
                    </li>
                    <li>
                        2.2 You accept Our use of any data collected under our{' '}
                        <a href="/privacy" target="_blank">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        2.3 You must be at least 13 years old to use the Service or have the permission of a parent or
                        guardian.
                    </li>
                    <li>2.4 You are responsible for any activity that occurs through your account.</li>
                    <li>
                        2.5 You agree You will not sell, transfer, licence or assign any account or rights without Our
                        prior express written permission.
                    </li>
                    <li>
                        2.6 You understand and agree that We are not responsible for the Content posted or accessible on
                        the Service and You use the Service at Your own risk.
                    </li>
                    <li>2.7 You may not use the Service for any illegal or unauthorised purpose.</li>
                    <li>
                        2.8 You agree to comply with all laws, rules and regulations (for example federal, state and
                        local) applicable to your use of the Service including but not limited to copyright laws.
                    </li>
                    <li>
                        2.9 You must not attempt to change, modify, adapt or alter the Service or change, modify or
                        alter another website to falsely imply that it is associated with the Service or Us.
                    </li>
                    <li>
                        2.10 You must not interfere or disrupt the Service or servers, or networks connected to the
                        Service, including by transmitting any worms, viruses, spyware, malware, or any other code of a
                        destructive or disruptive nature.
                    </li>
                    <li>
                        2.11 You may not inject content or code or otherwise alter or interfere with the way any page is
                        rendered or displayed in a user's browser or device.
                    </li>
                    <li>
                        2.12 You must not attempt to restrict another user from using or enjoying the Service and You
                        must not encourage or facilitate violations of these Terms or any other terms related to the
                        site and/or third-party content.
                    </li>
                    <li>
                        2.13 Violation of these Terms or Guidelines may, in Our sole discretion, result in blocking Your
                        access to the Service.
                    </li>
                    <li>
                        2.14 We reserve the right to modify or terminate the Service or your access to the Service for
                        any reason, without notice, at any time, and without liability to you.
                    </li>
                    <li>
                        2.15 To use some of the Services, you will be required to:
                        <ul>
                            <li>2.15.1 Create an account.</li>
                            <li>2.15.2 Submit a valid email address.</li>
                            <li>2.15.3 Create a username.</li>
                        </ul>
                    </li>
                    <li>
                        2.16 You can deactivate your account at any time via the Dashboard. Once deactivated:
                        <ul>
                            <li>2.16.1 Your account may not be able to be reactivated in the future.</li>
                            <li>
                                2.16.2 We may continue to retain any records related to your account to comply with
                                legal requirements or in the case of a dispute.
                            </li>
                            <li>2.16.3 You may continue to use features of the site that do not require an account.</li>
                        </ul>
                    </li>
                    <li>
                        2.17 Upon termination of your access to the Site, all licences and other rights granted to you
                        in these Terms will cease immediately but not those rights to any Content purchased by you from
                        a third-party
                    </li>
                    <li>
                        2.18 We reserve the right to refuse access to the Service to anyone for any reason at any time.
                    </li>
                    <li>
                        2.19 We may, but have no obligation to, remove, edit, block, and/or monitor Content or accounts
                        containing Content that we determine in our sole discretion violates these Terms.
                    </li>
                    <li>2.20 You agree that you are responsible for all data charges you incur using the Service.</li>
                    <li>
                        2.21 We prohibit crawling, scraping, caching or otherwise accessing any content on the Service
                        via automated means, except as may be the result of standard search engine protocols or
                        technologies used by a search engine with Our express consent.
                    </li>
                </ul>

                <h4>3. Third-party content and licences</h4>
                <ul>
                    <li>3.1 We make available Content that is provided by third-parties (“Third-Party Content”).</li>
                    <li>
                        3.2 We do not guarantee the availability of any Third-Party Content through the Service as
                        availability is beyond Our control and may be restricted or blocked due to your location.
                    </li>
                    <li>
                        3.3 Third-Party Content may include but is not limited to:
                        <ul>
                            <li>3.3.1 Base map images provided as part of the platform.</li>
                            <li>3.3.2 Satellite image content</li>
                            <li>
                                3.3.3 Image or map content viewed, purchased or downloaded through the platform from a
                                third-party service
                            </li>
                            <li>3.3.4 Other image content from any other source</li>
                        </ul>
                    </li>
                    <li>
                        3.4 Third-Party Content provided through the Site is each governed by licence terms as set out
                        by the Third-Party that owns or created the content.
                    </li>
                </ul>

                <h4>4. Our Content</h4>
                <ul>
                    <li>
                        4.1 The Service contains content owned or licensed by us ("Our Content"). Our Content is
                        protected by copyright, trademark, patent, trade secret and other laws, and We or the licensor
                        owns and retains all rights in Our Content and the Service. You will not remove, alter, or
                        conceal any copyright, trademark, service mark or other proprietary rights notices incorporated
                        in or accompanying Our Content.
                    </li>
                    <li>
                        4.2 You will not reproduce, modify, adapt, prepare derivative works based on, perform, display,
                        publish, distribute, transmit, broadcast, sell, licence, or otherwise exploit Our Content.
                    </li>
                    <li>
                        4.3 The Pics from Space name and logo are Our trademarks and may not be copied, imitated, or
                        used, in whole or in part, without Our prior written permission.
                    </li>
                    <li>
                        4.4 All page headers, custom graphics, button icons and scripts are service marks and/or
                        trademarks (whether formally registered or otherwise) and may not be copied, imitated, or used,
                        in whole or in part, without Our prior written permission.
                    </li>
                </ul>

                <h4>5. Purchasing of Content</h4>
                <ul>
                    <li>
                        5.1 When purchasing Content via the Site, You agree that:
                        <ul>
                            <li>
                                5.1.1 The purchase is for a licence for the Content, You do not own the content and it
                                will be governed by an End User Licence Agreement (EULA) provided by the Supplier
                            </li>
                            <li>
                                5.1.2 Any purchase of the licence for the Content from the Service is an agreement
                                between You and the Supplier who has originally provided the Content and is governed by
                                the supplier's EULA(s).
                            </li>
                            <li>
                                5.1.3 You accept the content “as is ”and without warranty of merchantability, fitness
                                for purpose, non-infringement, title, custom, trade, quiet enjoyment, and free from
                                computer virus.
                            </li>
                            <li>
                                5.1.4 Unless otherwise specified You will abide by any licence conditions for the use of
                                any Content purchased by You.
                            </li>
                        </ul>
                    </li>

                    <li>5.2 Your payment will be processed at the time of placement of the order.</li>
                    <li>
                        5.3 All orders, unless otherwise specified will be delivered:
                        <ul>
                            <li>5.3.1 at the pixel resolution specified in the order</li>
                            <li>5.3.2 in WGS84 Datum and UTM projection</li>
                            <li>5.3.3 as a GeoTIFF or ECW image format</li>
                        </ul>
                    </li>

                    <li>
                        5.4. All Archive images are provided as follows:
                        <ul>
                            <li>
                                5.4.1 Images are supplied ‘as is’ with colouring and cloud / smoke as shown on the low
                                resolution image preview.
                            </li>
                            <li>
                                5.4.2 Images are supplied in Natural Colour Composite (NCC) which is a 3-band RGB
                                format.
                            </li>
                            <li>
                                5.4.3 Images are not ortho or georectified and some locational errors may occur. These
                                are different for each image and can vary from 3m to 20m.
                            </li>
                            <li>
                                5.4.4 Delivery times may vary depending on the size, location, and complexity of the
                                order. The estimated delivery time for Archive images can take up to 2 business days.
                            </li>
                            <li>
                                5.4.5 Images will not be mosaiced or stitched unless otherwise agreed to in writing
                                upfront at the time of the order.
                            </li>
                        </ul>
                    </li>

                    <li>
                        5.5. New collection images are provided as follows:
                        <ul>
                            <li>
                                5.5.1 The initiation of my order will commence up to 5 business days after order
                                placement.
                            </li>
                            <li>
                                5.5.2 Images are supplied with a maximum allowable cloud coverage of 15% (including
                                cloud shadow). For example, if total order size is 100km2, your order will only be
                                re-tasked if clouds cover more than 15km2.
                            </li>
                            <li>
                                5.5.3 In cases where persistent cloud cover is more than 15%, Pics from Space will
                                re-attempt to capture the area at no additional cost.{' '}
                            </li>
                            <li>
                                5.5.4 Delivery of your order will be up to 3 business days after successful collection.
                            </li>
                            <li>
                                5.5.5 Images are supplied in Natural Color Composite (NCC) which is a 3-band RGB format.
                            </li>
                            <li>
                                5.5.6 Images are not ortho or georectified and some locational errors may occur. These
                                are different for each image and can vary from 3m to 20m.
                            </li>
                            <li>
                                5.5.7 Images will not be mosaiced or stitched unless otherwise agreed to in writing
                                upfront at the time of the order.
                            </li>
                            <li>
                                5.5.8 Priority and emergency collection may be requested for shorter turnaround times
                                and You accept that:
                                <ul>
                                    <li>
                                        5.5.8.1 Such an order placed outside the business hours of Monday to Friday 9 am
                                        and 5 pm GMT +8 will be processed the next available business day.
                                    </li>
                                    <li>
                                        5.5.8.2 The image provided under priority or emergency tasking orders will be
                                        "as-is" and clause 5.5.3 above does not apply.
                                    </li>
                                    <li>
                                        5.5.8.3 You accept the risk of emergency tasking and the risk of excess cloud or
                                        obscuration of areas of specific interest to You and that the final product may
                                        not be suitable for use by You.
                                    </li>
                                    <li>
                                        5.5.8.4 Such orders will incur an additional charge for priority or emergency
                                        tasking.
                                    </li>
                                    <li>
                                        5.5.8.5 Such orders, subject to 5.5.8.1 above, placed on a Friday will be
                                        delivered the following Monday.{' '}
                                    </li>
                                </ul>
                            </li>
                            <li>
                                5.5.9 Pics from Space, reserves the right to refuse any order and is under no obligation
                                to disclose any reasons for the refusal. Should such an occasion occur a full refund
                                will be made.
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>6. Users excluded from purchasing or receiving data</h4>
                <ul>
                    <li>
                        6.1 You may not order or receive satellite imagery if You or a person on whose behalf You are
                        making the purchase are:
                        <ul>
                            <li>
                                6.1.1 from any country where such distribution or use would be contrary to law or
                                regulation or which would subject Us to any registration requirement within such
                                jurisdiction or country;
                            </li>
                            <li>
                                6.1.2 from (or to a national or resident of) any country to which Australia has
                                embargoed goods;
                            </li>
                            <li>
                                6.1.3 on the U.S. Treasury Department's list of Specially Designated Nationals or the
                                U.S. Commerce Department's Table of Deny Orders;
                            </li>
                            <li>
                                6.1.4 a person, including government, state or organisation, who is listed as under
                                sanction through the authority of:
                                <ul>
                                    <li>6.1.4.1 the United Nations Charter Act 1945 (Cth);</li>
                                    <li>6.1.4.2 the Autonomous Sanctions Act 2011 (Cth)</li>
                                    <li>6.1.4.3 the US OFAC consolidated list; or</li>
                                    <li>
                                        6.1.4.4 any other statute or regulation which would make supply of any Product
                                        illegal.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <h4>7. Replacements and refunds</h4>
                <ul>
                    <li>
                        7.1. If the Content delivered is faulty, does not match what has been described or is not within
                        any stated specification provided in Section 5 You will be entitled to a replacement of that
                        Content.
                    </li>
                    <li>
                        7.2 We will only provide a refund where we are unable to provide an acceptable replacement or
                        equivalent for your purchase.{' '}
                    </li>
                    <li>
                        7.3 The right to a refund or replacement does not apply if You got what You asked for but simply
                        changed your mind, found it cheaper somewhere else, decided you did not like the purchase or had
                        no use for it.
                    </li>
                </ul>

                <h4>8. Third-party services</h4>
                <ul>
                    <li>
                        8.1 You acknowledge that we may not always identify paid services, sponsored content, or
                        commercial communications as such.
                    </li>
                    <li>
                        8.2 There may be links from the Service, or from communications you receive from the Service to
                        Third-Party web sites or features. There may also be links to Third-Party web sites or features
                        in images or comments within the Service. The Service also includes third-party content that we
                        do not control, maintain or endorse.
                    </li>
                    <li>
                        8.3 Functionality on the Service may also permit interactions between the Service and a
                        third-party web site or feature, including applications that connect the Service or your profile
                        on the Service with a third-party web site or feature.
                    </li>
                    <li>
                        8.4 We do not control any of these Third-Party web services or any of their content. You
                        expressly acknowledge and agree that Soar is in no way responsible or liable for any such
                        third-party services or features.
                    </li>
                </ul>
                <h4>9. Not used</h4>
                <h4>10. Disclaimer of Warranties</h4>
                <ul>
                    <li>
                        10.1 The Service, including, without limitation, Our Content, is provided on an "as-is", "as
                        available "and "with all faults" basis.
                    </li>
                    <li>
                        10.2 Some or all features available through the Service may not be available in your region due
                        to various factors and We do not guarantee access to the Service.
                    </li>
                    <li>
                        10.3 To the fullest extent permissible by law, neither We nor any of Our employees, managers,
                        officers or agents make any representations or warranties or endorsements of any kind
                        whatsoever, express or implied, as to:
                        <ul>
                            <li>10.3.1 The Service</li>
                            <li>10.3.2 Our Content</li>
                            <li>10.3.3 Third-Party Content; or</li>
                            <li>
                                10.3.4 Security associated with the transmission of information to Us or via the
                                Service.
                            </li>
                        </ul>
                    </li>
                    <li>
                        10.4 In addition, We hereby disclaims all warranties, express or implied, including, but not
                        limited to, the warranties of merchantability, fitness for a particular purpose,
                        non-infringement, title, custom, trade, quiet enjoyment, system integration and freedom from
                        computer virus.
                    </li>
                    <li>
                        10.5 We do not represent or warrant that the Service will be error-free or uninterrupted; that
                        defects will be corrected; or that the Service or the server that makes the Service available is
                        free from any harmful components, including, without limitation, viruses.
                    </li>
                    <li>
                        10.6 We do not make any representations or warranties that the information on the Service is
                        accurate, complete, or useful. You acknowledge that your use of the Service is at your sole
                        risk.
                    </li>
                    <li>
                        10.7 We do not warrant that your use of the Service is lawful in any particular jurisdiction,
                        and We specifically disclaim such warranties. Some jurisdictions limit or do not allow the
                        disclaimer of implied or other warranties so the above disclaimer may not apply to you to the
                        extent such jurisdiction's law is applicable to You and these Terms.
                    </li>
                    <li>
                        10.8 By accessing or using the Service, You represent and warrant that Your activities are
                        lawful in every jurisdiction where You access or use the Service.
                    </li>
                    <li>
                        10.9 We do not endorse Content and specifically disclaim any responsibility or liability to any
                        person or entity for any loss, damage (whether actual, consequential, punitive or otherwise),
                        injury, claim, liability or other cause of any kind or character based upon or resulting from
                        any content.
                    </li>
                </ul>

                <h4>11. Limitation of liability; waiver</h4>
                <ul>
                    <li>
                        11.1 Under no circumstances will We be liable to You for any loss or damages of any kind
                        (including, without limitation, for any direct, indirect, economic, exemplary, special,
                        punitive, incidental or consequential losses or damages) that are directly or indirectly related
                        to:
                        <ul>
                            <li>11.1.1 The Service</li>
                            <li>11.1.2 Our Content</li>
                            <li>11.1.3 Your use of, inability to use, or the performance of the Service;</li>
                            <li>
                                11.1.4 Any action taken in connection with an investigation by Us or law enforcement
                                authorities regarding Your or any other party's use of the Service
                            </li>
                            <li>
                                11.1.5 Any action taken in connection with copyright or other intellectual property
                                owners
                            </li>
                            <li>11.1.6 Any errors or omissions in the Service's operation; or</li>
                            <li>
                                11.1.7 Any damage to any user 's computer, mobile device, or other equipment or
                                technology including, without limitation, damage from any security breach or from any
                                virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation
                                or transmission, computer line or network failure or any other technical or other
                                malfunction; including, without limitation, damages for lost profits, loss of goodwill,
                                loss of data, work stoppage, accuracy of results, or computer failure or malfunction,
                                even if foreseeable or even if We have been advised of or should have known of the
                                possibility of such damages, whether in an action of contract, negligence, strict
                                liability or tort (including, without limitation, whether caused in whole or in part by
                                negligence, acts of God, telecommunications failure, or theft or destruction of the
                                service).
                            </li>
                        </ul>
                    </li>
                    <li>
                        11.2 In no event will We be liable to You or anyone else for loss, damage or injury, including,
                        without limitation, death or personal injury. Some jurisdictions do not allow the exclusion or
                        limitation of incidental or consequential damages, so the above limitation or exclusion may not
                        apply to You. In no event will Our total liability to you for all damages, losses or causes of
                        action exceed one hundred United States Dollars (US $100.00).
                    </li>
                    <li>
                        11.3 You agree that in the event You incur any damages, losses or injuries that arise out of Our
                        acts or omissions, the damages, if any, caused to You are not irreparable or sufficient to
                        entitle You to an injunction preventing any exploitation of any web site, service, property,
                        product or other content owned or controlled by Us, and You will have no rights to enjoin or
                        restrain the development, production, distribution, advertising, exhibition or exploitation of
                        any web site, property, product, service, or other content owned or controlled by Us.
                    </li>
                    <li>
                        11.4 We are not responsible for the actions, content, information, or data of Third Parties, and
                        You release Us, Our directors, officers, employees, and agents from any claims and damages,
                        known and unknown, arising out of or in any way connected with any claim You have against any
                        such Third-Parties.
                    </li>
                </ul>

                <h4>12. Indemnity</h4>
                <ul>
                    <li>
                        12.1 You (and also any third-party for whom You operate an account or activity on the Service)
                        agree to defend (at Our request), indemnify and hold Us harmless from and against any claims,
                        liabilities, damages, losses, and expenses, including without limitation, reasonable attorney 's
                        fees and costs, arising out of or in any way connected with any of the following (including as a
                        result of Your direct activities on the Service or those conducted on Your behalf):
                        <ul>
                            <li>12.1.1 Your breach or alleged breach of these Terms</li>
                            <li>
                                12.1.2 Your violation of any Third-Party right, including without limitation, any
                                intellectual property right, publicity, confidentiality, property or privacy right
                            </li>
                            <li>
                                12.1.3 Your violation of any laws, rules, regulations, codes, statutes, ordinances or
                                orders of any governmental and quasi-governmental authorities, including, without
                                limitation, all regulatory, administrative and legislative authorities; or
                            </li>
                            <li>12.1.4 Any misrepresentation made by You.</li>
                        </ul>
                    </li>
                    <li>
                        12.2 You will cooperate as fully required by Us in the defence of any claim. We reserve the
                        right to assume the exclusive defence and control of any matter subject to indemnification by
                        You, and You will not in any event settle any claim without Our prior written consent.
                    </li>
                </ul>
                <h4>13. Time Limitation on Claims</h4>
                <ul>
                    <li>
                        13.1 You agree that any claim you may have arising out of or related to Your relationship with
                        Us must be filed within one (1) year of the cause of such a claim arising; otherwise, Your claim
                        is permanently barred.
                    </li>
                </ul>
                <h4>14. Governing Law & Venue</h4>
                <ul>
                    <li>
                        14.1 These Terms are governed by and construed in accordance with the laws of Western Australia,
                        Australia without giving effect to any principles of conflicts of law, and You Agree to be bound
                        by the decision of any court of competent jurisdiction.
                    </li>
                    <li>
                        14.2 These Terms WILL SPECIFICALLY NOT BE GOVERNED BY THE UNITED NATIONS CONVENTIONS ON
                        CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS, IF OTHERWISE APPLICABLE.
                    </li>
                    <li>
                        14.3 If any provision of these Terms is held to be unlawful, void, or for any reason
                        unenforceable during arbitration or by a court of competent jurisdiction, then that provision
                        will be deemed severable from these Terms and will not affect the validity and enforceability of
                        any remaining provisions. Our failure to insist upon or enforce strict performance of any
                        provision of these Terms will not be construed as a waiver of any provision or right.
                    </li>
                    <li>
                        14.4 No waiver of any of these Terms will be deemed a further or continuing waiver of such term
                        or condition or any other term or condition.
                    </li>
                    <li>
                        14.5 We reserve the right to change this dispute resolution provision, but any such changes will
                        not apply to disputes arising before the effective date of the amendment.
                    </li>
                    <li>
                        14.6 This dispute resolution provision will survive the termination of any or all of Your
                        transactions with Us.
                    </li>
                </ul>
                <h4>15. Entire Agreement</h4>
                <ul>
                    <li>
                        15.1 If you are using the Service on behalf of a legal entity, You represent that You are
                        authorised to enter into an agreement on behalf of that legal entity.
                    </li>
                    <li>
                        15.2 These Terms constitute the entire agreement between You and Us and governs Your use of the
                        Service, superseding any prior agreements between You and Us.
                    </li>
                    <li>
                        15.3 You will not assign the Terms or assign any rights or delegate any obligations hereunder,
                        in whole or in part, whether voluntarily or by operation of law, without Our prior written
                        consent.
                    </li>
                    <li>
                        15.4 Any purported assignment or delegation by You without Our prior written consent will be
                        null and void.
                    </li>
                    <li>15.5 We may assign these Terms or any rights hereunder without Your consent.</li>
                    <li>
                        15.6 Neither the course of conduct between the parties nor trade practice will act to modify the
                        Terms.
                    </li>
                    <li>15.7 These Terms do not confer any Third-Party beneficiary rights.</li>
                </ul>
                <h4>16. Territorial Restrictions</h4>
                <ul>
                    <li>
                        16.1 The information provided within the Service is not intended for distribution to or use by
                        any person or entity in any jurisdiction or country where such distribution or use would be
                        contrary to law or regulation or which would subject Us to any registration requirement within
                        such jurisdiction or country. We reserve the right to limit the availability of the Service or
                        any portion of the Service, to any person, geographic area, or jurisdiction, at any time and in
                        our sole discretion, and to limit the quantities of any content, program, product, service or
                        other feature that We provide.
                    </li>
                    <li>
                        16.2 Software related to, or made available by, the Service may be subject to export controls.
                        Thus, no software from the Service may be downloaded, exported or re-exported:
                        <ul>
                            <li>
                                (a) into (or to a national or resident of) any country to which the United States has
                                embargoed goods; or
                            </li>
                            <li>
                                (b) to anyone on the U.S. Treasury Department 's list of Specially Designated Nationals
                                or the U.S. Commerce Department 's Table of Deny Orders. By downloading any software
                                related to the Service, You represent and warrant that You are not located in, under the
                                control of, or a national or resident of, any such country or on any such list.
                            </li>
                        </ul>
                    </li>
                </ul>
            </TermsAndConditionsBody>
            <ScrollToTopButton />
        </TermsAndConditionsContainer>
    );
};

export default TermsAndConditions;

const TermsAndConditionsContainer = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

const TermsAndConditionsBody = styled.div`
    margin: 20px;
    ul {
        margin-left: 10px;
    }

    h4 {
        font-weight: 600;
        color: ${(props) => props.theme.primary.text};
        text-align: left;
        font-size: ${(props) => props.theme.fontSize.default};
    }

    li {
        color: ${(props) => props.theme.primary.text};
        list-style-type: none;
    }

    a {
        color: ${(props) => props.theme.primary.text};
        font-weight: 600;
    }

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
        flex-direction: column;

        ul {
            padding-inline-start: 0px;
        }
    }
`;
