import React from 'react';
import NavigationBar from '../NavigationBar/navbar';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
    return (
        <React.Fragment>
            <NavigationBar />
            <Outlet />
        </React.Fragment>
    );
};

export default PageLayout;
