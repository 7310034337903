import styled from 'styled-components';
import SoarAutocomplete from './soar-autocomplete';
import { LatLng } from 'leaflet';
import { useDispatch } from 'react-redux';
import { actionFlyToOnMap } from 'store/Map/actions';
import {
    actionSatelliteEndBoxSelect,
    actionSatelliteMouseOut,
    actionSatelliteSelectAOI,
} from 'store/Map/SatelliteArchive/actions';
import { useCallback } from 'react';

const SearchBar = () => {
    const dispatch = useDispatch();

    const onLatlngSelected = useCallback(
        (position: LatLng) => {
            dispatch(actionFlyToOnMap(position));
            dispatch(actionSatelliteSelectAOI(undefined));
            dispatch(actionSatelliteEndBoxSelect());
            dispatch(actionSatelliteMouseOut());
        },
        [dispatch]
    );

    const onSearchClear = useCallback(() => {
        dispatch(actionFlyToOnMap(undefined));
    }, [dispatch]);

    return (
        <SearchInputContainer>
            <SoarAutocomplete
                autocompleteType={['ADDRESS', 'COORDINATE']}
                onLatlngSelected={onLatlngSelected}
                onSearchClear={onSearchClear}
            />
        </SearchInputContainer>
    );
};

export default SearchBar;

const SearchInputContainer = styled.div`
    position: fixed;
    width: 40vw;
    left: 28vw;
    top: 12px;
    background: #e9d7ad;
    border: 1px solid #515151;
    border-radius: 6px;
    display: block;
    z-index: 10003;
    min-width: 250px;

    .react-autosuggest__input {
        width: calc(100% - 30px);
        height: 100%;
        padding: 10px 20px 10px 30px;
        font-weight: 300;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        background-color: transparent;
        color: #6e322b;
    }

    .react-autosuggest-search-icon {
        top: 13px;
        left: 0px;
        color: #6e322b;
    }

    .react-autosuggest-close-search {
        right: 0px;
        color: #6e322b;
    }

    .react-autosuggest-spinner {
        right: 0px;
        color: #6e322b;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__suggestions-container {
        top: 40px !important;
        max-height: calc(100vh - 300px);
        max-width: 40vw;
        overflow-y: auto;
        width: 400px;
        background-color: #e9d7ade6;
        border-radius: 6px;
        border: 1px solid #515151;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #343a40;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #343a40;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #eed926;
            border-radius: 4px;
        }
    }
    .react-autosuggest__suggestions-list {
        font-size: 14px;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 5px 10px;
        word-break: break-all;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: transparent;
        box-shadow: 0px 0px 10px 5px rgb(110 50 43 / 34%);
    }

    .react-autosuggest__suggestion span {
        color: #6e322b !important;
    }
`;
