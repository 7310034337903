import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Nav as BootstrapNav,
    Navbar as BootstrapNavbar,
    NavbarBrand as BootstrapNavbarBrand,
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
} from 'reactstrap';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionShowLoginDialog } from '../../store/App/actions';
import { actionGetMyProfile } from '../../store/App/actions';
import { selectMyProfile } from '../../store/App/selectors';
import NavbarProfile from './navbar-profile';
import { selectShoppingCartOrders } from 'store/ShoppingCart/selectors';
import { Link, useNavigate } from 'react-router-dom';
import { actionClearMap } from 'store/Map/actions';
import GoogleAnalytics from 'lib/google-analytics';

const NavigationBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const myProfile = useSelector(selectMyProfile);
    const cartOrders = useSelector(selectShoppingCartOrders);

    const itemsInCart = cartOrders.length;

    useEffect(() => {
        if (!myProfile) {
            dispatch(actionGetMyProfile());
        }
    }, [myProfile, dispatch]);

    const onClickLogo = () => {
        Analytics.Event('Navigation bar', 'Clicked Logo to navigate to /satellite');
        dispatch(actionClearMap());
        navigate('/satellite');
    };

    const onClickLogin = () => {
        GoogleAnalytics.Event('login_click', { action: 'click', from: 'navigation' });
        dispatch(actionShowLoginDialog(true));
    };

    return (
        <Navbar fixed="top">
            <SoarNavbarBrand onClick={onClickLogo}>
                <img src="/assets/logos/logo-black.png" alt="Picsfromspace" />
            </SoarNavbarBrand>

            <Nav className="ml-auto">
                {myProfile && itemsInCart > 0 && (
                    <CartNavItem>
                        <Link to="/cart" onClick={() => Analytics.Event('Navigation bar', 'Clicked Shopping Cart')}>
                            <i className="fa fa-shopping-cart" />
                            <span className="badge badge-light">{itemsInCart}</span>
                        </Link>
                    </CartNavItem>
                )}

                {myProfile ? (
                    <NavbarProfile userProfile={myProfile} />
                ) : !UserHelper.validateToken() ? (
                    <SignInNavItem>
                        <NavLink onClick={onClickLogin} data-cy="Sign in">
                            Sign in
                        </NavLink>
                    </SignInNavItem>
                ) : null}
            </Nav>
        </Navbar>
    );
};

export default NavigationBar;

const Navbar = styled(BootstrapNavbar)`
    background: ${(props) => props.theme.primary.color};
    border-bottom: 12px solid ${(props) => props.theme.secondary.color};
    height: 72px;
    margin-left: 0;
    z-index: 1501;
    color: ${(props) => props.theme.primary.text};
`;

const Nav = styled(BootstrapNav)`
    margin-right: 12px;
`;

const SignInNavItem = styled(BootstrapNavItem)`
    color: ${(props) => props.theme.primary.text};

    &:hover {
        color: ${(props) => props.theme.primary.hover};
    }

    @media (max-width: 1400px) {
        margin-right: -20px;
    }
`;

interface NavLinkProps {
    to?: string;
}

const NavLink = styled(BootstrapNavLink)<NavLinkProps>`
    color: ${(props) => props.theme.primary.text};
    cursor: pointer;
    &:hover {
        color: ${(props) => props.theme.primary.hover};
    }
`;

const CartNavItem = styled(BootstrapNavItem)`
    display: flex;
    margin-right: 40px;

    a {
        margin: auto;
        &:hover {
            color: ${(props) => props.theme.primary.hover};
        }
    }

    span {
        position: absolute;
        top: 15px;
        border-radius: 10px;
        color: ${(props) => props.theme.primary.text};
        border: 1px solid ${(props) => props.theme.primary.text};
        background-color: transparent;
    }
`;

const SoarNavbarBrand = styled(BootstrapNavbarBrand)`
    cursor: pointer;

    img {
        height: 42px;
    }
`;
