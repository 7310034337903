import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Row, Col, Spinner } from 'reactstrap';
import styled from 'styled-components';
import GeoUtil from 'lib/geo-util';
import { selectSatelliteAOI, selectSatelliteSelectedFeature } from '../../store/Map/SatelliteArchive/selectors';
import ApiSupplier, { DefaultNewCollectProductKey, Products, SatelliteArchiveOrderDTO } from '../../api/api-supplier';
import { selectOpenDrawerForOrder, selectShoppingCartOrders } from 'store/ShoppingCart/selectors';
import {
    actionShoppingCartAddOrder,
    actionShoppingCartOpenDrawer,
    actionShoppingCartRemoveOrder,
} from 'store/ShoppingCart/actions';
import { FlatButton, StyledButton, StyledTransparentButton } from 'components/Shared/Buttons/styled-button';
import SelectDropdown, { SelectItem } from 'components/Shared/Input/select-dropdown';
import Analytics from 'lib/user-analytics';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

import { actionShowLoginDialog } from 'store/App/actions';
import { selectLoggedIn } from 'store/App/selectors';
import PriceWithCurrency from './price-with-currency';
import LocalStorageUtil from 'lib/local-storage-util';
import ImageComparisonModal from './image-comparison-modal';
import ImagePreview from './image-preview';
import { LatLngBounds } from 'leaflet';
import GoogleAnalytics from 'lib/google-analytics';
import ApiAnalytics from 'api/api-analytics';
import { AnalyticsAction } from 'api/model';

const getNewCollectResolutions = (bounds: LatLngBounds): SelectItem[] => {
    const area = GeoUtil.areaInKM(bounds);
    const resolutions = Object.keys(Products)
        .filter((p) => Products[p].type === 'NEWCOLLECT' && area >= Products[p].minAreaInKm)
        .map((p) => {
            return { value: p, text: Products[p].resolution };
        });
    return resolutions;
};

const ShoppingCartDrawer = () => {
    const selectedFeature = useSelector(selectSatelliteSelectedFeature);
    const selectedOrderType = useSelector(selectOpenDrawerForOrder);
    const selectedAOI = useSelector(selectSatelliteAOI);
    const [item, setItem] = useState<SatelliteArchiveOrderDTO | undefined>(undefined);
    const [productKey, setProductKey] = useState(DefaultNewCollectProductKey);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    const [showComparisonModal, setShowComparisonModal] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);
    const [isAdded, setIsAdded] = useState(false);

    const nodeRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cartOrders = useSelector(selectShoppingCartOrders);
    const isLoggedIn = useSelector(selectLoggedIn);

    useEffect(() => {
        setIsRemoved(false);
        if (item) {
            GoogleAnalytics.ViewSatelliteItem(item);
        }
    }, [item]);

    useEffect(() => {
        setError(undefined);
        const country = LocalStorageUtil.getCountry();
        if (selectedFeature && selectedAOI && selectedOrderType === 'ARCHIVE') {
            const geometryWKT = GeoUtil.latLngBoundsToWKT(selectedAOI);
            ApiSupplier.calculateArchivePrice(geometryWKT, selectedFeature, country).then(setItem).catch(setError);
            setIsOpen(true);
            setIsAdded(false);
        } else if (selectedAOI && selectedOrderType === 'NEWCOLLECT') {
            const geometryWKT = GeoUtil.latLngBoundsToWKT(selectedAOI);
            ApiSupplier.calculateNewCollectPrice(geometryWKT, productKey, country)
                .then(setItem)
                .catch((e) => {
                    setError(e);
                });
            setIsOpen(true);
            setIsAdded(false);
        } else {
            setIsOpen(false);
            setIsAdded(false);
            setProductKey(DefaultNewCollectProductKey);
            // delay clearing the selected item for drawer animation
            setTimeout(() => {
                setItem(undefined);
            }, 300);
        }
    }, [selectedFeature, selectedAOI, selectedOrderType, productKey]);

    const onCloseHandler = () => {
        setIsOpen(false);
        dispatch(actionShoppingCartOpenDrawer(undefined));
        Analytics.Event('Shopping cart drawer', 'Clicked to continue shopping');
    };

    const addToCart = (item: SatelliteArchiveOrderDTO) => {
        Analytics.Event('Shopping cart drawer', 'Clicked Add to Cart', item.product);
        GoogleAnalytics.AddToCart(item);
        ApiAnalytics.postAnalytics(
            item.supplier,
            AnalyticsAction.CART_ADD,
            item.product,
            item.geometryWKT,
            item.id,
            item.previewUrl
        );
        const isItemInCart = cartOrders.find(
            (o) =>
                (o.orderType === 'ARCHIVE' && o.id === item.id && o.geometryWKT === item.geometryWKT) ||
                (o.orderType === 'NEWCOLLECT' &&
                    o.orderType === item.orderType &&
                    o.geometryWKT === item.geometryWKT &&
                    o.productKey === item.productKey)
        )
            ? true
            : false;

        setIsAdded(true);
        if (isItemInCart) return;
        dispatch(actionShoppingCartAddOrder(item));
    };

    const removeFromCart = (item: SatelliteArchiveOrderDTO) => {
        dispatch(actionShoppingCartRemoveOrder(item));
        setIsRemoved(true);
        GoogleAnalytics.RemoveFromCart(item);
        ApiAnalytics.postAnalytics(
            item.supplier,
            AnalyticsAction.CART_REM,
            item.product,
            item.geometryWKT,
            item.id,
            item.previewUrl
        );
    };

    const login = () => {
        dispatch(actionShowLoginDialog(true));
        GoogleAnalytics.Event('login_click', { action: 'click', from: 'shopping-cart-drawer' });
    };

    const proceedCheckout = () => {
        navigate('/checkout');
        Analytics.Event('Shopping cart drawer', 'Clicked Proceed to checkout');
    };

    const handleComparison = () => {
        setShowComparisonModal(true);
        Analytics.Event('Shopping cart drawer', 'Clicked Show sample');
    };

    const itemsCount = cartOrders.length;
    const totalPrice = cartOrders.reduce((sum, current) => sum + current.totalPrice, 0);
    const newCollectTotalArea = cartOrders
        .filter((m) => m.orderType === 'NEWCOLLECT')
        .reduce((sum, current) => sum + parseFloat(current.areaInKm.toString()), 0);
    const archiveTotalArea = cartOrders
        .filter((m) => m.orderType === 'ARCHIVE')
        .reduce((sum, current) => sum + parseFloat(current.archiveSelectedAreaInKm.toString()), 0);
    const totalAreaInKm = Math.ceil(newCollectTotalArea + archiveTotalArea);

    return (
        <CSSTransition nodeRef={nodeRef} timeout={500} in={isOpen} classNames="cart">
            <DrawerContainer ref={nodeRef}>
                <CloseIcon onClick={onCloseHandler} />

                {error ? (
                    <ErrorContainer>
                        <Alert color="danger">
                            <i className="fa fa-exclamation-circle mr-1"></i>Sorry 😌
                            <br />
                            {error.message}
                        </Alert>
                    </ErrorContainer>
                ) : item ? (
                    <React.Fragment>
                        {isAdded ? (
                            <CartTitle>
                                <i className="fa fa-check-circle mr-2"></i> Item added to your shopping cart
                            </CartTitle>
                        ) : isRemoved ? (
                            <CartTitle>
                                <i className="fa fa-minus-circle mr-2"></i> Item removed from your cart
                            </CartTitle>
                        ) : item.orderType === 'ARCHIVE' ? (
                            <div className="m-3">
                                <h2>Image preview</h2>
                            </div>
                        ) : item.orderType === 'NEWCOLLECT' ? (
                            <div className="m-3">
                                <h2>Take a new image from space!</h2>
                            </div>
                        ) : null}

                        <CheckoutContainer>
                            {isAdded || isRemoved ? (
                                <React.Fragment>
                                    <div>
                                        <ItemPricePerKm>
                                            {itemsCount > 1
                                                ? `Current average price per km²: `
                                                : `Current price per km²: `}
                                            <PriceWithCurrency
                                                value={totalPrice / totalAreaInKm}
                                                currency={item.currency}
                                            />
                                        </ItemPricePerKm>

                                        {cartOrders.map((item, index) => {
                                            return (
                                                <CartItemContainer key={index}>
                                                    <CartItem>
                                                        {item.orderType === 'ARCHIVE' ? (
                                                            <>
                                                                <DetailImagePreview>
                                                                    <ImagePreview previewUrl={item.previewUrl} />
                                                                </DetailImagePreview>
                                                                <DetailsContainer>
                                                                    <div>{moment(item.date).format('MMM Do YYYY')}</div>
                                                                    <div>{Products[item.productKey]?.resolution}</div>
                                                                    <div>{item.cloud}% cloud cover</div>
                                                                </DetailsContainer>
                                                            </>
                                                        ) : item.orderType === 'NEWCOLLECT' ? (
                                                            <>
                                                                <DetailImagePreview>
                                                                    <img
                                                                        src="/assets/floating-drawer-icons/icon-new-collect-brown.svg"
                                                                        className="p-4"
                                                                    />
                                                                </DetailImagePreview>
                                                                <DetailsContainer>
                                                                    <div>New image!</div>
                                                                    <div>{Products[item.productKey]?.resolution}</div>
                                                                    <div>{item.areaInKm}km² </div>
                                                                </DetailsContainer>
                                                            </>
                                                        ) : null}
                                                        <PriceDetail>
                                                            <PriceWithCurrency
                                                                value={item.totalPrice}
                                                                currency={item.currency}
                                                            />
                                                        </PriceDetail>
                                                    </CartItem>
                                                    <TrashContainer>
                                                        <FlatButton onClick={() => removeFromCart(item)}>
                                                            <i className="fa fa-trash" />
                                                        </FlatButton>
                                                    </TrashContainer>
                                                </CartItemContainer>
                                            );
                                        })}
                                    </div>
                                    <Row className="ml-2 mt-4 mr-2 d-flex justify-content-around">
                                        <div>
                                            <i className="fa fa-shopping-cart mr-2"></i>
                                            {itemsCount > 1 ? `${itemsCount} items ` : `${itemsCount} item `}
                                            <PriceWithCurrency value={totalPrice} currency={item.currency} />
                                        </div>
                                        <div>|</div>
                                        <div>
                                            <Link
                                                to="/cart"
                                                onClick={() =>
                                                    Analytics.Event(
                                                        'Shopping cart drawer',
                                                        'Clicked View Shopping Cart'
                                                    )
                                                }
                                            >
                                                View Shopping Cart
                                            </Link>
                                        </div>
                                    </Row>
                                    <Row className="mt-5 d-flex justify-content-around ">
                                        <StyledButton onClick={proceedCheckout} className="pl-3 pr-3">
                                            PROCEED TO CHECKOUT
                                        </StyledButton>
                                        <StyledTransparentButton onClick={onCloseHandler} className="pl-3 pr-3">
                                            CONTINUE SHOPPING
                                        </StyledTransparentButton>
                                    </Row>
                                </React.Fragment>
                            ) : item.orderType === 'ARCHIVE' ? (
                                <React.Fragment>
                                    <PreviewContainer>
                                        <ImagePreview previewUrl={item.previewUrl} />
                                    </PreviewContainer>

                                    <div className="d-flex justify-content-center ml-2">
                                        <FlatButton
                                            className="text-decoration-underline m-0"
                                            style={{ fontSize: '14px' }}
                                            onClick={handleComparison}
                                        >
                                            Show sample <i className="fa fa-search ml-1 mr-2" />
                                        </FlatButton>
                                    </div>

                                    <CartContainer>
                                        <Row>
                                            <Col sm={6}>
                                                <b>Image date:</b>
                                            </Col>
                                            <Col>{moment(item.date).format('MMM Do YYYY')}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <b>Total Area:</b>
                                            </Col>
                                            <Col>{item.archiveSelectedAreaInKm}km²</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <b>Resolution:</b>
                                            </Col>
                                            <Col>{Products[item.productKey]?.resolution}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <b>Cloud Cover:</b>
                                            </Col>
                                            <Col>{item.cloud}%</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <b>Nadir:</b>
                                            </Col>
                                            <Col>{item.rollAngle}&#176;</Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <b>Price:</b>
                                            </Col>
                                            <Col>
                                                <PriceText className={!isLoggedIn ? 'blur' : ''}>
                                                    <PriceWithCurrency
                                                        value={item.totalPrice}
                                                        currency={item.currency}
                                                    />
                                                </PriceText>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mt-4 d-flex justify-content-center mr-2">
                                            {isLoggedIn ? (
                                                <div style={{ display: 'block' }}>
                                                    <AddCartButton onClick={() => addToCart(item)}>
                                                        <i className="fa fa-shopping-cart mr-3"></i>ADD TO CART
                                                    </AddCartButton>
                                                </div>
                                            ) : (
                                                <Button onClick={login}>Login to view price</Button>
                                            )}
                                        </Row> */}
                                    </CartContainer>
                                </React.Fragment>
                            ) : item.orderType === 'NEWCOLLECT' && selectedAOI ? (
                                <React.Fragment>
                                    <PreviewNewCollect>
                                        <img src="/assets/floating-drawer-icons/icon-new-collect-brown.svg" />
                                    </PreviewNewCollect>
                                    <div className="d-flex justify-content-center ml-2">
                                        <FlatButton
                                            className="text-decoration-underline m-0"
                                            style={{ fontSize: '14px' }}
                                            onClick={handleComparison}
                                        >
                                            Show sample <i className="fa fa-search ml-1 mr-2" />
                                        </FlatButton>
                                    </div>

                                    <NewCollectOrderDetails>
                                        <Row>
                                            <Col sm={5}>
                                                <b>Request Area:</b>
                                            </Col>
                                            <Col>{item.areaInKm}km²</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={5} className="d-flex align-items-center ">
                                                <b>What resolution do you want?</b>
                                            </Col>
                                            <Col>
                                                <SelectDropdown
                                                    items={getNewCollectResolutions(selectedAOI)}
                                                    value={productKey}
                                                    setValue={(value) => setProductKey(value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={5}>
                                                <b>Price:</b>
                                            </Col>
                                            <Col>
                                                <PriceText className={!isLoggedIn ? 'blur' : ''}>
                                                    <PriceWithCurrency
                                                        value={item.totalPrice}
                                                        currency={item.currency}
                                                    />
                                                </PriceText>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-4 d-flex justify-content-center mr-2">
                                            {isLoggedIn ? (
                                                <AddCartButton onClick={() => addToCart(item)}>
                                                    <i className="fa fa-shopping-cart mr-3"></i>ADD TO CART
                                                </AddCartButton>
                                            ) : (
                                                <Button onClick={login}>Login to view price</Button>
                                            )}
                                        </Row> */}
                                    </NewCollectOrderDetails>
                                </React.Fragment>
                            ) : null}
                        </CheckoutContainer>
                        <ImageComparisonModal
                            isOpen={showComparisonModal}
                            toggle={() => setShowComparisonModal(false)}
                            productKey={item.productKey}
                        />
                    </React.Fragment>
                ) : (
                    <div className="m-5">
                        <Spinner />
                    </div>
                )}
                {item && !error && !isAdded && !isRemoved ? (
                    <DrawerFooter>
                        <div className="d-flex align-items-center justify-content-center" style={{ height: '90px' }}>
                            {isLoggedIn ? (
                                <AddCartButton onClick={() => addToCart(item)}>
                                    <i className="fa fa-shopping-cart mr-3"></i>ADD TO CART
                                </AddCartButton>
                            ) : (
                                <Button onClick={login}>Login to view price</Button>
                            )}
                        </div>
                    </DrawerFooter>
                ) : null}
            </DrawerContainer>
        </CSSTransition>
    );
};

export default ShoppingCartDrawer;

const DrawerContainer = styled.div`
    position: absolute;
    border-radius: 6px;
    background: ${(props) => props.theme.primary.color};
    right: -500px;
    top: 80px;
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    width: 450px;
    z-index: 1001;
    overflow: hidden;
    transition: right 500ms;

    &.cart-enter {
        right: 0px;
    }
    &.cart-enter-done {
        right: 0px;
    }

    &.cart-exit {
        right: -450px;
    }
    &.cart-exit-done {
        right: -500px;
    }
`;

const CloseIcon = styled.button`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 25px;
    height: 25px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    float: right;
    outline: none !important;
    border: none;
`;

const CartTitle = styled.h3`
    margin-top: 40px;
    margin-left: 30px;
    font-weight: 700;
`;

const CartContainer = styled.div`
    margin: 15px 30px 70px 50px;
    div.row {
        margin-bottom: 5px;
    }
`;

const NewCollectOrderDetails = styled.div`
    margin: 20px 30px 70px 30px;
    div.row {
        margin-bottom: 10px;
    }
`;

const PreviewNewCollect = styled.div`
    display: flex;
    justify-content: center;
    margin: 25px 50px 10px;

    img {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        height: 150px;
        padding: 25px;
    }
`;

const AddCartButton = styled(StyledButton)`
    margin: unset;
    padding: 10px 50px;
`;

const Button = styled(StyledButton)`
    text-transform: unset;
`;

const CheckoutContainer = styled.div`
    max-height: calc(100vh - 275px);
    min-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 20px 0px 0px 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    transition: all 1s;
    -webkit-transition: all 1s;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.secondary.color};
        border-radius: 4px;
    }
`;

const PriceText = styled.span`
    &.blur {
        color: transparent;
        text-shadow: 0 0 8px #000;
        user-select: none;
    }
`;

const ErrorContainer = styled.div`
    margin: 60px 20px;

    div.alert-danger {
        border: 1px solid ${(props) => props.theme.primary.text};
        color: ${(props) => props.theme.primary.text};
        background-color: transparent;
    }
`;

const ItemPricePerKm = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1rem;
    color: #1172d3;
    font-weight: 700;
`;

const CartItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CartItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-bottom: none;
    border-radius: ${(props) => props.theme.borderRadius};
    overflow: hidden;
    width: 100%;
`;

const TrashContainer = styled.div`
    margin: auto 0px auto 15px;
`;

const DetailImagePreview = styled.div`
    div {
        display: flex;
        align-items: center;
        height: 100%;
        img {
            width: 85px;
            height: 85px;
        }
        &.unavailable {
            img {
                height: auto;
            }
        }
    }
`;

const DetailsContainer = styled.div`
    overflow-wrap: anywhere;
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const PriceDetail = styled.div`
    margin-right: 10px;
    margin-top: 10px;
    text-wrap: nowrap;
`;

const DrawerFooter = styled.div`
    position: absolute;
    bottom: 0px;
    left: 10px;
    right: 10px;
    background: linear-gradient(360deg, rgba(233, 215, 173, 1) 25%, rgba(233, 215, 173, 0.4) 100%);
`;

const PreviewContainer = styled.div`
    div {
        display: flex;
        justify-content: center;
        margin: 5px 25px 5px 25px;
        flex-direction: column;
        position: relative;
        align-items: center;
        height: 100%;

        img {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            height: 330px;
            max-width: 400px;
            padding: 5px;
        }

        &.unavailable {
            img {
                width: 200px;
                height: auto;
            }
        }
    }
`;
