import React from 'react';
import styled from 'styled-components';

interface DrawerHeaderProps {
    title: string;
    subTitle?: string;
    handleBack?: () => void;
}

const DrawerHeader = (props: DrawerHeaderProps) => {
    return (
        <React.Fragment>
            <TitleContainer>
                {props.handleBack ? (
                    <HeaderButton
                        onClick={() => props.handleBack && props.handleBack()}
                        data-testid="sentinel-back-button"
                    >
                        <BackArrow />
                    </HeaderButton>
                ) : null}
                <Title>{props.title}</Title>
            </TitleContainer>

            {props.subTitle ? <Subtitle>{props.subTitle}</Subtitle> : null}
        </React.Fragment>
    );
};

export default DrawerHeader;

const TitleContainer = styled.h1`
    display: flex;
    justify-content: space-around;
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.black};
    margin: 10px 0px;
`;

const Subtitle = styled.span`
    color: ${(props) => props.theme.black};
    width: 100%;
    display: block;
    margin-top: 4px;
    position: absolute;
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.default};
`;

const HeaderButton = styled.div`
    cursor: pointer;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px 0px;
`;
