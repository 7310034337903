import { useState } from 'react';
import Analytics from '../../lib/user-analytics';
import Modal from '../Shared/Modal/modal';
import ForgotPassword from './forgot-password';
import Login from './login';
import { LoginModalMode } from './login-enum';
import { PasswordReset } from './password-reset';
import Register from './register';
import RegistrationConfirmationModal from './registration-confirmation-modal';

interface LoginRegisterDialogProps {
    isOpen: boolean;
    initialMode?: LoginModalMode;
    onClose?: () => void;
}

const LoginRegisterDialog = (props: LoginRegisterDialogProps) => {
    const [mode, setMode] = useState<LoginModalMode | undefined>(props.initialMode);
    const [email, setEmail] = useState<string>('');

    const handleClickLogin = () => {
        Analytics.Event('Registration', 'Clicked Sign in');
        setMode(LoginModalMode.LOGIN);
    };

    const handleClickRegister = () => {
        Analytics.Event('Registration', 'Clicked Register');
        setMode(LoginModalMode.REGISTER);
    };

    const handleClickForgotPassword = () => {
        Analytics.Event('Registration', 'Clicked Forgot Password');
        setMode(LoginModalMode.FORGOT_PASSWORD);
    };

    const onClose = () => {
        if (props.onClose) {
            setMode(props.initialMode);
            props.onClose();
            Analytics.Event('Registration', 'Clicked close icon');
        }
    };
    if (mode === LoginModalMode.REGISTER_SUCCESS) {
        return <RegistrationConfirmationModal toggle={onClose} />;
    }

    return (
        <Modal
            title={generateDialogTitle(mode)}
            width={'400px !important'}
            isOpen={props.isOpen}
            toggle={() => onClose()}
        >
            {mode === LoginModalMode.LOGIN ? (
                <Login
                    email={email}
                    onEmailChange={setEmail}
                    onClose={onClose}
                    handleClickForgotPassword={handleClickForgotPassword}
                    handleClickRegister={handleClickRegister}
                />
            ) : null}
            {mode === LoginModalMode.REGISTER ? (
                <Register
                    email={email}
                    onEmailChange={setEmail}
                    onClose={onClose}
                    handleClickLogin={handleClickLogin}
                    onRegisterSuccess={() => setMode(LoginModalMode.REGISTER_SUCCESS)}
                />
            ) : null}
            {mode === LoginModalMode.FORGOT_PASSWORD ? (
                <ForgotPassword
                    email={email}
                    onEmailChange={setEmail}
                    onClose={onClose}
                    handleClickLogin={handleClickLogin}
                />
            ) : null}
            {mode === LoginModalMode.PASSWORD_RESET ? (
                <PasswordReset onClose={() => setMode(LoginModalMode.LOGIN)} />
            ) : null}
        </Modal>
    );
};

export default LoginRegisterDialog;

const generateDialogTitle = (mode?: LoginModalMode): string => {
    switch (mode) {
        case LoginModalMode.LOGIN:
            return 'Sign in';
        case LoginModalMode.REGISTER:
            return 'Create your free account';
        case LoginModalMode.FORGOT_PASSWORD:
            return 'Forgot password';
        case LoginModalMode.PASSWORD_RESET:
            return 'Reset Password';
        default:
            return 'Login / Register Dialog';
    }
};

// const RegisterSuccess =styled.div`

// `;
