import { LoginModalMode } from './login-enum';
import LoginRegisterDialog from './login-register-dialog';
import UserHelper from 'lib/user-helper';
import { Navigate, useNavigate } from 'react-router-dom';

interface LoginRegisterDialogProps {
    loginMode?: LoginModalMode;
}

const LoginRegisterPage = (props: LoginRegisterDialogProps) => {
    const navigate = useNavigate();
    const token = UserHelper.validateAndGetIdToken();

    const onLoginDialogClosed = () => {
        navigate('/satellite');
    };

    if (token) {
        return <Navigate replace to="/satellite" />;
    }

    return <LoginRegisterDialog isOpen={true} initialMode={props.loginMode} onClose={onLoginDialogClosed} />;
};

export default LoginRegisterPage;
