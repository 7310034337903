import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import profileIconUrl from '../profile-icon-url';
import Analytics from '../../../lib/user-analytics';
import { useSelector, useDispatch } from 'react-redux';
import NavigationPanel from '../navigation-panel';
import { StyledButton } from 'components/Shared/Buttons/styled-button';
import NoImageryModal from 'components/Shared/no-imagery-modal';
import { selectSatelliteOrders } from 'store/Map/Tasks/selectors';
import { actionFetchSatelliteOrders } from 'store/Map/Tasks/actions';
import OrderList from './order-list';
import { useNavigate } from 'react-router-dom';

const SatelliteOrders = () => {
    const dispatch = useDispatch();
    const orders = useSelector(selectSatelliteOrders);

    const navigate = useNavigate();

    useEffect(() => {
        if (!orders) {
            dispatch(actionFetchSatelliteOrders());
        }
    }, [orders, dispatch]);

    if (!orders) return <LoadingSpinner />;

    return (
        <>
            <NavigationPanel>
                <h3>My Pics from Space queue</h3>
            </NavigationPanel>
            <SatelliteContainer>
                {orders.length === 0 ? (
                    <React.Fragment>
                        <NoImageryModal
                            icon={profileIconUrl.satelliteQueueListUrl}
                            title="It looks like your Pics from Space Queue is empty"
                        />
                        <Button
                            onClick={() => {
                                Analytics.Event('My Satellite Queue', 'Clicked Search for more Pics from Space');
                                navigate('/satellite');
                            }}
                        >
                            Search for more Pics from Space
                        </Button>
                        <br />
                    </React.Fragment>
                ) : (
                    <OrderList orders={orders} />
                )}
            </SatelliteContainer>
        </>
    );
};

export default SatelliteOrders;

const SatelliteContainer = styled.div`
    background-color: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    border: none;
    margin-top: 15px;
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;
`;

const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LoadingSpinner = styled.i`
    position: relative;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: ${Rotate} 1s linear infinite;
    color: ${(props) => props.theme.primary.text};
`;
