import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { actionSatelliteBeginBoxSelect } from '../../../../store/Map/SatelliteArchive/actions';
import { StyledButton } from '../../../Shared/Buttons/styled-button';
import Drawer from '../../drawer';
import DrawerContent from '../../drawer-content';
import DrawerHeader from '../../drawer-header';
import Analytics from 'lib/user-analytics';

const SatelliteSelectAOI = () => {
    const dispatch = useDispatch();

    const startAOI = () => {
        dispatch(actionSatelliteBeginBoxSelect());
        Analytics.Event('Satellite Search', 'Clicked Search This Area');
    };

    return (
        <Drawer>
            <DrawerHeader title={'Satellite Image Search'} subTitle="Search for a Pic from Space" />
            <DrawerContent>
                <Content>
                    <Button onClick={startAOI}>Search this area</Button>
                </Content>
            </DrawerContent>
        </Drawer>
    );
};

export default SatelliteSelectAOI;

const Content = styled.div`
    margin: 0 auto;
    display: block;
    text-align: center;
    width: 100%;
`;

const Button = styled(StyledButton)`
    width: 180px;
    margin-bottom: 22px;
`;
