import React from 'react';
import styled from 'styled-components';

interface DrawerContentProps {
    children: React.ReactNode;
}

/**
 * ThinDrawerContent
 * Standardized drawer content to control height and scroll behavior
 * @required children React.ReactNode
 */
const DrawerContent = (props: DrawerContentProps) => {
    return <DrawerContentContainer>{props.children}</DrawerContentContainer>;
};

export default DrawerContent;

const DrawerContentContainer = styled.div`
    margin-top: 40px;
    min-height: 0px;
    max-height: calc(100vh - 240px);
    transition: none;
    -webkit-transition: none;

    transition: all 1s;
    -webkit-transition: all 1s;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.secondary.color};
        border-radius: 4px;
    }
`;
