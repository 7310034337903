/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Wellknown from 'wellknown';
import turfArea from '@turf/area';
import turfIntersect from '@turf/intersect';
import { Polygon, feature } from '@turf/helpers';

export default class TurfGeoUtil {
    static area = (geometry: any) => {
        if (!geometry) return 0;

        const area = turfArea(geometry);
        const areaKm2 = area / 1000 / 1000;
        return areaKm2;
    };

    static areaWkt = (wkt: string) => {
        const geometry = this.getGeometry(wkt);
        return this.area(geometry);
    };

    static getGeometry = (wkt: string) => {
        const parsedWKT: any = Wellknown.parse(wkt);
        const f = feature(parsedWKT);
        return f.geometry;
    };

    static intersect = (polygon1: Polygon, polygon2: Polygon) => {
        const bbox = turfIntersect(polygon1, polygon2);
        return bbox;
    };

    //get the intersected polygon
    static intersectPolygon = (targetWkt: string, sourceWkt: string) => {
        const targetPolygon = this.getGeometry(targetWkt);
        const sourcePolygon = this.getGeometry(sourceWkt);
        return this.intersect(targetPolygon, sourcePolygon);
    };

    //get the area of intersected polygon
    static intersectArea = (targetWkt: string, sourceWkt: string) => {
        const polygon = this.intersectPolygon(targetWkt, sourceWkt);
        return this.area(polygon);
    };

    //get the intersect percentage from the smallest polygon
    static intersectPercent = (targetWkt: string, sourceWkt: string) => {
        const intersectArea = this.intersectArea(targetWkt, sourceWkt);
        if (!intersectArea) return 0;

        const targetArea = this.areaWkt(targetWkt);
        const sourceArea = this.areaWkt(sourceWkt);

        if (targetArea > sourceArea) return (intersectArea / sourceArea) * 100;
        else return (intersectArea / targetArea) * 100;
    };
}
