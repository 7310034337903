import * as React from 'react';

interface PriceWithCurrencyProps {
    value?: number;
    currency?: string;
}

const PriceWithCurrency = (props: PriceWithCurrencyProps) => {
    const { value, currency } = props;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return <>{`${currency?.toLowerCase() === 'aud' ? 'AUD' : 'US'} ${formatter.format(value ? value : 0)}`}</>;
};

export default PriceWithCurrency;
