import { SatelliteArchiveOrderDTO } from 'api/api-supplier';
import { OrderDTO } from 'api/model';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class GoogleAnalytics {
    public static Event(event: string, params: unknown) {
        try {
            // console.log('Event', event, params);
            //@ts-ignore
            window.gtag('event', event, params);
        } catch {
            //do nothing for now
        }
    }

    public static Login(params: any) {
        this.Event('login', { method: 'Email', ...params });
    }

    public static Search(search_term: string, type = '') {
        this.Event('search', { search_term, type });
    }

    private static ConvertToItem(order: SatelliteArchiveOrderDTO) {
        const item = {
            item_id: order.id,
            item_name: `${order.supplier} ${order.orderType} ${order.product}`,
            price: order.totalPrice / 100,
        };
        return item;
    }

    public static ViewSatelliteItem(order: SatelliteArchiveOrderDTO) {
        const item = this.ConvertToItem(order);
        this.Event('view_item', { currency: order.currency, value: item.price, items: [item] });
    }

    public static AddToCart(order: SatelliteArchiveOrderDTO) {
        const item = this.ConvertToItem(order);
        this.Event('add_to_cart', { currency: order.currency, value: item.price, items: [item] });
    }

    public static RemoveFromCart(order: SatelliteArchiveOrderDTO) {
        const item = this.ConvertToItem(order);
        this.Event('remove_from_cart', { currency: order.currency, value: item.price, items: [item] });
    }

    public static ViewCart(orders: SatelliteArchiveOrderDTO[]) {
        if (orders.length === 0) return;
        const items = orders.map((order) => this.ConvertToItem(order));
        const value = items.reduce((sum, current) => sum + current.price, 0);
        const currency = orders[0].currency;
        this.Event('view_cart', { currency, value, items: items });
    }

    public static BeginCheckout(orders: SatelliteArchiveOrderDTO[]) {
        if (orders.length === 0) return;
        const items = orders.map((order) => this.ConvertToItem(order));
        const value = items.reduce((sum, current) => sum + current.price, 0);
        const currency = orders[0].currency;
        this.Event('begin_checkout', { currency, value, items: items });
    }

    public static Purchased(transaction_id: string, orders: OrderDTO[]) {
        //prevent raising error
        try {
            if (orders.length === 0) return;
            const items = orders.map((order) => {
                return {
                    item_id: order.id,
                    item_name: order.orderNumber,
                    price: order.amount / 100,
                };
            });
            const value = items.reduce((sum, current) => sum + current.price, 0);
            const currency = orders[0].currency;
            this.Event('purchase', { transaction_id, currency, value, items: items });
        } catch (e) {
            //do nothing for now
            // console.log('Purchased error', e);
        }
    }
}
