import Axios from 'axios';
import { Permission, Review } from './model';
import Constants from '../constants';
import UserHelper from '../lib/user-helper';
import CookieHelper from '../lib/cookie-helper';

import {
    setupCache,
    buildMemoryStorage,
    defaultKeyGenerator,
    defaultHeaderInterpreter,
    AxiosCacheInstance,
} from 'axios-cache-interceptor';

export default class Api {
    static axios: AxiosCacheInstance; // Same as the AxiosInstance https://axios-cache-interceptor.js.org/#/pages/typescript

    public static _initialize() {
        // If you want to ignore the cache add { cache: false } to the specific request
        // More Info - https://axios-cache-interceptor.js.org/#/pages/per-request-configuration?id=cacheoverride
        this.axios = setupCache(
            Axios.create({
                baseURL: Constants.API_URL,
            }),
            {
                storage: buildMemoryStorage(),
                generateKey: defaultKeyGenerator,
                headerInterpreter: defaultHeaderInterpreter,
            }
        );
        // Add a response interceptor
        this.axios.interceptors.response.use(
            function (response) {
                if (response.headers['warning']) {
                    console.error(`API WARNING:[${response.config.url}]: ${response.headers['warning']}`);
                }
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            async function (error) {
                error.config.retries = error.config?.retries ?? {
                    count: 0,
                };

                let message = 'Unknown error';
                if (error.response) {
                    const statusCode = error.response.status;

                    if (statusCode === 401) {
                        if (error.config.retries.count < 1) {
                            const updatedToken = await UserHelper.refreshAuth();

                            error.config.retries.count++;

                            Api.axios.defaults.headers.common.Authorization = `${updatedToken}`;

                            // set cache to false due to re-request looks in cache first in which fails
                            return Api.axios.request({ ...error.config, cache: false });
                        }
                    } else {
                        if (error.response.data && error.response.data.error) {
                            const options: ErrorOptions = { cause: error.response.data.code };
                            message = `${error.response.data.error}`;
                            return Promise.reject(Error(message, options));
                        } else if (statusCode == 400) {
                            message = `${error.response.data.error}`;
                        } else {
                            message = `${statusCode}: Unknown error`;
                        }
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    message = 'No response from server';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    message = 'Error: ' + error.message;
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.error(message);
                return Promise.reject(Error(message));
            }
        );

        this.axios.interceptors.request.use(
            function (config) {
                if (config.baseURL === Constants.API_URL) {
                    config.headers.Subdomain = Api.getSubdomain();
                    const idToken = UserHelper.validateAndGetIdToken();
                    if (idToken) {
                        config.headers.Authorization = idToken;
                    }

                    const tracker = CookieHelper.getTrackerCode();
                    if (tracker) {
                        config.headers.UTMTracker = tracker;
                    }
                }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }

    static apiReviewToEnum(review: Review | string | undefined): Review {
        switch (review) {
            case 'PENDING':
                return Review.PENDING;
            case 'APPROVED':
                return Review.APPROVED;
            case 'REJECTED':
                return Review.REJECTED;
            case Review.PENDING:
                return Review.PENDING;
            case Review.APPROVED:
                return Review.APPROVED;
            case Review.REJECTED:
                return Review.REJECTED;
            default:
                return Review.PENDING;
        }
    }

    static apiPermissionsToEnum(permission: string) {
        switch (permission) {
            case 'READ':
                return Permission.Read;
            case 'WRITE':
                return Permission.Write;
            case 'REVIEW':
                return Permission.Review;
            case 'ADMIN':
                return Permission.Admin;
            case 'OWNER':
                return Permission.Owner;
            default:
                return Permission.Read;
        }
    }

    static PermissionEnumToString(permission: Permission) {
        switch (permission) {
            case Permission.Read:
                return 'READ';
            case Permission.Write:
                return 'WRITE';
            case Permission.Review:
                return 'REVIEW';
            case Permission.Admin:
                return 'ADMIN';
            case Permission.Owner:
                return 'OWNER';
            default:
                return 'READ';
        }
    }

    static getSubdomainFromWindow(): string {
        // CAUTION:  This can only be tested in production
        const host = window.location.host;
        if (!host) {
            return Constants.DOMAIN;
        }

        const parts = host.split('.');
        if (parts.length === 3) {
            const subdomain = parts[0];
            if (subdomain === 'www') {
                return Constants.DOMAIN;
            }
            return subdomain + '.' + Constants.DOMAIN;
        } else {
            return Constants.DOMAIN;
        }
    }

    static getSubdomainForLocalDevelopment(): string | undefined {
        let host = window.location.host;
        if (process.env.REACT_APP_ENVIRONMENT === 'test') {
            host = host.replace(/192\.168\.\d+\.\d+/g, 'localhost');
        }
        if (host.includes('localhost')) {
            host = host.replace('https://', '').replace('http://', '');
            const port = host.split(':')[1];
            host = host.replace(':', '').replace(port, '');
            const hostParts = host.split('.');
            if (hostParts.length === 0 || hostParts[0] === 'localhost' || hostParts[0] === 'www') {
                return Constants.DOMAIN;
            } else {
                return hostParts[0] + '.' + Constants.DOMAIN;
            }
        }
        return undefined;
    }

    static getSubdomain(): string {
        return Constants.DOMAIN;
    }

    static returnHeaders(accessToken) {
        if (accessToken) {
            return { Authorization: accessToken, Subdomain: this.getSubdomain() };
        } else {
            return { Subdomain: this.getSubdomain() };
        }
    }
}

Api._initialize();
