import React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';

const ErrorBoundary = (props) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                return (
                    <ErrorBackground>
                        <AlertContainer>
                            <h3>
                                Houston,
                                <br />
                                we have a problem ...
                            </h3>
                            <p>
                                We've had a Main B Bus Undervolt! <br />
                                Just a joke, but we're still detecting an error. Please try to reload the page.
                            </p>
                            <button onClick={() => window.open('/', '_self')}>RELOAD PAGE</button>
                            <ReportButton
                                onClick={() => {
                                    const eventId = Sentry.captureException(error);
                                    Sentry.showReportDialog({ eventId });
                                }}
                            >
                                SEND US A REPORT
                            </ReportButton>
                        </AlertContainer>
                    </ErrorBackground>
                );
            }}
        >
            {props.children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;
const ErrorBackground = styled.div`
    background-image: url(/assets/PfS_UI_bg_pixellated_sepia.jpg);
    background-size: cover;
    min-height: 100vh;
    padding: 50px;
    color: ${(props) => props.theme.primary.color};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AlertContainer = styled.div`
    width: 500px;
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    border-radius: 6px;
    background: ${(props) => props.theme.primary.color};
    display: block;
    padding: 10px;
    margin-top: -10vh;
`;

const ReportButton = styled.button`
    background-color: ${(props) => props.theme.secondary.color};
    border: 1px solid white !important;
    display: inline-block !important;
    color: ${(props) => props.theme.secondary.text};
    margin-left: 10px;
`;
