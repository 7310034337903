import React, { useState } from 'react';
import { Form, FormGroup, Input, Label, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import Spinner from '../Shared/Spinner/spinner';
import { passwordActiveUrl, passwordInActiveUrl } from './registration-icons';

interface PasswordResetProps {
    onClose: () => void;
}

export const PasswordReset = (props: PasswordResetProps) => {
    const id = UriHelper.tryGetParam('id') || '';
    const email = UriHelper.tryGetParam('email') || '';

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const handleSubmitResetPassword = (e) => {
        e.preventDefault();

        Analytics.Event('Password reset', 'Clicked Reset password button');

        if (!UserHelper.isEmailValid(email)) {
            setError('Something has gone wrong, please open from the email link sent to you');
            return;
        }

        if (!password && !confirmPassword) {
            setError('Please enter a password and confirmation password');
            return;
        }

        if (password !== confirmPassword) {
            setError('Please make sure the password and confirm password are the same.');
            setConfirmPassword('');
            return;
        }

        if (password.length < 6) {
            setError('Please ensure the password is 6 or more characters long');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (!error && password && email && id) {
            setIsSubmitting(true);
            ApiUser.resetPasswordPromise(id, email, password)
                .then(() => {
                    setIsSubmitting(false);
                    setSubmitted(true);
                    setTimeout(() => {
                        setSubmitted(false);
                        Analytics.Event('Password reset', 'Reset password submitted');
                        props.onClose();
                    }, 3000);
                })
                .catch((error) => {
                    if (error.toString().includes('reset id is null')) {
                        setError(
                            'It looks like you may have used this reset token or something has gone wrong, please request another reset email'
                        );
                    } else {
                        setError(error);
                    }
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <React.Fragment>
            {!submitted ? (
                <>
                    <ResetModalBody>
                        <Form onSubmit={(e) => handleSubmitResetPassword(e)}>
                            <FormGroup>
                                <Label for="password">Please enter a new password and confirm</Label>
                                <Input
                                    data-sentry-block
                                    data-testid="password"
                                    style={{ marginTop: '20px' }}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setError('');
                                    }}
                                />
                                <ResetPasswordIcon
                                    onClick={() => setShowPassword(!showPassword)}
                                    showPasswordIcon={showPassword}
                                />
                                <Input
                                    data-sentry-block
                                    data-testid="confirm-password"
                                    style={{ marginTop: '20px' }}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setError('');
                                    }}
                                />
                                <ResetPasswordIcon
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    showPasswordIcon={showConfirmPassword}
                                />
                            </FormGroup>
                            {error && (
                                <ResetModalError>
                                    <ResetModalErrorIcon />
                                    {error}
                                </ResetModalError>
                            )}
                            {!isSubmitting ? (
                                <ResetSubmitButton
                                    onSubmit={(e) => handleSubmitResetPassword(e)}
                                    error={error ? true : false}
                                >
                                    SUBMIT
                                </ResetSubmitButton>
                            ) : (
                                <Spinner />
                            )}
                        </Form>
                    </ResetModalBody>
                </>
            ) : (
                <>
                    <ResetModalBody>
                        <h2>Your password has been reset</h2>
                        <p>Redirecting to login</p>
                    </ResetModalBody>
                </>
            )}
        </React.Fragment>
    );
};

const ResetModalError = styled.div`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const ResetModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='18' stroke='%236E322B'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 20px;
    margin-right: 8px;
    padding: 3px;
`;

const ResetModalBody = styled(ModalBody)`
    label {
        color: ${(props) => props.theme.secondary.color};
        width: 100%;
    }

    input {
        background-color: transparent;
        color: ${(props) => props.theme.primary.hover};
        border: 1px solid;
        border-color: ${(props) => props.theme.secondary.color};
        -webkit-text-fill-color: ${(props) => props.theme.primary.hover};

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active,
        :focus {
            background-color: transparent;
            border-color: #6e322b;
            box-shadow: 0 0 0 0.2rem rgb(110 50 43 / 10%);
            color: ${(props) => props.theme.primary.hover};
        }
    }

    h2 {
        color: ${(props) => props.theme.secondary.color};
    }

    p {
        color: ${(props) => props.theme.primary.hover};
        text-align: center;
        margin: 25px 0px;
    }
`;

interface PasswordIconProps {
    showPasswordIcon: boolean;
}
const ResetPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url('${(props) => (props.showPasswordIcon ? passwordActiveUrl : passwordInActiveUrl)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -26px;
    margin-right: 5px;
`;

interface ErrorProps {
    error: boolean;
}

const ResetSubmitButton = styled.button<ErrorProps>`
    margin: 0 auto;
    display: block;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.primary.color};
    border-radius: 4px;
    background-color: ${(props) => props.theme.secondary.color};
    width: -webkit-fill-available;

    :not(:disabled) {
        cursor: pointer;
        background-color: ${(props) => props.theme.secondary.color};
        -webkit-text-fill-color: ${(props) => props.theme.primary.color};
    }
`;
