import React from 'react';
import styled from 'styled-components';

interface NavigationPanelProps {
    children: React.ReactNode;
}

const NavigationPanel = (props: NavigationPanelProps) => {
    const { children } = props;

    return <ProfileEditNavigationContainer>{children}</ProfileEditNavigationContainer>;
};

export default NavigationPanel;

const ProfileEditNavigationContainer = styled.div`
    background-color: ${(props) => props.theme.primary.color};
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.primary.text};
    margin: auto;
    flex-direction: none;
    padding-left: 20px;
    padding-right: 10px;

    @media only screen and (max-width: 600px) {
        background-color: transparent;
        border-radius: 0px;
        flex-direction: row;
        padding-bottom: 5px;
        margin-bottom: 10px;
        margin-top: 5px;
        border-bottom: 1px solid white;
    }

    h3 {
        margin: 0px;
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;

        @media only screen and (max-width: 600px) {
            margin-top: 10px;
        }
    }

    p {
        margin: 0px;
        opacity: 0.8;
    }
`;
