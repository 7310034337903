import React, { useState } from 'react';
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import ApiUser from '../../api/api-user';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { StyledButton } from '../Shared/Buttons/styled-button';

interface ForgotPasswordProps {
    email: string;
    onEmailChange: (email: string) => void;
    onClose: () => void;
    handleClickLogin: () => void;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const { email } = props;
    const [error, setError] = useState<string | undefined>(undefined);
    const [forgotPasswordSent, setForgotPasswordSent] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);

    const handleSubmitForgotPassword = (e) => {
        setIsResetting(true);
        e.preventDefault();
        setError(undefined);
        setForgotPasswordSent(false);

        if (email === undefined || email.length === 0) {
            setError('Please enter an email address to send the request');
            setIsResetting(false);
            return;
        }

        if (email && !UserHelper.isEmailValid(email)) {
            setError('The email looks wrong, please check and try again');
            setIsResetting(false);
            return;
        }

        Analytics.Event('Forgot Password', 'Clicked Reset password');
        if (email) {
            ApiUser.requestPasswordResetPromise(email)
                .then(() => {
                    setError(undefined);
                    setForgotPasswordSent(true);
                    setIsResetting(false);
                })
                .catch(() => {
                    setError('Something has gone wrong please contact support');
                    setForgotPasswordSent(true);
                    setIsResetting(false);
                });
        }
    };

    const handleDidNotReceivePassword = (e) => {
        setIsResetting(true);
        e.preventDefault();
        setError(undefined);

        if (email === undefined || email.length === 0) {
            setError('Please enter an email address to send the request');
            setIsResetting(false);
            return;
        }

        if (email && !UserHelper.isEmailValid(email)) {
            setError('The email looks wrong, please check and try again');
            setIsResetting(false);
            return;
        }

        if (email) {
            ApiUser.requestPasswordResetPromise(email)
                .then(() => {
                    setError(undefined);
                    setIsResetting(false);
                })
                .catch(() => {
                    setError('Something has gone wrong please contact support');
                    setForgotPasswordSent(true);
                    setIsResetting(false);
                });
        }
        Analytics.Event('Forgot Password', 'Clicked Did not receive an email');
    };

    const handleGoToLogin = () => {
        Analytics.Event('Forgot Password', 'Clicked go to Login');
        props.handleClickLogin();
    };
    const handleGoBackToSignIn = () => {
        props.handleClickLogin();
        Analytics.Event('Forgot Password', 'Clicked to go back to sign in');
    };
    return (
        <React.Fragment>
            {!forgotPasswordSent ? (
                <>
                    <ForgotModalBody>
                        <Form onSubmit={(e) => handleSubmitForgotPassword(e)}>
                            <FormGroup>
                                <Label for="email">Enter your email account</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    placeHolder="Email Address"
                                    suggested="email"
                                    value={email}
                                    onChange={(e) => props.onEmailChange(e.target.value)}
                                />
                                <ForgotFormEmailIcon />
                            </FormGroup>
                            {error && (
                                <ForgotModalError>
                                    <ForgotModalErrorIcon />
                                    {error}
                                </ForgotModalError>
                            )}
                            <ForgotSubmitButton
                                onClick={(e) => handleSubmitForgotPassword(e)}
                                error={error ? true : false}
                            >
                                {isResetting ? <LoadingSpinner className="fa fa-spinner fa-spin" /> : 'RESET PASSWORD'}
                            </ForgotSubmitButton>
                        </Form>
                    </ForgotModalBody>
                    <ForgotModalFooter>
                        Got here by accident?
                        <ForgotFooterLink onClick={handleGoBackToSignIn}>Go back to Sign in</ForgotFooterLink>
                    </ForgotModalFooter>
                </>
            ) : (
                <>
                    <ForgotModalBody>
                        <Form>
                            <ForgotPasswordSubmittedNotification>
                                If the email exists you will receive a reset notification shortly, otherwise you can try
                                again.
                            </ForgotPasswordSubmittedNotification>
                            <ForgotSubmitOptionButton onClick={(e) => handleDidNotReceivePassword(e)}>
                                {isResetting ? (
                                    <LoadingSpinner className="fa fa-spinner fa-spin" />
                                ) : (
                                    'I DID NOT RECEIVE AN EMAIL'
                                )}
                            </ForgotSubmitOptionButton>
                            <ForgotSubmitOptionButton onClick={handleGoToLogin}>GO TO LOGIN</ForgotSubmitOptionButton>
                        </Form>
                    </ForgotModalBody>
                    <ForgotModalFooter>
                        I think I entered the wrong email
                        <ForgotFooterLink
                            onClick={() => {
                                props.onEmailChange('');
                                setError(undefined);
                                setForgotPasswordSent(false);
                            }}
                        >
                            {' '}
                            Go back
                        </ForgotFooterLink>
                    </ForgotModalFooter>
                </>
            )}
        </React.Fragment>
    );
};

export default ForgotPassword;

const ForgotModalError = styled.div`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const ForgotModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%236E322B'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const ForgotModalBody = styled(ModalBody)`
    label {
        color: ${(props) => props.theme.primary.hover};
        width: 100%;
    }

    input {
        background-color: transparent;
        color: ${(props) => props.theme.primary.hover};
        border: 1px solid;
        border-color: ${(props) => props.theme.secondary.color};
        -webkit-text-fill-color: ${(props) => props.theme.primary.hover};

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active,
        :focus {
            background-color: transparent;
            border-color: ${(props) => props.theme.secondary.color};
            box-shadow: ${(props) => props.theme.boxShadow.shallow};
            color: ${(props) => props.theme.primary.hover};
            -webkit-text-fill-color: ${(props) => props.theme.primary.hover};
        }
    }
`;

const ForgotFormEmailIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.73714 12.7607C4.78466 12.7082 4.81115 12.6395 4.81034 12.5688V6.39123C5.24076 6.22647 5.6357 5.98059 5.97388 5.66019C6.63478 5.03403 6.99884 4.20065 7 3.31549C6.99956 2.43057 6.63568 1.59785 5.9744 0.971329C5.3128 0.344507 4.43486 0.000853209 3.4998 1.23742e-07C1.56919 -0.000495257 -0.000667802 1.48645 2.13114e-07 3.31554C-0.000115961 4.20236 0.362635 5.0345 1.02389 5.66099C1.36215 5.98147 1.75683 6.22606 2.18815 6.39134L2.18734 8.42156C2.18789 8.50547 2.2233 8.58326 2.28877 8.64138L2.89292 9.17497L2.28874 9.71128C2.22522 9.76805 2.18743 9.84607 2.18821 9.93008C2.1874 10.0125 2.22408 10.0925 2.28804 10.1484L2.89388 10.6845L2.28836 11.2183C2.22475 11.2751 2.1883 11.3546 2.18746 11.4371C2.1881 11.5209 2.21639 11.5924 2.2888 11.657L2.89303 12.1918L2.28888 12.7271C2.22063 12.7853 2.18557 12.8702 2.18766 12.9561C2.19152 13.0334 2.22394 13.1038 2.28017 13.1571C2.28824 13.1647 2.29687 13.1723 2.30585 13.1794L3.30144 13.9341C3.43575 14.0363 3.63198 14.0175 3.74294 13.8926L4.73714 12.7607ZM3.49954 2.7497C2.94935 2.74943 2.50437 2.32784 2.50466 1.80713C2.50464 1.28595 2.94953 0.864446 3.49936 0.864722C4.04879 0.864612 4.49388 1.28581 4.49432 1.80721C4.49426 2.32776 4.0489 2.74937 3.49954 2.7497ZM3.89758 12.1067C3.83217 12.1067 3.77201 12.0807 3.72943 12.0403C3.68685 12 3.65934 11.9429 3.65934 11.881L3.66016 6.62412C3.82066 6.6184 3.97856 6.60188 4.1345 6.575L4.13505 11.8817C4.13505 12.0056 4.02834 12.1067 3.89758 12.1067Z' fill='%236E322B'/%3E%3Cpath d='M8 7L11 10L14 7' stroke='%236E322B'/%3E%3C/svg%3E ");
    width: 15px;
    height: 14px;
    float: right;
    margin-top: -26px;
    margin-right: 6px;
`;

interface ErrorProps {
    error: boolean;
}

const ForgotSubmitButton = styled(StyledButton)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: ${(props) => props.theme.secondary.color};
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        color: ${(props) => props.theme.secondary.color};
        -webkit-text-fill-color: ${(props) => props.theme.primary.color};
    }
`;
const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LoadingSpinner = styled.i`
    position: relative;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: ${Rotate} 1s linear infinite;
    color: ${(props) => props.theme.primary.text};
`;

const ForgotSubmitOptionButton = styled(StyledButton)`
    margin: 20px auto 10px auto;
    background-color: ${(props) => props.theme.primary.color};
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: ${(props) => props.theme.secondary.color};
    }
`;

const ForgotModalFooter = styled(ModalFooter)`
    color: ${(props) => props.theme.primary.hover};
    border-top: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    display: block;
    text-align: center;
`;

const ForgotFooterLink = styled.a`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;

const ForgotPasswordSubmittedNotification = styled.div`
    color: ${(props) => props.theme.primary.hover};
    margin: 10px;
`;
