import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { StyledButton } from '../Shared/Buttons/styled-button';
import { usePageVisitCount } from '../../lib/use-page-visit-count';
import LoginRegisterDialog from './login-register-dialog';
import { LoginModalMode } from './login-enum';
import { selectLoggedIn } from '../../store/App/selectors';
import { isMobile } from 'react-device-detect';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';

export const RegisterCTA = () => {
    const isLoggedIn = useSelector(selectLoggedIn);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState<boolean>(false);

    const { hasHitVisitCount } = usePageVisitCount(['/explore/', '/maps/', '/browse/'], 3);

    useEffect(() => {
        if (hasHitVisitCount && !isLoggedIn && !UserHelper.validateToken()) {
            Analytics.Event('Registration', 'CTA', 'OPENED');
            setIsOpen(true);
        }
    }, [hasHitVisitCount, isLoggedIn]);

    const openRegisterPage = () => {
        setIsOpen(false);
        setIsRegisterOpen(true);
        Analytics.Event('Registration', 'CTA', 'CLICKED REGISTER NOW');
    };

    const closeRegisterPage = () => {
        Analytics.Event('Registration', 'CTA', 'CLOSED');
        setIsOpen(!isOpen);
    };

    if (isRegisterOpen) {
        return (
            <LoginRegisterDialog
                isOpen={isRegisterOpen}
                initialMode={LoginModalMode.REGISTER}
                onClose={() => setIsRegisterOpen(!isRegisterOpen)}
            />
        );
    }

    if (!isOpen) {
        return <React.Fragment />;
    }

    return (
        <CTALightbox onClick={() => closeRegisterPage()}>
            <CTABody>
                <CTAHeader>
                    {isMobile ? (
                        <CTAModalIntro>You Love Our Maps!</CTAModalIntro>
                    ) : (
                        <CTAModalIntro>We're Happy You Love Our Maps!</CTAModalIntro>
                    )}
                    <CTACloseIcon className={'fa fa-close'} onClick={() => closeRegisterPage()} />
                </CTAHeader>
                <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                <CTABodyContent>
                    {isMobile ? (
                        <CTAText>Register now for access to:</CTAText>
                    ) : (
                        <CTAText>
                            Want to get more out of Soar.Earth? Register for a{' '}
                            <CTATextYellow>FREE account</CTATextYellow> and enjoy full access:
                        </CTAText>
                    )}
                    <CTAList>
                        <CTAListItem>order on-demand satellite imagery</CTAListItem>
                        <CTAListItem>comment and draw on maps</CTAListItem>
                        <CTAListItem>share maps on social media</CTAListItem>
                        <CTAListItem>upload your own maps</CTAListItem>
                        <CTAListItem>engage with the community</CTAListItem>
                        <CTAListItem>and much more...!</CTAListItem>
                    </CTAList>
                </CTABodyContent>
                <CTAButton onClick={() => openRegisterPage()}>REGISTER TODAY</CTAButton>
                <CTAText>Discover everything that Soar.Earth has to offer</CTAText>
            </CTABody>
        </CTALightbox>
    );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CTALightbox = styled.div`
    background-color: rgba(0, 0, 0, 0.67);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    animation: ${fadeIn} 400ms linear;
`;

const CTABody = styled.div`
    color: white;
    background: black;
    margin: 20vh auto;
    padding: 50px 50px 25px 50px;
    width: 90%;
    max-width: 650px;
    border-radius: 6px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
        url('../assets/backgrounds-large/register-cta_large.png');

    @media only screen and (max-width: 600px) {
        width: 95%;
        padding: 5%;
    }
`;

const CTAHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: inherit;
`;

const CTACloseIcon = styled.i`
    font-size: 25px;
    cursor: pointer;
    margin: -35px -35px 0px 0px;
    text-shadow: 0 1px 0 black;

    @media only screen and (max-width: 890px) {
        margin: -10px -10px 0px 0px;
    }
`;

const Divider = styled.img`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const CTAModalIntro = styled.h2`
    text-shadow: 0 1px 0 black;
    color: inherit;
    font-size: 32px;
    text-align: left;
    font-weight: bold;
`;

const CTAText = styled.p`
    color: inherit;
    font-size: 20px;
    text-shadow: 0 1px 0 black;
    font-weight: bold;
    line-height: initial;
`;

const CTATextYellow = styled.span`
    color: #eed926;
`;

const CTABodyContent = styled.div`
    color: inherit;
    max-width: 90%;

    @media only screen and (max-width: 600px) {
        width: auto;
    }
`;

const CTAList = styled.ul`
    padding-inline-start: 15px;
    line-height: initial;
`;

const CTAListItem = styled.li`
    color: inherit;
    text-shadow: 0 1px 0 black;
    font-size: 16px;
    font-weight: bold;
`;

const CTAButton = styled(StyledButton)`
    padding: 12px 50px;
    font-size: 16px;
    margin-bottom: 10px;
`;
