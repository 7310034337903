import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    actionSatelliteMouseOut,
    actionSatelliteMouseOver,
    actionSatelliteSetFeatureActive,
    actionSatelliteSetFeatureInactive,
} from 'store/Map/SatelliteArchive/actions';
import { FlatButton } from '../../../../Shared/Buttons/styled-button';

import { SatelliteArchiveImageryDTO } from 'api/api-supplier';
import { selectSatelliteAOI, selectSatelliteSelectedFeature } from 'store/Map/SatelliteArchive/selectors';
import SatelliteArchiveItem from './satellite-archive-item';
import Analytics from 'lib/user-analytics';
import SatelliteDateFilter, { DateRange, getDateRanges } from './satellite-date-filter';
import { actionShoppingCartOpenDrawer } from 'store/ShoppingCart/actions';
import GeoUtil from 'lib/geo-util';

interface Props {
    archiveResults: SatelliteArchiveImageryDTO[];
}

const SatelliteArchiveResults = ({ archiveResults }: Props) => {
    const dispatch = useDispatch();
    const satelliteAOI = useSelector(selectSatelliteAOI);
    const selectedFeature = useSelector(selectSatelliteSelectedFeature);

    const [resultsCount, setResultsCount] = useState(7);
    const [dateRanges, setDateRanges] = useState<DateRange[] | undefined>(undefined);
    const [date, setDate] = useState<DateRange | undefined>(undefined);

    useEffect(() => {
        if (archiveResults.length) {
            const ranges = getDateRanges(archiveResults[archiveResults.length - 1].date);
            setDateRanges(ranges);
            setDate(ranges[0]);
        }
    }, [archiveResults]);

    useEffect(() => {
        //check if the selected scene is within the aoi
        if (selectedFeature && satelliteAOI) {
            const selectedFeatureBounds = GeoUtil.latLngBoundsFromPolygonWKT(selectedFeature.geometryWKT);
            if (!satelliteAOI.intersects(selectedFeatureBounds)) {
                dispatch(actionSatelliteSetFeatureInactive());
                dispatch(actionSatelliteMouseOut());
            }
        }
    }, [selectedFeature, satelliteAOI, dispatch]);

    useEffect(() => {
        setResultsCount(7);
    }, [date]);

    const handleShowMore = () => {
        setResultsCount(resultsCount + 7);
        Analytics.Event('Satellite Search', 'Clicked Show More archive results');
    };

    if (archiveResults.length === 0)
        return (
            <ResultsContainer>
                <NoResultsContainer>No results on the Area of Interest.</NoResultsContainer>
            </ResultsContainer>
        );

    if (!dateRanges || !date) return null;

    const filteredResults = archiveResults.filter(
        (result) => new Date(result.date) >= date.startDate && new Date(result.date) <= date.endDate
    );

    return (
        <React.Fragment>
            <FilterText>Filter by date</FilterText>
            <SatelliteDateFilter date={date} setDate={setDate} dateRanges={dateRanges} />
            <ResultsContainer>
                {filteredResults.slice(0, resultsCount).map((archiveImagery, i) => {
                    return (
                        <SatelliteArchiveItem
                            key={i}
                            result={archiveImagery}
                            isSelected={(selectedFeature && selectedFeature.id === archiveImagery.id) || false}
                            setResultActive={(result) => {
                                dispatch(actionSatelliteSetFeatureActive(result));
                                dispatch(actionShoppingCartOpenDrawer('ARCHIVE'));
                            }}
                            setResultInActive={() => dispatch(actionSatelliteSetFeatureInactive())}
                            setResultMouseOut={() => dispatch(actionSatelliteMouseOut())}
                            setResultMouseOver={(result) => dispatch(actionSatelliteMouseOver(result))}
                        />
                    );
                })}

                {filteredResults.length === 0 && (
                    <NoResultsContainer>No results on the selected date.</NoResultsContainer>
                )}

                {filteredResults.length > resultsCount && (
                    <ShowMoreButton onClick={handleShowMore}>Show more</ShowMoreButton>
                )}
            </ResultsContainer>
        </React.Fragment>
    );
};

export default SatelliteArchiveResults;

const ResultsContainer = styled.div`
    margin-top: 15px;
`;

const ShowMoreButton = styled(FlatButton)``;

const NoResultsContainer = styled.div`
    color: ${(props) => props.theme.black};
    display: flex;
    justify-content: center;
    margin: 20px 0px 10px 0px;
    padding: 10px;
`;

const FilterText = styled.div`
    color: ${(props) => props.theme.black};
    font-weight: 500;
    text-align: left;
`;
