import React, { useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../package.json';
import Api from './api/api';
import Maintenance from './components/Error/maintenance';
import Mobile from './components/Mobile/mobile';
import LoginDialog from './components/Registration/login-dialog';
import { RegisterCTA } from './components/Registration/register-cta';
import Constants from './constants';
import CookieHelper from './lib/cookie-helper';
import { useQuery } from './lib/uri-helper';
import Analytics from './lib/user-analytics';
import Routes from './routes';
import { selectMaintenance } from './store/App/selectors';
import { selectShoppingCartOrders } from 'store/ShoppingCart/selectors';
import LocalStorageUtil from 'lib/local-storage-util';
import { actionShoppingCartInitialize } from 'store/ShoppingCart/actions';
import { useLocation } from 'react-router-dom';

const ConfiguredRoot = () => {
    const dispatch = useDispatch();
    const maintenance = useSelector(selectMaintenance);
    const cartOrders = useSelector(selectShoppingCartOrders);

    const location = useLocation();
    const query = useQuery();

    useEffect(() => {
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);

    useEffect(() => {
        const trackerCode = query.get('t');
        if (trackerCode) {
            CookieHelper.setTrackerCode(trackerCode);
        }
    }, [query]);

    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_ID_G4);
        const isLoggedIn = Analytics.isLoggedIn();

        Analytics.setIsLoggedIn(isLoggedIn);
        Analytics.setDeviceDimension();

        //restore shopping cart from localstorage
        dispatch(actionShoppingCartInitialize(LocalStorageUtil.getShoppingCart()));

        console.log('Picsfromspace v' + packageJson.version);
        console.log('React stage: ' + process.env.REACT_APP_STAGE);
        console.log('React environment: ' + process.env.REACT_APP_ENVIRONMENT);
        console.log('Subdomain: ' + Api.getSubdomain());
        console.log('Root API: ' + Constants.API_URL);
    }, [dispatch]);

    //update local storage for every shopping cart change
    useEffect(() => {
        LocalStorageUtil.saveShoppingCart(cartOrders);
    }, [cartOrders]);

    if (maintenance) {
        return <Maintenance />;
    }

    return (
        <React.Fragment>
            <RegisterCTA />
            {isBrowser ? (
                <React.Fragment>
                    <LoginDialog />

                    <Routes />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Mobile />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ConfiguredRoot;
