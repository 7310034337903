import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledButton = styled.button`
    outline: none !important;
    display: block;
    margin: 6px auto;
    background-color: ${(props) => props.theme.secondary.color};
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.secondary.text};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.text};
    font-size: 1rem;
    border-radius: 4px;
    padding: 8px 25px;

    :active,
    :focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const StyledTransparentButton = styled(StyledButton)`
    background-color: transparent !important;
    border: 1px solid ${(props) => props.theme.primary.text} !important;
    color: ${(props) => props.theme.primary.text};
    -webkit-text-fill-color: ${(props) => props.theme.primary.text};

    &:hover {
        opacity: 0.6;
    }
`;

export const StyledDeleteButton = styled(StyledButton)`
    background-color: crimson !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.primary.color};

    &:hover {
        opacity: 0.6;
    }
`;

export const FlatButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    color: ${(props) => props.theme.primary.text};
    margin: 0 auto;
    display: block;
    cursor: pointer;
    outline: 0 !important;
`;

export const LinkButton = styled(Link)`
    display: inline-block;
    margin: 6px auto;
    background-color: ${(props) => props.theme.secondary.color} !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.secondary.text};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.text};
    font-size: 1rem;
    border-radius: 4px;
    padding: 8px 25px;
    text-decoration: none;

    :active,
    :focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }
`;
