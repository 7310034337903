import styled from 'styled-components';
import PageContainer from './page-container';
import React from 'react';
import ScrollToTopButton from './scroll-to-top-button';

export const PrivacyAgreementPage = () => {
    return (
        <PageContainer header={<PrivacyAgreementHeader />}>
            <PrivacyAgreement />
        </PageContainer>
    );
};

export const PrivacyAgreementHeader = () => {
    return (
        <React.Fragment>
            <h3>Pics From Space Privacy Policy</h3>
            <p>
                Effective Date: July 1<sup>st</sup>&nbsp;2023
            </p>
        </React.Fragment>
    );
};

const PrivacyAgreement = () => {
    return (
        <PrivacyAgreementContainer>
            <PrivacyAgreementBody>
                <p>
                    This privacy policy ("Policy") describes how Soar.Earth Ltd and all related entities (together "we",
                    "us", "our") collects, uses, shares, and stores personal information of users of the website,&nbsp;
                    <a href="https://picsfromspace.com" target="_blank">
                        https://picsfromspace.com
                    </a>
                    &nbsp; ("the Site"). This Privacy Policy applies to the Site, applications, products and services
                    (collectively, "the Services") on or in which it is posted, linked, or referenced and includes any
                    sub-domains of the site.
                </p>
                <p>
                    By using the Services, the user ("you", "your") accepts the terms of this Privacy Policy and
                    our&nbsp;
                    <a href="https://picsfromspace.com/terms" target="_blank">
                        Terms of Service
                    </a>
                    , and consent to our collection, use, disclosure, and retention of your information as described in
                    this Policy.
                </p>
                <h4>
                    IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, THEN PLEASE DO NOT
                    USE ANY OF THE SERVICES.&nbsp;
                </h4>
                <p>
                    Please note that this Privacy Policy does not apply to information collected through third-party
                    websites or services that you may access through the Services or that you submit to us through
                    email, text message or other electronic message or offline.
                </p>
                <h4>INFORMATION WE COLLECT</h4>
                <div>
                    <p>Information we collect from you may include: </p>
                    <ul>
                        <li>Information regarding transactions or downloads, both paid and unpaid;</li>
                        <li>Data automatically collected from your interactions with the Service ("Log Data")</li>
                        <li>Information about you that you consent to provide such as your:</li>
                        <li style={{ marginLeft: '40px' }}>name</li>
                        <li style={{ marginLeft: '40px' }}>email address</li>
                        <li style={{ marginLeft: '40px' }}>other information within Your user profile</li>
                        <li>
                            Feedback and correspondence, such as information you provide in your responses to surveys
                            when you participate in market research activities, report a problem with Service, receive
                            customer support or otherwise correspond with us;
                        </li>
                        <li>
                            Usage information, such as information about how you use the Site or the Service and
                            interact with us; and
                        </li>
                        <li>
                            Marketing information, such your preferences for receiving marketing communications and
                            details about how you engage with them.
                        </li>
                    </ul>

                    <h5>Information we get from others</h5>

                    <p>
                        We may get information about you from Third Party sources and we may add this to information we
                        get from your use of the Services. Such information may include:
                    </p>

                    <table border={1}>
                        <thead>
                            <tr>
                                <th style={{ width: '15%' }}>Third Party Service</th>
                                <th>Basis of data collection</th>
                                <th style={{ width: '15%' }}>Privacy Policy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Stripe</td>
                                <td>
                                    {' '}
                                    Information in respect of any transactions you make on the Site for legal reasons
                                    and fraud prevention. The service is provided by Stripe.com which allows online
                                    transactions without storing any credit card information.
                                </td>
                                <td>
                                    <a href="https://stripe.com/en-au/privacy" target="_blank">
                                        {' '}
                                        Stripe Privacy Policy
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Drift</td>
                                <td>
                                    To enable chatbox functionality, identify specific user chat sessions, allow support
                                    to see previous issues and reconnect with previous support requests.
                                </td>
                                <td>
                                    <a href="https://www.drift.com/privacy-policy/" target="_blank">
                                        {' '}
                                        Drift Privacy Policy
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Google Analytics</td>
                                <td>
                                    We need this data to understand how you use our website so we can improve its design
                                    and functionality. We also need the data to inform future marketing campaign
                                    effectiveness.
                                </td>
                                <td>
                                    <a href="https://policies.google.com/privacy?hl=en-US" target="_blank">
                                        {' '}
                                        Google Privacy Policy
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Google AdSense</td>
                                <td>Asessment of advertising efficiency across websites.</td>
                                <td>
                                    <a href="https://policies.google.com/privacy?hl=en-US" target="_blank">
                                        Google Privacy Policy
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Microsoft Clarity</td>
                                <td>
                                    To capture how you use and interact with our website through behavioral metrics,
                                    heatmaps, and session replay to improve and market our products/services. Website
                                    usage data is captured using first and third-party cookies and other tracking
                                    technologies to determine the popularity of products/services and online activity.
                                    Additionally, we use this information for site optimization, fraud/security
                                    purposes, and advertising.
                                </td>
                                <td>
                                    <a href="https://privacy.microsoft.com/privacystatement" target="_blank">
                                        Microsoft Privacy Statement
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Sentry</td>
                                <td>
                                    For error monitoring, session replays where an error occurs within the Site and
                                    performance profiling to improve design and functionality of the site and correct
                                    errors in performance.
                                </td>
                                <td>
                                    <a href="https://sentry.io/privacy/" target="_blank">
                                        Sentry Privacy Policy
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />

                    <h5>Information automatically collected</h5>
                    <p>
                        We automatically record certain information about how you use the Site (we refer to this
                        information as "Log Data") which may include:
                    </p>
                    <ul>
                        <li>IP address used</li>
                        <li>browser</li>
                        <li>device type</li>
                        <li>device operating system</li>
                        <li>pages visited</li>
                        <li>frequency of use</li>
                        <li>time spent on pages</li>
                        <li>search terms</li>
                        <li>links clicked and</li>
                        <li>other statistics</li>
                    </ul>

                    <p>
                        We use this information to administer the Service and we analyse (and may engage third parties
                        to analyse) this information to improve and enhance the Service by expanding its features and
                        functionality and tailoring it to our user's needs and preferences. <br />
                    </p>
                    <p>
                        We may use cookies or similar technologies to analyse trends, administer the website, track user
                        movements around the website, and to gather demographic information about our user base. You can
                        control the use of cookies at the individual browser level.
                        <br />
                    </p>
                    <p>
                        For more information, please see the section entitled "Cookies Policy" below.
                        <br />
                    </p>
                    <p>
                        We also use Google Analytics to help us offer you optimized user experience. You can find more
                        information about Google Analytics use of Your personal data here at&nbsp;
                        <a href="https://policies.google.com/privacy?hl=en" target="_blank">
                            Google Analytics Privacy Policy
                        </a>
                    </p>
                </div>

                <h4>USE OF PERSONAL INFORMATION</h4>
                <div>
                    <h5>To provide our service</h5>
                    <p>We will use your personal information in the following ways:</p>
                    <ul>
                        <li>To enable you to access and use the Services;</li>
                        <li>To provide and deliver products and services that you may request;</li>
                        <li>To identify you as the owner, creator or authorised licensee of content; and</li>
                        <li>
                            To send information, including confirmations, technical notices, updates, security alerts,
                            and support and administrative messages.{' '}
                        </li>
                    </ul>

                    <h5>To comply with relevant laws</h5>
                    <p>
                        We use your personal information as we believe necessary or appropriate to comply with
                        applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests
                        from government authorities.
                    </p>
                    <h5>To communicate with you</h5>
                    <p>
                        We use Your personal information to communicate about promotions, upcoming events, and other
                        news about products and services offered by us and our selected partners.
                    </p>

                    <h5>To optimize our platform</h5>
                    <p>
                        In order to optimize your user experience, we may use your personal information to operate,
                        maintain, and improve our Services. We may also use your information to respond to your comments
                        and questions regarding the Services and to provide you and other users with general customer
                        service.
                    </p>
                    <h5>With your consent</h5>
                    <p>
                        We may use or share your personal information with your consent, such as when you instruct us to
                        take a specific action with respect to your personal information, or you opt into any Third
                        Party marketing communications.
                    </p>

                    <h5>For compliance, fraud prevention, and safety</h5>
                    <p>
                        We may use your personal information to protect, investigate, and deter against fraudulent,
                        unauthorized, or illegal activity.
                    </p>
                </div>

                <h4>SHARING OF PERSONAL INFORMATION</h4>
                <div>
                    <p>
                        We do not share or sell the personal information that you provide us with other organizations
                        without your express consent, except as described in this Privacy Policy.
                    </p>
                    <p> We may disclose personal information to third parties under the following circumstances: </p>
                    <ul>
                        <li>
                            to our subsidiaries and corporate affiliates for purposes consistent with this Privacy
                            Policy.{' '}
                        </li>
                        <ul></ul>
                        <li>
                            when we do a business deal, or negotiate a business deal, involving the sale or transfer of
                            all or a part of Our business or assets. These deals can include any merger, financing or
                            acquisition.{' '}
                        </li>
                        <ul></ul>
                        <li>for legal, protection, and safety purposes.</li>
                        <ul></ul>
                        <li>to comply with laws and to respond to lawful requests and legal processes.</li>
                        <ul></ul>
                        <li>
                            to protect our rights and property, our agents, customers, and others. This includes
                            enforcing Our agreements, policies, and{' '}
                            <a href="https://picsfromspace.com/terms" target="_blank">
                                Terms of Service
                            </a>
                            .{' '}
                        </li>
                        <ul></ul>
                        <li>
                            in an emergency including protecting the safety of Our employees and agents, our customers,
                            or any person.{' '}
                        </li>
                        <ul></ul>
                        <li>
                            with those who need it to do work for Us. These recipients may include third party companies
                            and individuals to administer and provide the Service on our behalf (such as customer
                            support, hosting, email delivery and database management services), as well as lawyers,
                            bankers, auditors, and insurers.{' '}
                        </li>
                        <ul></ul>
                        <li>
                            Other. You may permit us to share your personal information with other companies or entities
                            of your choosing. Those uses will be subject to the privacy policies of the recipient entity
                            or entities.
                        </li>
                    </ul>
                </div>

                <h4>INTERNATIONAL TRANSFER</h4>
                <div>
                    <p>
                        We have offices outside of the EU and has affiliates and service providers in the United States
                        and in other countries. Your personal information may be transferred to or from other locations
                        outside of your state, province, country or other governmental jurisdiction where privacy laws
                        may not be as protective as those in your jurisdiction. <br />
                    </p>
                    <p>
                        EU users should read the important information provided below about transfer of personal
                        information outside of the European Economic Area (EEA).
                    </p>
                </div>

                <h4>HOW INFORMATION IS SECURED</h4>
                <div>
                    <p>We retain information we collect:</p>
                    <ul>
                        <li>If it is necessary and relevant to fulfil the purposes outlined in this Privacy Policy;</li>
                        <li>To comply with applicable law where required;</li>
                        <li>Prevent fraud;</li>
                        <li>Resolve disputes;</li>
                        <li>Troubleshoot problems;</li>
                        <li>Assist with any investigation;</li>
                        <li>
                            Enforce our <a href="https://picsfromspace.com/terms">Terms of Service</a>; and
                        </li>
                        <li>Other actions permitted by law.</li>
                    </ul>

                    <p>
                        To determine the appropriate retention period for personal information, We consider the amount,
                        nature, and sensitivity of the personal information, the potential risk of harm from
                        unauthorized use or disclosure of your personal information, the purposes for which we process
                        your personal information and whether we can achieve those purposes through other means, and the
                        applicable legal requirements. <br />
                    </p>
                    <p>
                        In some circumstances, we may anonymize your personal information (so that it can no longer be
                        associated with you) in which case we may use this information indefinitely without further
                        notice to you. <br />
                    </p>
                    <p>
                        We employ industry standard security measures designed to protect the security of all
                        information submitted through the Services. However, the security of information transmitted
                        through the internet can never be guaranteed. We are not responsible for any interception or
                        interruption of any communications through the internet or for changes to or losses of data. You
                        are responsible for maintaining the security of any:
                    </p>
                    <ul>
                        <li>User ID</li>
                        <li>Password</li>
                        <li>Multi-factor authentication devices</li>
                        <li>Biometrics or</li>
                        <li>
                            Other forms of authentication involved in obtaining access to secure areas of the
                            Services.&nbsp;
                        </li>
                    </ul>
                    <p>
                        To protect you and your data, we may suspend your use of any of the Services, without notice,
                        pending an investigation, if any breach of security is suspected.
                    </p>
                </div>
                <h4>INFORMATION CHOICES AND CHANGES</h4>
                <div>
                    <h5>Accessing, updating, correcting, and deleting your information </h5>
                    <p>
                        You may access information that you have voluntarily provided through Your Account on the Site,
                        and to review, correct, or delete it. If you are unable to access or change this information You
                        may do so by sending a request to{' '}
                        <a href="mailto:skysponsor@soar.earth?subject=Privacy%20information">skysponsor@soar.earth</a>{' '}
                        and specifying what you require in the body of the email. <br />
                    </p>
                    <p>
                        You can request to change contact choices, opt-out of our sharing with others, and update your
                        personal information and preferences.
                    </p>

                    <h5>Tracking technologies generally </h5>
                    <p>
                        Regular cookies may generally be disabled or removed by tools available as part of most
                        commercial browsers, and in some instances blocked in the future by selecting certain settings.
                        For more information, please see the section entitled "Cookies Policy" below.
                    </p>
                    <h5>Google Analytics </h5>
                    <p>
                        You may exercise choices regarding the use of cookies from Google Analytics by downloading and
                        installing the{' '}
                        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                            Google Analytics Opt-out browser addon;
                        </a>
                    </p>
                </div>

                <h4>CONTACT INFORMATION</h4>
                <div>
                    <p>
                        We welcome your comments or questions about this Policy, and you may contact us at{' '}
                        <a href="mailto:skysponsor@soar.earth?subject=Privacy%20policy%20questions">
                            skysponsor@soar.earth
                        </a>{' '}
                        <br />
                    </p>
                </div>

                <h4>CHANGES TO THIS PRIVACY POLICY</h4>
                <div>
                    <p>
                        If We make any changes, We will change the Effective Date date above. Any modifications to this
                        Privacy Policy will be effective upon our posting of the new Terms and/or upon implementation of
                        the changes to the Site (or as otherwise indicated at the time of posting). <br />
                    </p>
                    <p>
                        In all cases, your continued use of the Site or Services after the posting of any modified
                        Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.
                    </p>
                </div>

                <h4>ELIGIBILITY</h4>
                <div>
                    <p>
                        If you are under the age of majority in your jurisdiction of residence, you may use the Services
                        only with the consent of or under the supervision of your parent or legal guardian. Consistent
                        with the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that
                        we have received any information directly from a child under age 13 without first receiving his
                        or her parent's verified consent, we will use that information only to respond directly to that
                        child (or his or her parent or legal guardian) to inform the child that he or she cannot use the
                        Site and subsequently we will delete that information.
                    </p>
                </div>

                <h4>NOTICE TO EU DATA SUBJECTS</h4>
                <div>
                    <h5>Personal information</h5>
                    <p>
                        With respect to EU data subjects, "personal information" as used in this Privacy Policy, is
                        equivalent to "personal data" as defined in the &nbsp;
                        <a href="https://gdpr-info.eu/art-4-gdpr/" target="_blank">
                            European Union General Data Protection Regulation
                        </a>
                        &nbsp; (GDPR).
                    </p>
                    <h5>Sensitive data</h5>
                    <p>
                        Some of the information you provide us may constitute sensitive data as defined in the GDPR
                        (also referred to as special categories of personal data), including identification of your race
                        or ethnicity on government-issued identification documents
                    </p>

                    <h5>Use for new purposes</h5>
                    <ul>
                        <li>
                            We may use your personal information for reasons not described in this Privacy Policy, where
                            we are permitted by law to do so and where the reason is compatible with the purpose for
                            which we collected it.
                        </li>
                        <li>
                            If we need to use your personal information for an unrelated purpose, we will notify you and
                            explain the applicable legal basis for that use.
                        </li>

                        <li>
                            If we have relied upon your consent for a particular use of your personal information, we
                            will seek your consent for any unrelated purpose.
                        </li>
                    </ul>

                    <h5>Your rights </h5>
                    <p>
                        Under the GDPR, you have certain rights regarding your personal information. You may ask us to
                        take the following actions in relation to your personal information that we hold to:
                        <ul>
                            <li>
                                Opt-out of direct marketing communications which you have previously consented to
                                receive. We may continue to send you Service-related and other non-marketing
                                communications;&nbsp;
                            </li>
                            <li>
                                To provide you with information about our processing of your personal information and
                                give you access to your personal information&nbsp;
                            </li>
                            <li>Update or correct inaccuracies in your personal information;</li>
                            <li>Delete your personal information;</li>
                            <li>
                                Transfer a machine-readable copy of your personal information to you or a third party of
                                your choice;&nbsp;
                            </li>
                            <li>Restrict the processing of your personal information;</li>
                            <li>
                                Object to our reliance on our legitimate interests as the basis of our processing of
                                your personal information that impacts your rights.&nbsp;
                            </li>
                        </ul>
                    </p>
                    <p>
                        You can submit these requests by email to&nbsp;
                        <a href="mailto:skysponsor@soar.earth?subject=Privacy%20request">skysponsor@soar.earth</a>&nbsp;
                        and describe your requirements in the body of the text. <br />
                    </p>
                    <p>
                        We may request specific information from you to help us confirm your identity and process your
                        request. Applicable law may require or permit us to decline your request. If we decline your
                        request, we will tell you why, subject to legal restrictions. <br />
                    </p>
                    <p>
                        If you would like to submit a complaint about our use of your personal information or response
                        to your requests regarding your personal information, you may contact us at&nbsp;
                        <a href="mailto:skysponsor@soar.earth?subject=Privacy%20complaint">skysponsor@soar.earth</a>
                        &nbsp; or submit a complaint to the data protection regulator in your jurisdiction.
                    </p>
                    <h5>Cross-border data transfer </h5>
                    <p>
                        Please be aware that your personal data may be transferred to, processed, and stored in a
                        different jurisdiction to that in which you reside or access the service. Data protection laws
                        where your data is stored may be different from those in your country of residence. <br />
                        <br />
                        You consent to the transfer of your information, including personal information, as set forth in
                        this Privacy Policy by visiting the Site or using the Service.
                    </p>
                </div>
                <h4>COOKIES POLICY</h4>
                <div>
                    <p>
                        We understand that your privacy is important to you and are committed to being transparent about
                        the technologies we use. In the spirit of transparency, this policy provides detailed
                        information about how and when we use cookies on the Site.
                    </p>
                    <p>
                        <b>Do we use cookies?</b>
                        <br />
                        Yes, native and third-party cookies may be present on the Site and through the use of the
                        Services.
                    </p>
                    <p>
                        <b> What is a cookie?</b>
                        <br />A cookie ("Cookie") is a small text file that is placed on your hard drive by a web page
                        server. Cookies contain information that can later be read by a web server in the domain that
                        issued the cookie to you. Some of the cookies will only be used if you use certain features or
                        select certain preferences, and some cookies will always be used. You can find out more about
                        each cookie by viewing our current cookie list below. We update this list periodically, so there
                        may be additional cookies that are not yet listed. Web beacons, tags and scripts may be used in
                        the Site or in emails to help us to deliver cookies, count visits, understand usage and campaign
                        effectiveness and determine whether an email has been opened and acted upon. We may receive
                        reports based on the use of these technologies by our service / analytics providers on an
                        individual and aggregated basis.
                    </p>
                    <p>
                        <b>How to disable cookies</b>
                        <br />
                        You can generally activate or later deactivate the use of cookies through functionality built
                        into your web browser. Please note that disabling cookies may impact the performance of the
                        site.
                    </p>
                    <p>
                        If you want to learn more about cookies, or how to control, disable or delete them, please
                        visit&nbsp;
                        <a href="http://www.aboutcookies.org" target="_blank">
                            http://www.aboutcookies.org
                        </a>
                        &nbsp; for detailed guidance. In addition, certain third-party advertising networks, including
                        Google, permit users to opt out of or customize preferences associated with your internet
                        browsing. To learn more about this feature from Google, click&nbsp;
                        <a href="https://adssettings.google.com/u/0/authenticated?hl=en" target="_blank">
                            here
                        </a>
                        &nbsp;.
                    </p>
                    <p>Please note that disabling cookies may impact the performance of the site.</p>
                </div>
                <br />
            </PrivacyAgreementBody>
            <ScrollToTopButton />
        </PrivacyAgreementContainer>
    );
};

export default PrivacyAgreement;

const PrivacyAgreementContainer = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

const PrivacyAgreementBody = styled.div`
    margin: 20px;

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
    }
    h4 {
        text-align: left;
        font-size: ${(props) => props.theme.fontSize.large};
        font-weight: 600;
        color: ${(props) => props.theme.primary.text};
        margin-top: 30px;
    }
    h5 {
        text-align: left;
        font-size: 1.1rem;
        font-weight: 600;
        color: ${(props) => props.theme.primary.text};
    }

    div {
        margin-left: 20px;
    }

    ul {
        list-style: disc;
        padding-inline-start: 0px;

        a {
            color: ${(props) => props.theme.primary.text};
            font-weight: 600;
            text-decoration: underline;
        }
    }

    li {
        color: ${(props) => props.theme.primary.text};

        a {
            color: ${(props) => props.theme.primary.text};
            font-weight: 600;
            text-decoration: underline;
        }
    }
`;
