import { AppState } from '../../root-reducer';

export const selectIsSatelliteBoxSelectActive = (state: AppState) =>
    state.satelliteArchiveDomain.isSatelliteBoxSelectActive;

export const selectSatelliteAOI = (state: AppState) => state.satelliteArchiveDomain.satelliteAOI;

export const selectSatelliteSelectedFeature = (state: AppState) =>
    state.satelliteArchiveDomain.satelliteSelectedFeature;

export const selectSatelliteHighlightedFeature = (state: AppState) =>
    state.satelliteArchiveDomain.satelliteHighlightedFeature;

export const selectSatelliteZIndex = (state: AppState) => state.satelliteArchiveDomain.satelliteZIndex;

export const selectSatelliteAOIError = (state: AppState) => state.satelliteArchiveDomain.satelliteAOIError;
