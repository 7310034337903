export const actionTypes = {
    MAP_LOADED: 'MAP/MAP_LOADED',
    MAP_UNLOADED: 'MAP/MAP_UNLOADED',

    SET_MAP_BOUNDS: 'MAP/SET_MAP_BOUNDS',
    SET_MAP_ZOOM: 'MAP/SET_MAP_ZOOM',

    FLY_TO: 'MAP/FLY_TO',
    FLY_TO_POSITION: 'MAP/FLY_TO_POSITION',
    FLY_TO_POSITION_INVALIDATE: 'MAP/FLY_TO_INVALIDATE',

    SET_BASEMAP: 'MAP/SET_BASEMAP',
};
