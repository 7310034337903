import Checkout from './checkout';
import styled from 'styled-components';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Constants from '../../constants';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import UserHelper from 'lib/user-helper';

const CheckoutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const onOrderComplete = () => {
        navigate('/order-complete');
    };

    if (!UserHelper.validateToken()) {
        return <Navigate replace to="/satellite" />;
    }

    return (
        <Container>
            <ContentHeader>
                <h3>Checkout</h3>
            </ContentHeader>
            <ContentBody>
                <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                    <Elements>
                        <Checkout data-sentry-block onOrderComplete={onOrderComplete} />
                    </Elements>
                </StripeProvider>
            </ContentBody>
        </Container>
    );
};

export default CheckoutPage;

const Container = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

const ContentHeader = styled.div`
    position: fixed;
    justify-content: space-between;
    top: 60px;
    height: 60px;
    width: 100vw;
    display: flex;
    background-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.primary.color};
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 10px;
    z-index: 1;

    h3 {
        color: ${(props) => props.theme.primary.color};
        justify-content: center;
        align-self: center;
        display: flex;
        font-size: ${(props) => props.theme.fontSize.large};
        font-weight: 600;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        height: 55px;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        padding-top: 0px;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }
`;

const ContentBody = styled.div`
    padding: 120px 10px 10px 10px;
    display: flex;
    justify-content: center;
`;
