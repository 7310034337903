import React, { useState } from 'react';
import Analytics from '../../lib/user-analytics';
import SatelliteDrawer from '../Drawer/Satellite/satellite-drawer';
import NavigationBar from '../NavigationBar/navbar';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import MapView from './map-view';

interface MapContainerProps {
    loginMode?: LoginModalMode;
}

const MapContainer = (props: MapContainerProps) => {
    const [loginMode, setLoginMode] = useState<LoginModalMode | undefined>(props.loginMode);

    const onLoginDialogClosed = () => {
        setLoginMode(undefined);
        Analytics.Event('Login popup', 'Closed login dialog');
    };

    return (
        <React.Fragment>
            <NavigationBar />
            <SatelliteDrawer />
            <MapView />
            {loginMode && <LoginRegisterDialog isOpen={true} initialMode={loginMode} onClose={onLoginDialogClosed} />}
        </React.Fragment>
    );
};

export default MapContainer;
