import GeoUtil from '../lib/geo-util';
import Api from './api';
import { OrderDetailsDTO, OrderDTO, OrderForPayment, ResultDTO } from './model';

export default class ApiOrder extends Api {
    public static getOrders(): Promise<OrderDTO[]> {
        return this.axios.get('/v1/orders').then((res) => res.data.list);
    }

    public static getOrderDetails(id: number): Promise<OrderDetailsDTO> {
        return this.axios
            .get('/v1/orders/' + id, { cache: true })
            .then((res) => res.data)
            .then((data) => {
                data.boundingBox = GeoUtil.latLngBoundsFromPolygonWKT(data.geometryWKT);
                return data;
            });
    }

    public static getOrdersForPayment(): Promise<OrderForPayment[]> {
        return this.axios
            .get('/v1/orders/payment')
            .then((res) => res.data.list as OrderForPayment[])
            .then((data) => {
                return data.map((p) => {
                    p.preview.totalPrice = p.preview.totalPrice / 100;
                    p.preview.voucherValue = (p.preview.voucherValue ?? 0) / 100;
                    p.preview.voucherValueUsed = (p.preview.voucherValueUsed ?? 0) / 100;
                    p.preview.totalTax = (p.preview.totalTax ?? 0) / 100;
                    return p;
                });
            });
    }

    public static payOrders(
        ids: number[],
        totalPrice: number,
        currency: string,
        stripeToken: string
    ): Promise<ResultDTO> {
        const params = {
            ids,
            total: totalPrice * 100,
            currency,
            stripeToken,
        };
        return this.axios.post(`/v1/orders/payment`, params).then((res) => {
            return res.data;
        });
    }

    public static getOrderDownloadLink(orderId: number, fileId: string): Promise<string> {
        return this.axios.get(`/v1/orders/${orderId}/files/${fileId}/download`, { cache: false }).then((res) => {
            return res.data.presignedUrl;
        });
    }
}
