import styled from 'styled-components';
import { UserDTO } from '../../../api/model';

interface ProfileProps {
    myProfile?: UserDTO;
}

const MenuUserDetails = (props: ProfileProps) => {
    const { myProfile } = props;

    return (
        <ProfileUserDetails>
            <ProfileUserName>{myProfile?.firstName ? `Hello, ${myProfile.firstName}` : ``}</ProfileUserName>
            <p>This is your Dashboard</p>
        </ProfileUserDetails>
    );
};

export default MenuUserDetails;

const ProfileUserDetails = styled.div`
    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {
        color: ${(props) => props.theme.primary.text};
        text-align: center;
    }
`;

const ProfileUserName = styled.h1`
    display: block;
    color: inherit;
    padding-left: 10px;
    padding-top: 10px;
`;
