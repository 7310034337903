import React from 'react';
import { useSelector } from 'react-redux';
import { selectSatelliteHighlightedFeature } from '../../store/Map/SatelliteArchive/selectors';
import { Polygon } from 'react-leaflet';
import GeoUtil from '../../lib/geo-util';


const SatelliteHighlightedAOI = () => {

    const highlightedFeature = useSelector(selectSatelliteHighlightedFeature);

    if (!highlightedFeature) return <React.Fragment />

    return (
        <Polygon
            positions={GeoUtil.polygonFromPolygonWKT(highlightedFeature.geometryWKT)}
            weight={1}
            interactive={false}
            key="overlayFilledPolygon"
        />
    )
};

export default SatelliteHighlightedAOI;