import styled from 'styled-components';
import Modal, { StyledModalBody } from '../../Shared/Modal/modal';
import { StyledButton } from '../../Shared/Buttons/styled-button';

interface SuccessResetPasswordModalProps {
    toggle: () => void;
    email: string | undefined;
    openSuccessModal: boolean;
    onClose: () => void;
}

const SuccessResetPasswordModal = (props: SuccessResetPasswordModalProps) => {
    const { toggle, email, openSuccessModal } = props;

    return (
        <Modal
            title={<span className="small">Reset Password for {email}</span>}
            isOpen={openSuccessModal}
            toggle={toggle}
        >
            <ModalContainerBody>
                <h3>Success! Your password has been updated!</h3>

                <ButtonContainer>
                    <StyledButton onClick={toggle}>CLOSE</StyledButton>
                </ButtonContainer>
            </ModalContainerBody>
        </Modal>
    );
};

export default SuccessResetPasswordModal;

const ModalContainerBody = styled(StyledModalBody)`
    h3 {
        color: ${(props) => props.theme.primary.text};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
