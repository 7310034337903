import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { SatelliteArchiveOrderDTO, OrderType } from '../../api/api-supplier';

interface ShoppingCartStore {
    satelliteArchiveOrders: SatelliteArchiveOrderDTO[];
    openDrawerForOrder: OrderType | undefined;
}

const initialState: ShoppingCartStore = {
    satelliteArchiveOrders: [],
    openDrawerForOrder: undefined,
};

export default (state: ShoppingCartStore = initialState, action: Action): ShoppingCartStore => {
    switch (action.type) {
        case at.SHOPPING_CART_ADD_SATELLITE_ARCHIVE: {
            const order = action.payload as SatelliteArchiveOrderDTO;
            return {
                ...state,
                satelliteArchiveOrders: [...state.satelliteArchiveOrders, order],
            };
        }
        case at.SHOPPING_CART_REMOVE_SATELLITE_ARCHIVE: {
            const order = action.payload as SatelliteArchiveOrderDTO;
            return {
                ...state,
                satelliteArchiveOrders: [
                    ...state.satelliteArchiveOrders.filter(
                        (o) => !(o.id === order.id && o.totalPrice === order.totalPrice)
                    ),
                ],
            };
        }
        case at.SHOPPING_CART_CLEAR:
            return {
                ...state,
                satelliteArchiveOrders: [],
            };
        case at.SHOPPING_CART_INITIALIZE:
            return {
                ...state,
                satelliteArchiveOrders: action.payload,
            };

        case at.SHOPPING_CART_OPEN_DRAWER:
            return {
                ...state,
                openDrawerForOrder: action.payload,
            };

        default:
            return state;
    }
};
