import { OrderType } from 'api/api-supplier';
import styled from 'styled-components';

interface Props {
    orderTypes: OrderType[];
}

const ArchiveTermsList = () => {
    return (
        <ol>
            <li>
                Images are supplied ‘as is’ with colouring and cloud / smoke as shown on the low resolution image
                preview.
            </li>
            <li>Images are supplied in Natural Colour Composite (NCC) which is a 3-band RGB format.</li>
            <li>
                Images are supplied in GeoTIFF format unless otherwise agreed to in writing upfront at the time of the
                order.
            </li>
            <li>
                Images are not ortho or georectified and some locational errors may occur. These are different for each
                image and can vary from 3m to 20m.
            </li>
            <li>
                Delivery times may vary depending on the size, location, and complexity of the order. The estimated
                delivery time for Archive images can take up to 2 business days.
            </li>
            <li>
                Images will not be mosaiced or stitched unless otherwise agreed to in writing upfront at the time of the
                order.
            </li>
        </ol>
    );
};

const NewCollectTermsList = () => {
    return (
        <ol>
            <li>The initiation of my order will commence up to 5 business days after order placement.</li>
            <li>
                Images are supplied with a maximum allowable cloud coverage of 15% (including cloud shadow). For
                example, if total order size is 100km2, your order will only be re-tasked if clouds cover more than
                15km2.
            </li>
            <li>
                In cases where persistent cloud cover is more than 15%, Pics from Space will re-attempt to capture the
                area at no additional cost.
            </li>
            <li>Delivery of your order will be up to 3 business days after successful collection.</li>
            <li>Images are supplied in Natural Color Composite (NCC) which is a 3-band RGB format.</li>
            <li>
                Images are supplied in GeoTIFF format unless otherwise agreed to in writing upfront at the time of the
                order.
            </li>
            <li>
                Images are not ortho or georectified and some locational errors may occur. These are different for each
                image and can vary from 3m to 20m.
            </li>
            <li>
                Images will not be mosaiced or stitched unless otherwise agreed to in writing upfront at the time of the
                order.
            </li>
            <li>
                Pics from Space, reserves the right to refuse any order and is under no obligation to disclose any
                reasons for the refusal. Should such an occasion occur a full refund will be made.
            </li>
        </ol>
    );
};

const CheckoutTermsList = ({ orderTypes }: Props) => {
    if (orderTypes.includes('ARCHIVE') && orderTypes.includes('NEWCOLLECT'))
        return (
            <TermsContainer>
                <b>Sales Conditions: (Archive)</b>
                <div>I accept that:</div>
                <ArchiveTermsList />
                <div className="mt-1" />
                <b>Sales Conditions: (New Collect)</b>
                <div>I accept that:</div>
                <NewCollectTermsList />
            </TermsContainer>
        );
    else if (orderTypes.includes('NEWCOLLECT'))
        return (
            <TermsContainer>
                <b>Sales Conditions:</b>
                <div>I accept that:</div>
                <NewCollectTermsList />
            </TermsContainer>
        );
    else
        return (
            <TermsContainer>
                <b>Sales Conditions:</b>
                <div>I accept that:</div>
                <ArchiveTermsList />
            </TermsContainer>
        );
};

export default CheckoutTermsList;

const TermsContainer = styled.div`
    ol {
        margin-left: 0px;

        ol {
            list-style: lower-alpha;
        }
    }
`;
