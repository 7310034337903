import UserHelper from 'lib/user-helper';
import { useEffect } from 'react';

const Logout = () => {
    useEffect(() => {
        UserHelper.resetToken();
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    }, []);

    return null;
};

export default Logout;
