import { LatLng, LatLngBounds } from 'leaflet';
import { history } from './history';
import { actionSetBasemap, actionSetMapBounds } from './Map/actions';
import { BasemapKey } from './Map/model';
import { actionSatelliteSelectAOI, actionSatelliteSetFeatureActive } from './Map/SatelliteArchive/actions';
import { selectSatelliteAOI, selectSatelliteSelectedFeature } from './Map/SatelliteArchive/selectors';
import { selectBasemap, selectMapBounds } from './Map/selectors';
import { AppState } from './root-reducer';
import URIEnhancer, { QueryParameter } from './uri-enhancer';
import { SatelliteArchiveImageryDTO } from '../api/api-supplier';

const mapPositionParameter: QueryParameter<LatLngBounds | undefined> = {
    selector: (state: AppState) => selectMapBounds(state),
    action: (bounds: LatLngBounds) => actionSetMapBounds(bounds),
    valueToString: (value: LatLngBounds): string => {
        return value.toBBoxString();
    },
    stringToValue: (str: string): LatLngBounds => {
        const [west, south, east, north] = str.split(',').map(parseFloat);
        const bounds = new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        return bounds;
    },
    isEqual: (a: LatLngBounds, b: LatLngBounds): boolean => {
        return a.equals(b);
    },
    useBrowserHistory: false,
};

const basemapParameter: QueryParameter<BasemapKey> = {
    selector: (state: AppState) => selectBasemap(state),
    action: (basemap: BasemapKey) => actionSetBasemap(basemap),
    valueToString: (value: BasemapKey): string => {
        return encodeURI(value);
    },
    stringToValue: (str: string): BasemapKey => {
        return decodeURI(str) as BasemapKey;
    },
    isEqual: (a: BasemapKey, b: BasemapKey) => {
        return a === b;
    },
    useBrowserHistory: true,
};

const aoiParameter: QueryParameter<LatLngBounds | undefined> = {
    selector: (state: AppState) => selectSatelliteAOI(state),
    action: (aoi: LatLngBounds) => actionSatelliteSelectAOI(aoi),
    valueToString: (value: LatLngBounds | undefined): string | undefined => {
        if (value) {
            return value.toBBoxString();
        } else return undefined;
    },
    stringToValue: (str: string): LatLngBounds | undefined => {
        const [west, south, east, north] = str.split(',').map(parseFloat);
        if (!west || !south || !east || !north) {
            return undefined;
        }
        const bounds = new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        return bounds;
    },
    isEqual: (a: LatLngBounds, b: LatLngBounds): boolean => {
        return a.equals(b);
    },
    useBrowserHistory: false,
};

const archiveResultParameter: QueryParameter<SatelliteArchiveImageryDTO | undefined> = {
    selector: (state: AppState) => selectSatelliteSelectedFeature(state),
    action: (result: SatelliteArchiveImageryDTO) => actionSatelliteSetFeatureActive(result),
    valueToString: (value: SatelliteArchiveImageryDTO | undefined): string | undefined => {
        if (value) {
            return encodeURI(JSON.stringify(value));
        } else return undefined;
    },
    stringToValue: (str: string): SatelliteArchiveImageryDTO | undefined => {
        if (str) {
            try {
                const parsed = JSON.parse(decodeURI(str));
                const archiveDTO: SatelliteArchiveImageryDTO = {
                    id: parsed.id,
                    cloud: parsed.cloud,
                    date: parsed.date,
                    description: parsed.description,
                    geometryWKT: parsed.geometryWKT,
                    previewUrl: parsed.previewUrl,
                    product: parsed.product,
                    resolutionInCm: parsed.resolutionInCm,
                    rollAngle: parsed.rollAngle,
                    supplier: parsed.supplier,
                };
                return archiveDTO;
            } catch (err) {
                console.log(err);
            }
        }
        return undefined;
    },
    isEqual: (a: SatelliteArchiveImageryDTO, b: SatelliteArchiveImageryDTO): boolean => {
        return a.id == b.id;
    },
    useBrowserHistory: true,
};

export const uriEnhancer = URIEnhancer.enhancer({
    params: {
        pos: mapPositionParameter,
        basemap: basemapParameter,
        //  aoi: aoiParameter,

        // TODO: Fix the selected archive result URI enhancer
        // archiveResult: archiveResultParameter
    },
    history: history,
});
