import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

type InputProps = React.ComponentProps<typeof Input>;

const StyledInput = (props: InputProps) => {
    return <ThemedInput {...props} />;
};

export default StyledInput;

const ThemedInput = styled(Input)`
    background-color: transparent !important;
    color: ${(props) => props.theme.secondary.color}cc !important;
    border: 1px solid ${(props) => props.theme.secondary.color}99 !important;

    ::placeholder {
        color: ${(props) => props.theme.secondary.color}cc !important;
    }

    :hover,
    :active,
    :focus {
        background-color: transparent !important;
        border: 1.5px solid ${(props) => props.theme.secondary.color}99 !important;
        box-shadow: 0 0 0 0.2rem ${(props) => props.theme.secondary.color}1f !important;
    }

    option {
        color: ${(props) => props.theme.secondary.color}cc !important;
        background-color: transparent !important;
    }
`;
