import { useEffect, useState } from 'react';
import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLeafletContext,
    useLayerLifecycle,
} from '@react-leaflet/core';
import L, { LatLng, LatLngBounds, LeafletMouseEvent, PolylineOptions } from 'leaflet';
import { MarkerProps } from 'react-leaflet';
import GeoUtil from '../../../lib/geo-util';
import './satellite-aoi-tool.css';
import turfArea from '@turf/area';
import turfDistance from '@turf/distance';
import { Point } from '@turf/helpers';

interface AOIControlProps {
    boundingBox: LatLngBounds;
    onAOIChange: (bounds: LatLngBounds) => void;
    onAOIError: (error: Error | undefined) => void;
}

export enum ErrorEnum {
    TooSmall = 'aoi-too-small',
    TooBig = 'aoi-too-big',
    TooWide = 'aoi-too-wide',
    TooHigh = 'aoi-too-high',
    TooShort = 'aoi-too-short',
    TooThin = 'aoi-too-thin',
}

const CONSTANTS = {
    maxArea: 10000,
    minArea: 10,
    minWidth: 2,
    minHeight: 2,
    maxWidth: 100,
    maxHeight: 100,
};

const crateCornerIcon = (color: 'normal' | 'red' = 'normal') => {
    return new L.DivIcon({
        className: `annotation-tool-corner-marker ${color}`,
        iconAnchor: new L.Point(6, 6),
    });
};

const squareOptions: PolylineOptions = {
    smoothFactor: 1.0,
    noClip: false,
    stroke: true,
    color: '#6E322B',
    weight: 3,
    opacity: 1.0,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: 'transparent',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};

const createCornerDragHandle = (position: LatLng, context: LeafletContextInterface) => {
    const handle = new L.DivIcon({
        className: `annotation-tool-corner-handle`,
        iconAnchor: new L.Point(8, 8),
    });
    const element = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { draggable: true, icon: handle }),
        context
    );
    return element;
};

const createCornerMarker = (position: LatLng, context: LeafletContextInterface) => {
    const icon = crateCornerIcon();
    const element = createElementObject<L.Marker, MarkerProps>(new L.Marker(position, { icon: icon }), context);
    return element;
};

const createAreaMarker = (position: LatLng, context: LeafletContextInterface) => {
    return createElementObject<L.Marker, MarkerProps>(new L.Marker(position, { icon: new L.DivIcon({}) }), context);
};

const createDistanceMarker = (position: LatLng, context: LeafletContextInterface) => {
    const element = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { icon: new L.DivIcon({}) }),
        context
    );
    return element;
};

const createControl = (props: AOIControlProps, context: LeafletContextInterface) => {
    const squareElement = createElementObject<L.Rectangle, AOIControlProps>(
        new L.Rectangle(props.boundingBox, squareOptions),
        context
    );

    //marker just for display and icon to change if error in aoi
    const northEastCornerMarker = createCornerMarker(props.boundingBox.getNorthEast(), context);
    const northWestCornerMarker = createCornerMarker(props.boundingBox.getNorthWest(), context);
    const southWestCornerMarker = createCornerMarker(props.boundingBox.getSouthWest(), context);
    const southEastCornerMarker = createCornerMarker(props.boundingBox.getSouthEast(), context);

    //actual marker being dragged
    const northEastCornerHandle = createCornerDragHandle(props.boundingBox.getNorthEast(), context);
    const northWestCornerHandle = createCornerDragHandle(props.boundingBox.getNorthWest(), context);
    const southWestCornerHandle = createCornerDragHandle(props.boundingBox.getSouthWest(), context);
    const southEastCornerHandle = createCornerDragHandle(props.boundingBox.getSouthEast(), context);

    const horizontalDistanceMarker = createDistanceMarker(props.boundingBox.getNorthEast(), context);
    const verticalDistanceMarker = createDistanceMarker(props.boundingBox.getNorthEast(), context);
    const areaMarker = createAreaMarker(props.boundingBox.getCenter(), context);

    const updateHorizontalDistanceMarker = (bounds: LatLngBounds) => {
        if (horizontalDistanceMarker.instance.options && horizontalDistanceMarker.instance.options.icon) {
            const position = new LatLng(bounds.getNorth(), (bounds.getEast() + bounds.getWest()) / 2);
            const from: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
            const to: Point = { type: 'Point', coordinates: [bounds.getWest(), bounds.getNorth()] };
            const distance = turfDistance(from, to, { units: 'kilometers' }).toFixed(2).toLocaleString();
            horizontalDistanceMarker.instance.setLatLng(position);
            horizontalDistanceMarker.instance.setIcon(
                new L.DivIcon({
                    className: 'annotation-tool-horizontal-distance-marker',
                    html: `<span>${distance}km</span>`,
                    iconAnchor: new L.Point(22, 20),
                })
            );
        }
    };

    const updateVerticalDistanceMarker = (bounds: LatLngBounds) => {
        if (verticalDistanceMarker.instance.options && verticalDistanceMarker.instance.options.icon) {
            const position = new LatLng((bounds.getNorth() + bounds.getSouth()) / 2, bounds.getEast());
            const from: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
            const to: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getSouth()] };
            const distance = turfDistance(from, to, { units: 'kilometers' }).toFixed(2).toLocaleString();
            verticalDistanceMarker.instance.setLatLng(position);
            verticalDistanceMarker.instance.setIcon(
                new L.DivIcon({
                    className: 'annotation-tool-vertical-distance-marker',
                    html: `<span>${distance}km</span>`,
                    iconAnchor: new L.Point(14, 20),
                })
            );
        }
    };

    const updateAreaMarker = (bounds: LatLngBounds) => {
        if (areaMarker.instance.options && areaMarker.instance.options.icon) {
            const position = bounds.getCenter();
            const geoJson = new L.Polygon(GeoUtil.polygonForBounds(bounds)).toGeoJSON();
            const area = turfArea(geoJson);
            const areaKm2 = (area / 1000 / 1000).toFixed(2).toLocaleString();

            areaMarker.instance.setLatLng(position);
            areaMarker.instance.setIcon(
                new L.DivIcon({
                    iconSize: new L.Point(270, 100),
                    className: 'annotation-tool-area-marker',
                    html: `<span>Order Window (Area: ${areaKm2}km²)</span>`,
                    iconAnchor: new L.Point(130, 20),
                })
            );
        }
    };

    const getAOIError = (bounds: LatLngBounds): Error | undefined => {
        const geoJson = new L.Polygon(GeoUtil.polygonForBounds(bounds)).toGeoJSON();
        const area = turfArea(geoJson);
        const areaKm2 = area / 1000 / 1000;

        const ne: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
        const se: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getSouth()] };
        const nw: Point = { type: 'Point', coordinates: [bounds.getWest(), bounds.getNorth()] };
        const height = turfDistance(ne, se, { units: 'kilometers' });
        const width = turfDistance(nw, ne, { units: 'kilometers' });

        if (areaKm2 < CONSTANTS.minArea) {
            return new Error(`Area [${areaKm2.toFixed(2)} km²] can not be smaller than ${CONSTANTS.minArea} km²`, {
                cause: ErrorEnum.TooSmall.toString(),
            });
        } else if (areaKm2 > CONSTANTS.maxArea) {
            return new Error(`Area [${areaKm2.toFixed(2)} km²] can not be bigger than ${CONSTANTS.maxArea} km²`, {
                cause: ErrorEnum.TooBig.toString(),
            });
        } else if (width > CONSTANTS.maxWidth) {
            return new Error(`Width [${width.toFixed(2)} km] can not be greater than ${CONSTANTS.maxWidth} km`, {
                cause: ErrorEnum.TooWide.toString(),
            });
        } else if (width < CONSTANTS.minWidth) {
            return new Error(`Width [${width.toFixed(2)} km] can not be less than ${CONSTANTS.minWidth} km`, {
                cause: ErrorEnum.TooThin.toString(),
            });
        } else if (height > CONSTANTS.maxHeight) {
            return new Error(`Height [${height.toFixed(2)} km] can not be greater than ${CONSTANTS.maxHeight} km`, {
                cause: ErrorEnum.TooHigh.toString(),
            });
        } else if (height < CONSTANTS.minHeight) {
            return new Error(`Height [${height.toFixed(2)} km] can not be less than ${CONSTANTS.minHeight} km`, {
                cause: ErrorEnum.TooShort.toString(),
            });
        } else return undefined;
    };

    let prevAOIError: Error | undefined = undefined;
    const validateAOI = (bounds: LatLngBounds) => {
        const aoiError = getAOIError(bounds);
        if (prevAOIError !== aoiError && aoiError !== undefined) {
            squareElement.instance.setStyle({ color: 'red' });
            props.onAOIError(aoiError);
            northEastCornerMarker.instance.setIcon(crateCornerIcon('red'));
            northWestCornerMarker.instance.setIcon(crateCornerIcon('red'));
            southWestCornerMarker.instance.setIcon(crateCornerIcon('red'));
            southEastCornerMarker.instance.setIcon(crateCornerIcon('red'));
        } else if (prevAOIError !== aoiError && aoiError === undefined) {
            squareElement.instance.setStyle({ color: '#6E322B' });
            props.onAOIError(aoiError);
            northEastCornerMarker.instance.setIcon(crateCornerIcon());
            northWestCornerMarker.instance.setIcon(crateCornerIcon());
            southWestCornerMarker.instance.setIcon(crateCornerIcon());
            southEastCornerMarker.instance.setIcon(crateCornerIcon());
        }
        prevAOIError = aoiError;
    };

    const updateMarkersPosition = (newBounds: LatLngBounds) => {
        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());

        northEastCornerHandle.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerHandle.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerHandle.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerHandle.instance.setLatLng(newBounds.getSouthEast());

        squareElement.instance.setBounds(newBounds);
        updateHorizontalDistanceMarker(newBounds);
        updateVerticalDistanceMarker(newBounds);
        updateAreaMarker(newBounds);

        validateAOI(newBounds);
    };

    const onDragNorthEastHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getSouthWest());
        updateMarkersPosition(newBounds);
    };

    const onDragNorthWestHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getSouthEast());
        updateMarkersPosition(newBounds);
    };

    const onDragSouthWestHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getNorthEast());
        updateMarkersPosition(newBounds);
    };

    const onDragSouthEastHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getNorthWest());
        updateMarkersPosition(newBounds);
    };

    const onDrag = (e: LeafletMouseEvent) => {
        if (squareElement.instance) {
            const b = squareElement.instance.getBounds();
            const latOffset = e.latlng.lat - b.getCenter().lat;
            const lngOffset = e.latlng.lng - b.getCenter().lng;
            const newBounds = new LatLngBounds(
                new LatLng(b.getNorth() + latOffset, b.getEast() + lngOffset),
                new LatLng(b.getSouth() + latOffset, b.getWest() + lngOffset)
            );
            updateMarkersPosition(newBounds);
        }
    };

    northEastCornerHandle.instance.on('drag', onDragNorthEastHandle);
    northWestCornerHandle.instance.on('drag', onDragNorthWestHandle);
    southEastCornerHandle.instance.on('drag', onDragSouthEastHandle);
    southWestCornerHandle.instance.on('drag', onDragSouthWestHandle);

    northEastCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));
    northWestCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));
    southEastCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));
    southWestCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));

    squareElement.instance.on('add', (_) => {
        const bounds = squareElement.instance.getBounds();
        updateMarkersPosition(bounds);

        context.map.addLayer(northWestCornerMarker.instance);
        context.map.addLayer(southWestCornerMarker.instance);
        context.map.addLayer(northEastCornerMarker.instance);
        context.map.addLayer(southEastCornerMarker.instance);

        context.map.addLayer(northWestCornerHandle.instance);
        context.map.addLayer(southWestCornerHandle.instance);
        context.map.addLayer(northEastCornerHandle.instance);
        context.map.addLayer(southEastCornerHandle.instance);
        context.map.addLayer(horizontalDistanceMarker.instance);
        context.map.addLayer(verticalDistanceMarker.instance);
        context.map.addLayer(areaMarker.instance);
    });

    squareElement.instance.on('remove', (_) => {
        context.map.removeLayer(northWestCornerMarker.instance);
        context.map.removeLayer(southWestCornerMarker.instance);
        context.map.removeLayer(northEastCornerMarker.instance);
        context.map.removeLayer(southEastCornerMarker.instance);

        context.map.removeLayer(northWestCornerHandle.instance);
        context.map.removeLayer(southWestCornerHandle.instance);
        context.map.removeLayer(northEastCornerHandle.instance);
        context.map.removeLayer(southEastCornerHandle.instance);
        context.map.removeLayer(horizontalDistanceMarker.instance);
        context.map.removeLayer(verticalDistanceMarker.instance);
        context.map.removeLayer(areaMarker.instance);
    });

    let isDraggingEnabled = true;
    const shouldEnableDrag = (shouldDrag: boolean) => {
        if (shouldDrag) {
            //should drag only if mapZoom is less than max zoom for aoi
            const mapZoom = context.map.getZoom();
            const maxSquareZoom = context.map.getBoundsZoom(squareElement.instance.getBounds());

            if (mapZoom < maxSquareZoom) {
                context.map.dragging.disable();
                context.map.on('mousemove', onDrag);
                isDraggingEnabled = true;
            }
        }
        //disable drag only if it was enabled
        else if (!shouldDrag && isDraggingEnabled) {
            context.map.dragging.enable();
            context.map.off('mousemove', onDrag);
            props.onAOIChange(squareElement.instance.getBounds());
            isDraggingEnabled = false;
        }
    };

    squareElement.instance.on('mousedown', (_) => {
        shouldEnableDrag(true);
    });

    squareElement.instance.on('mouseup', (_) => {
        shouldEnableDrag(false);
    });

    areaMarker.instance.on('mouseup', (_) => {
        shouldEnableDrag(false);
    });
    return squareElement;
};

const useAOIControl = createElementHook<L.Rectangle, AOIControlProps, LeafletContextInterface>(
    createControl
    //no need for component update
);

//for initial bounding box
const createBoundsForViewport = (context: LeafletContextInterface): LatLngBounds => {
    const bounds = context.map.getBounds();
    const center = bounds.getCenter();
    const north = (center.lat + bounds.getNorth()) / 2;
    const south = (center.lat + bounds.getSouth()) / 2;
    const distanceNorthSouth = GeoUtil.distance(new LatLng(north, center.lng), new LatLng(south, center.lng));
    const east = GeoUtil.offsetLongitudeByDistance(center, distanceNorthSouth * 0.5).lng;
    const west = GeoUtil.offsetLongitudeByDistance(center, distanceNorthSouth * -0.5).lng;
    const rectangleBoundingBox = new LatLngBounds(new LatLng(north, east), new LatLng(south, west));
    return rectangleBoundingBox;
};

interface SatelliteAOIControlProps {
    onAOIChange: (bounds: LatLngBounds) => void;
    onAOIError: (error: Error | undefined) => void;
}

const SatelliteAOIControl = (props: SatelliteAOIControlProps) => {
    const { onAOIChange } = props;
    const context = useLeafletContext();
    const [AOI, setAOI] = useState(createBoundsForViewport(context));

    useEffect(() => {
        onAOIChange(AOI);
    }, [AOI, onAOIChange]);

    const aoiControl = useAOIControl({ boundingBox: AOI, onAOIChange: setAOI, onAOIError: props.onAOIError }, context);

    useLayerLifecycle(aoiControl.current, context);
    return null;
};

export default SatelliteAOIControl;
