import NavigationPanel from './navigation-panel';
import TermsAndConditions, { TermsAndConditionsHeader } from '../PrivacyAgreement/terms-and-conditions';
import styled from 'styled-components';

const DashboardTermsAndCondition = () => {
    return (
        <>
            <NavigationPanel>
                <TermsAndConditionsHeader />
            </NavigationPanel>

            <Container>
                <TermsAndConditions />
            </Container>
        </>
    );
};

export default DashboardTermsAndCondition;

const Container = styled.div`
    background-color: ${(props) => props.theme.primary.color};
    padding: 10px;
    margin-top: 15px;
    border-radius: 6px;
    color: ${(props) => props.theme.primary.text};
    margin-bottom: 15px;
`;
