import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import Root from './root';

import './styles/default.css';
import './styles/leaflet.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.css';
import './components/MapView/map-view.css';
import './components/MapView/MapScale/map-scale-control.css';

import Constants from './constants';

import TagManager from 'react-gtm-module';
import { SENTRY_OPTIONS } from 'tracking-options';
import { clarity } from 'react-microsoft-clarity';

if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
    Sentry.init(SENTRY_OPTIONS);
    clarity.init(Constants.CLARITY_ID);
}

TagManager.initialize({
    gtmId: Constants.GTAG_ID,
    dataLayer: {
        userId: '001',
        userProject: 'project',
    },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);
