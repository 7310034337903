/* eslint-disable @typescript-eslint/no-explicit-any */
import { LatLngBounds, LatLngExpression } from 'leaflet';
import { LatLng } from 'leaflet';
import * as Wellknown from 'wellknown';
import turfArea from '@turf/area';

const L = require('leaflet');

export default class GeoUtil {
    // https://gist.github.com/springmeyer/871897
    static latLngToEPSG3857(latLng: LatLng): [number, number] {
        const x = (latLng.lng * 20037508.34) / 180;
        let y = Math.log(Math.tan(((90 + latLng.lat) * Math.PI) / 360)) / (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return [x, y];
    }

    static EPSG3857ToLatLng(x: number, y: number): LatLng {
        const lng = (180 * x) / 20037508.34;
        const lat = (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
        return L.latLng(lat, lng);
    }

    // TODO:  Potential source of bugs?  Do consumers use `instanceof` correctly?
    static positionFromWKT(wkt: string): LatLng | LatLngBounds | undefined {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (parsedWKT.type === 'Polygon') {
                const north = parsedWKT.coordinates[0][1][1];
                const east = parsedWKT.coordinates[0][1][0];
                const south = parsedWKT.coordinates[0][3][1];
                const west = parsedWKT.coordinates[0][3][0];

                const northEast = new LatLng(north, east);
                const southWest = new LatLng(south, west);
                return new LatLngBounds(northEast, southWest);
            } else if (parsedWKT.type === 'Point') {
                return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    // TODO: Potential source of bugs. Do consumers check for LatLng(0, 0)?
    static latLngFromWKT(wkt: any): LatLng {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLng(0, 0);
            }
            return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
        } catch (e) {
            return new LatLng(0, 0);
        }
    }

    static toLeafletPositionsClock(positions: number[][]): LatLng[] {
        return [
            new LatLng(positions[2][1], positions[2][0]),
            new LatLng(positions[3][1], positions[3][0]),
            new LatLng(positions[1][1], positions[1][0]),
            new LatLng(positions[0][1], positions[0][0]),
        ];
    }

    static toDistortablePositionsClock(positions: LatLng[]): number[][] {
        return [
            [positions[3].lng, positions[3].lat],
            [positions[2].lng, positions[2].lat],
            [positions[0].lng, positions[0].lat],
            [positions[1].lng, positions[1].lat],
            [positions[3].lng, positions[3].lat],
        ];
    }

    static polygonFromPolygonWKT(wkt: any): LatLng[] {
        const parsedWKT: any = Wellknown.parse(wkt);
        if (parsedWKT && parsedWKT.coordinates) {
            return parsedWKT.coordinates[0].map((coord) => {
                return new LatLng(coord[1], coord[0]);
            });
        }
        return [];
    }

    static polygonForBounds(bounds: LatLngBounds): LatLngExpression[] {
        const latlngs: LatLngExpression[] = [];
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
        return latlngs;
    }

    static latLngBoundsFromPolygonWKT(wkt: any): LatLngBounds {
        if (wkt === undefined) {
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }

        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
            }
            const north = parsedWKT.coordinates[0][1][1];
            const east = parsedWKT.coordinates[0][1][0];
            const south = parsedWKT.coordinates[0][3][1];
            const west = parsedWKT.coordinates[0][3][0];

            const northEast = new LatLng(north, east);
            const southWest = new LatLng(south, west);
            return new LatLngBounds(northEast, southWest);
        } catch (e) {
            console.log('Error parsing polygon WKT for bounding box');
            console.log(e);
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }
    }

    static latLngBoundsToWKT(latLngBounds: LatLngBounds): string {
        const coords = [
            latLngBounds.getNorthEast(),
            latLngBounds.getNorthWest(),
            latLngBounds.getSouthWest(),
            latLngBounds.getSouthEast(),
        ];
        const polygon = new L.polygon(coords);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static widthKilometers(latLngBounds: LatLngBounds): number {
        // const ne = latLngBounds.getNorthEast();
        // const nw = latLngBounds.getNorthWest();
        // return Geometry.distance([ne.lng, ne.lat], [nw.lng, nw.lat]);
        return 0;
    }

    static heightKilometers(latLngBounds: LatLngBounds): number {
        // const ne = latLngBounds.getNorthEast();
        // const se = latLngBounds.getSouthEast();
        // return Geometry.distance([ne.lng, ne.lat], [se.lng, se.lat]);
        return 0;
    }

    static area(latLngBounds: LatLngBounds): number {
        // const ne = [latLngBounds.getNorthEast().lat, latLngBounds.getNorthEast().lng];
        // const nw = [latLngBounds.getNorthWest().lat, latLngBounds.getNorthWest().lng];
        // const sw = [latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng];
        // const se = [latLngBounds.getSouthEast().lat, latLngBounds.getSouthEast().lng];
        // const areaSqm = Geometry.area([ne, nw, sw, se]);
        // return areaSqm;
        return 0;
    }

    static distance(from: LatLng, to: LatLng) {
        const degreesToRadians = Math.PI / 180;

        const dLat = degreesToRadians * (to.lat - from.lat);
        const dLng = degreesToRadians * (to.lng - from.lng);
        const lat1 = degreesToRadians * from.lat;
        const lat2 = degreesToRadians * to.lat;

        const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLng / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const res = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return res * 6371;
    }

    static readableDistance(from: LatLng, to: LatLng): string {
        const distance = this.distance(from, to);
        if (distance >= 1) {
            return distance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'km';
        } else {
            if (1000 * distance < 100) {
                return (distance * 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm';
            } else {
                return (distance * 1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm';
            }
        }
    }

    static offsetLatitude(latLng: LatLng, distance: number): LatLng {
        const earthRadius = 6378.137;
        const metersPerDegree = 1 / (((2 * Math.PI) / 360) * earthRadius) / 1000;
        const newLatitude = latLng.lat + distance * metersPerDegree;
        return new LatLng(newLatitude, latLng.lng);
    }

    static offsetLongitudeByDistance(latLng: LatLng, distanceMeters: number): LatLng {
        const earthRadius = 6378.137;
        const metersPerDegree = 1 / (((2 * Math.PI) / 360) * earthRadius);
        const newLongitude = latLng.lng + (distanceMeters * metersPerDegree) / Math.cos(latLng.lat * (Math.PI / 180));
        return new LatLng(latLng.lat, newLongitude);
    }

    static polygonAsLatLngExpression(bounds: LatLngBounds): LatLngExpression[] {
        const latlngs: LatLngExpression[] = [];
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
        return latlngs;
    }

    static latLngListsToWKT(latLngs: LatLng[]): string {
        const polygon = new L.polygon(latLngs);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static gpsLongitudeDMSToDecimalDegrees(gpsLongitudeDMS: any, longitudeRef: string) {
        if (gpsLongitudeDMS === undefined) {
            return undefined;
        }
        const direction = longitudeRef === 'E' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLongitudeDMS[0]) +
                parseFloat(gpsLongitudeDMS[1]) / 60 +
                parseFloat(gpsLongitudeDMS[2]) / 3600)
        );
    }

    static gpsLatitudeDMSToDecimalDegrees(gpsLatitudeDMS: any, latitudeRef: string) {
        if (gpsLatitudeDMS === undefined) {
            return undefined;
        }
        const direction = latitudeRef === 'N' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLatitudeDMS[0]) + parseFloat(gpsLatitudeDMS[1]) / 60 + parseFloat(gpsLatitudeDMS[2]) / 3600)
        );
    }

    static boundingBoxFromExifData(
        focalLengthIn35mmFilm: number,
        altitude: number,
        centerLatLng: LatLng,
        gimbalYawDegree: number,
        pixelXDimension: number,
        pixelYDimension: number
    ) {
        if (
            isNaN(focalLengthIn35mmFilm) ||
            isNaN(altitude) ||
            isNaN(gimbalYawDegree) ||
            isNaN(pixelXDimension) ||
            isNaN(pixelYDimension) ||
            altitude < 0
        )
            return undefined;

        const diagonalDistance = (altitude * 35) / focalLengthIn35mmFilm;

        const metersPerPixel =
            diagonalDistance / Math.sqrt(Math.pow(pixelXDimension, 2) + Math.pow(pixelYDimension, 2));

        const distanceHorizontal = metersPerPixel * pixelXDimension;
        const distanceVertical = metersPerPixel * pixelYDimension;

        const rotation = ((Math.PI * -gimbalYawDegree) / 180) % (2 * Math.PI);
        const corner_1 = this.rotationMatrix([-distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_2 = this.rotationMatrix([distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_3 = this.rotationMatrix([distanceHorizontal / 2, -distanceVertical / 2], rotation);
        const corner_4 = this.rotationMatrix([-distanceHorizontal / 2, -distanceVertical / 2], rotation);

        return [
            this.pairLocalToGlobal(corner_1, centerLatLng),
            this.pairLocalToGlobal(corner_2, centerLatLng),
            this.pairLocalToGlobal(corner_3, centerLatLng),
            this.pairLocalToGlobal(corner_4, centerLatLng),
            this.pairLocalToGlobal(corner_1, centerLatLng),
        ];
    }

    static pairLocalToGlobal(pair: number[], transform: LatLng) {
        const latDirection = pair[0] / Math.abs(pair[0]) + 1;
        const lngDirection = pair[1] / Math.abs(pair[1]) + 1;
        const lat = this.distanceToLatLng(pair[0], transform, latDirection ? 270 : 90)[0];
        const lng = this.distanceToLatLng(pair[1], transform, lngDirection ? 180 : 0)[1];
        return [lat, lng];
    }

    static distanceToLatLng(_distance: number, startPoint: LatLng, bearing: number) {
        const R = 6356752;
        const bearing_radians = (Math.PI * bearing) / 180;
        const start_lat = (Math.PI * startPoint.lat) / 180;
        const start_lng = (Math.PI * startPoint.lng) / 180;
        const distance = Math.abs(_distance);
        const end_lat = Math.asin(
            Math.sin(start_lat) * Math.cos(distance / R) +
                Math.cos(start_lat) * Math.sin(distance / R) * Math.cos(bearing_radians)
        );
        const end_lng =
            start_lng +
            Math.atan2(
                Math.sin(bearing_radians) * Math.sin(distance / R) * Math.cos(start_lat),
                Math.cos(distance / R) - Math.sin(start_lat) * Math.sin(end_lat)
            );

        return [(180 * end_lng) / Math.PI, (180 * end_lat) / Math.PI];
    }

    static rotationMatrix(pair: number[], theta: number) {
        return [
            pair[0] * Math.cos(theta) - pair[1] * Math.sin(theta),
            pair[0] * Math.sin(theta) + pair[1] * Math.cos(theta),
        ];
    }

    static quickArea(bounds: LatLngBounds): number {
        const width = Math.abs(bounds.getWest() + 180 - (bounds.getEast() + 180) - 180);
        const height = Math.abs(bounds.getNorth() + 90 - (bounds.getNorth() + 90) - 90);
        return width * height;
    }

    static quickIncludes(viewport: LatLngBounds, bounds: LatLngBounds): boolean {
        return (
            viewport.contains(bounds.getNorthEast()) &&
            viewport.contains(bounds.getNorthWest()) &&
            viewport.contains(bounds.getSouthWest()) &&
            viewport.contains(bounds.getSouthEast())
        );
    }

    static wrapBoundingBox(bounds: LatLngBounds): LatLngBounds {
        const ne = bounds.getNorthEast().wrap();
        const sw = bounds.getSouthWest().wrap();

        return new LatLngBounds(sw, ne);
    }

    /**
     * Compares the area of intersection between two LatLngBounds and
     * returns true if the target approximately contains the target.
     * Used by the continental clustering to group maps that reasonably belong to that group
     *
     * @param targetBounds The Target bounds, such as the continent
     * @param sourceBounds The Source bounds, such as the map being tested
     * @param intersectionThresholdPercentage The percentage it should overlap, default at 50%
     * @returns true if the target approximately contains the source
     */
    static approximatelyContains(
        targetBounds: LatLngBounds,
        sourceBounds: LatLngBounds,
        intersectionThresholdPercentage?: number
    ): boolean {
        const intersectionArea = GeoUtil.intersectionArea(targetBounds, sourceBounds);

        if (intersectionArea === 0) return false;

        const sourceArea = GeoUtil.area(sourceBounds);
        const intersectionRatio = intersectionArea / sourceArea;

        const defaultIntersectionThreshold = 50; //percent
        const threshold = intersectionThresholdPercentage || defaultIntersectionThreshold;
        return intersectionRatio > threshold / 100;
    }

    /**
     * Calculates the intersection area of two Bounding boxes using axis-aligned rectangle logic
     * @param targetBounds The Target bounds, such as the continent
     * @param sourceBounds The Source bounds, such as the map being tested
     * @returns The area of the intersection
     */
    static intersectionArea(targetBounds: LatLngBounds, sourceBounds: LatLngBounds): number {
        // The bounds do not intersect at all
        if (!sourceBounds.intersects(targetBounds)) {
            return 0;
        }

        // The bounds are completely inside the target so the source is the intersection
        if (
            targetBounds.contains(sourceBounds.getNorthWest()) &&
            targetBounds.contains(sourceBounds.getNorthEast()) &&
            targetBounds.contains(sourceBounds.getSouthWest()) &&
            targetBounds.contains(sourceBounds.getSouthEast())
        ) {
            return GeoUtil.areaInKM(sourceBounds);
        }

        // Convert to rectangle points for easier reading
        const latLngBoundsToPoints = (
            bounds: LatLngBounds
        ): { left: number; right: number; top: number; bottom: number } => {
            return {
                left: bounds.getWest(),
                right: bounds.getEast(),
                top: bounds.getNorth(),
                bottom: bounds.getSouth(),
            };
        };

        const targetPoints = latLngBoundsToPoints(targetBounds);
        const sourcePoints = latLngBoundsToPoints(sourceBounds);

        // Calculate intersection of axis-aligned rectangle
        const leftIntersect = Math.max(targetPoints.left, sourcePoints.left);
        const rightIntersect = Math.min(targetPoints.right, sourcePoints.right);
        const bottomIntersect = Math.max(targetPoints.bottom, sourcePoints.bottom);
        const topIntersect = Math.min(targetPoints.top, sourcePoints.top);

        const intersectsBounds = new LatLngBounds(
            new LatLng(topIntersect, leftIntersect),
            new LatLng(bottomIntersect, rightIntersect)
        );
        return GeoUtil.areaInKM(intersectsBounds);
    }

    /**
     * We say a map is global if it approximately covers the entire world's bounding box
     * The thresholds are just reasonable guesses based on the content
     * @param bounds The LatLngBounds
     * @returns true if the bounds can be reasonably considered global
     */
    static isApproximatelyGlobalBounds = (bounds: LatLngBounds): boolean => {
        return bounds.getNorth() > 50 && bounds.getSouth() < -50 && bounds.getWest() < -40 && bounds.getEast() > 40;
    };

    static areaInKM = (bounds: LatLngBounds) => {
        const geoJson = new L.Polygon(GeoUtil.polygonForBounds(bounds)).toGeoJSON();
        const area = turfArea(geoJson);
        const areaKm2 = area / 1000 / 1000;
        return areaKm2;
    };
}
