import React, { useEffect, useState } from 'react';
import { Icon, LatLng, Point } from 'leaflet';
import { Marker, useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectFlyToMapPosition } from 'store/Map/selectors';

//to be included in the mapcontainer to have access to map
//this receives the actions from  search-bar

const icon = new Icon({
    iconSize: new Point(30.0, 40.0),
    iconUrl: '/assets/marker-icon.svg',
});

const SearchMap = () => {
    const [showMarker, setShowMarker] = useState(false);
    const [position, setPosition] = useState<LatLng | undefined>(undefined);

    const map = useMap();

    const mapPosition = useSelector(selectFlyToMapPosition);

    useEffect(() => {
        if (mapPosition instanceof LatLng) {
            const position = mapPosition as LatLng;
            map.flyTo(position, 11);
            setShowMarker(true);
            setPosition(position);
        } else {
            setShowMarker(false);
        }
    }, [map, mapPosition]);

    if (showMarker && position) {
        return <Marker position={position} icon={icon} />;
    }

    return <React.Fragment />;
};
export default SearchMap;
