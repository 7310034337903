import Analytics from 'lib/user-analytics';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface MenuLinkProps {
    selected: boolean;
    iconLight: string;
    iconDark: string;
    label: string;
    to: string;
}

const MenuLink = (props: MenuLinkProps) => {
    const { iconLight, iconDark, label, selected, to } = props;
    const [changeIcon, setChangeIcon] = useState<boolean>(false);

    useEffect(() => {
        setChangeIcon(!selected);
    }, [selected]);

    const handleClick = () => {
        setChangeIcon(false);
        Analytics.Event('Dashboard Menu Link Clicked', label);
    };

    return (
        <ProfileSideBarLinkItemContainer
            onClick={handleClick}
            onMouseLeave={() => !selected && setChangeIcon(true)}
            onMouseOver={() => setChangeIcon(false)}
            selected={selected}
            className={selected ? 'selected' : ''}
            to={to}
        >
            <ProfileSidebarLinkItemIcon src={changeIcon ? iconDark : iconLight} />
            <ProfileSideBarLinkItemLabel>{label}</ProfileSideBarLinkItemLabel>
        </ProfileSideBarLinkItemContainer>
    );
};

export default MenuLink;

interface Selected {
    selected: boolean;
}

const ProfileSidebarLinkItemIcon = styled.img`
    margin-left: 20px;
    margin-right: 20px;
    width: 25px;
    height: 25px;
`;

const ProfileSideBarLinkItemLabel = styled.div`
    color: inherit;
    font-size: 16px;
`;

const ProfileSideBarLinkItemContainer = styled(Link)<Selected>`
    flex-basis: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 15px 0px;
    text-decoration: unset !important;

    ${(props) =>
        props.selected && `background-color: ${props.theme.secondary.color}; color: ${props.theme.primary.color};`}

    .selected {
        background-color: ${(props) => props.theme.secondary.color};
        color: ${(props) => props.theme.primary.color};
    }

    &:hover {
        background-color: ${(props) => props.theme.secondary.color};
    }

    &:hover ${ProfileSideBarLinkItemLabel} {
        color: ${(props) => props.theme.primary.color};
    }

    &:last-child {
        border-radius: 0px 0px 6px 6px;
    }
`;
