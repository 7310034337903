import { StyledButton } from 'components/Shared/Buttons/styled-button';
import styled from 'styled-components';

interface OrderCompleteProps {
    handleClose: () => void;
}

const OrderComplete = (props: OrderCompleteProps) => {
    return (
        <Container>
            <Header>
                <h2>Congratulations, your order is complete!</h2>
            </Header>
            <Content>
                <ul>
                    <li>You should receive your receipt by email, if not please check your Spam folder.</li>
                    <li>You can track the progress of the order from your account Dashboard.</li>
                    <li>
                        Once your order is ready, you will receive a notification by email and can download the image
                        directly from your account Dashboard.
                    </li>
                </ul>

                <StyledButton onClick={props.handleClose}>CLOSE</StyledButton>
            </Content>
        </Container>
    );
};

export default OrderComplete;

const Container = styled.div`
    background: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    margin: 20px auto;
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    padding: 20px;
    max-width: 1200px;
    margin: 20px;
`;

const Header = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Content = styled.div`
    margin: 10px;
`;
