import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMyProfile } from '../../../store/App/selectors';
import profileIconUrl from '../profile-icon-url';
import MenuLink from './menu-link';
import MenuUserDetails from './menu-user-details';
import { Link, useLocation } from 'react-router-dom';
import Analytics from 'lib/user-analytics';

const MenuSoar = () => {
    const myProfile = useSelector(selectMyProfile);
    const location = useLocation();

    return (
        <React.Fragment>
            <ProfileSideBarContainer>
                <MenuUserDetails myProfile={myProfile} />

                <ProfileSideBarLinkList>
                    <MenuLink
                        iconLight={profileIconUrl.accountSettingsLightUrl}
                        iconDark={profileIconUrl.accountSettingsDarkUrl}
                        selected={location.pathname === '/dashboard/account-settings'}
                        label="Account Settings"
                        to="/dashboard/account-settings"
                    />

                    <MenuLink
                        iconLight={profileIconUrl.satelliteQueueLightUrl}
                        iconDark={profileIconUrl.satelliteQueueDarkUrl}
                        selected={location.pathname === '/dashboard/satellite-queue'}
                        label="My Pics from Space queue"
                        to="/dashboard/satellite-queue"
                    />

                    <MenuLink
                        iconLight={profileIconUrl.logoutLightUrl}
                        iconDark={profileIconUrl.logoutDarkUrl}
                        selected={false}
                        label="Sign Out"
                        to="/logout"
                    />
                </ProfileSideBarLinkList>
            </ProfileSideBarContainer>
            <ProfileSidebarTermsLink>
                <Link
                    to="/dashboard/terms"
                    onClick={() => Analytics.Event('Dashboard Menu clicked', 'Terms of Service')}
                >
                    Terms of Service
                </Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link
                    to="/dashboard/privacy"
                    onClick={() => Analytics.Event('Dashboard Menu clicked', 'Privacy Policy')}
                >
                    Privacy Policy
                </Link>
            </ProfileSidebarTermsLink>
        </React.Fragment>
    );
};

export default MenuSoar;

const ProfileSideBarContainer = styled.div`
    background-color: ${(props) => props.theme.primary.color};
    color: ${(props) => props.theme.primary.text};
    border-radius: 6px;
    width: 330px;
    padding: 2px;

    @media only screen and (max-width: 600px) {
        background-color: transparent !important;
        width: 100%;
    }
`;

const ProfileSidebarTermsLink = styled.div`
    background-color: transparent;
    padding-top: 10px;
    width: 330px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    a {
        font-size: 14px;
        text-decoration-line: underline;
        color: rgba(255, 255, 255, 0.5) !important;
    }

    @media only screen and (max-width: 600px) {
        text-align: left;
        padding-left: 1.3rem;
    }
`;

const ProfileSideBarLinkList = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 6px 6px;
`;
