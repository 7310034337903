import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectSatelliteAOI,
    selectSatelliteSelectedFeature,
} from '../../../store/Map/SatelliteArchive/selectors';
import GeoUtil from '../../../lib/geo-util';
import SatelliteQualityWatermark from '../satellite-quality-watermark';
import ImageOverlayTool from '../AnnotationTools/ImageOverlayTool/distorted-image-overlay-tool';


const SatelliteFeatures = () => {
    const aoi = useSelector(selectSatelliteAOI);
    const selectedFeature = useSelector(selectSatelliteSelectedFeature);
    if (!selectedFeature || !selectedFeature.geometryWKT || !aoi) return <React.Fragment />

    const anchors = GeoUtil.polygonFromPolygonWKT(selectedFeature.geometryWKT);  // Already ordered by the backend

    return (
        <React.Fragment>
            <ImageOverlayTool
                url={selectedFeature.previewUrl}
                anchors={anchors}
                clip={aoi}
                opacity={1.0}
                zIndex={10000}
            />
            <SatelliteQualityWatermark bounds={aoi} />;
        </React.Fragment>
    );
};

export default SatelliteFeatures;
