import watch from 'redux-watch';
import { Action, AppState } from './root-reducer';


export interface QueryParameter<T> {
    selector: (state: AppState) => T,
    action: (value: T) => Action,
    valueToString: (value: T) => string | undefined,
    stringToValue: (str: string) => T,
    isEqual: (a: T, b: T) => boolean,
    useBrowserHistory: boolean,
}

function URIEnhancer({
    store,
    params,
    history
}) {

    // Set the initial state based on the URL parameters
    Object.keys(params).forEach(key => {
        const param = params[key];
        const { action, stringToValue } = param;
        const locationParams = new URLSearchParams(location.search);
        const valueString = locationParams.get(key);

        // If the URL has the parameter, dispatch it into redux
        if (valueString) {
            const value = stringToValue(locationParams.get(key))
            store.dispatch(action(value))
        } else {
            // If there is no URL parameter, set it from the default state
            const state = store.getState();
            const selector = param.selector;
            const value = selector(state);
            if (value) {
                const locationParams = new URLSearchParams(location.search);
                locationParams.set(key, param.valueToString(value))
                const newLocationString = `?${locationParams.toString()}`

                const newLocation = {
                    pathname: location.pathname,
                    search: newLocationString,
                    hash: location.hash,
                    state: history.location.state,
                }
                history.replace(newLocation);
            }
        }
    });

    // Do not update URI while this enhancer is updating the redux state
    let ignoreHistoryUpdateWhileStateUpdates = false;

    // Update the redux state when the URI changes
    const unsubscribeHistoryListener = history.listen((_, browserUpdateAction) => {

        if (!ignoreHistoryUpdateWhileStateUpdates) {
            Object.keys(params).forEach(key => {
                const state = store.getState();
                const param = params[key];
                const { selector, action, stringToValue, isEqual, } = param;
                const locationParams = new URLSearchParams(location.search);

                const valueString = locationParams.get(key);
                if (valueString) {
                    const value = stringToValue(locationParams.get(key))
                    const currentValue = selector(state);

                    // If the history action was a pop or push, ignore the dispatches that
                    // would not update the browser history
                    if ((browserUpdateAction === 'POP' || browserUpdateAction === 'PUSH') && !param.useBrowserHistory) {
                        return;
                    }

                    // Only update state if a change has occurred
                    if (!isEqual(currentValue, value)) {
                        store.dispatch(action(value))
                    }
                }
            })
        }
    })

    // Update the URI when the state changes
    const subscriptions: any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any
    Object.keys(params).forEach(key => {
        const param = params[key];
        const selector = param.selector;
        const watcher = watch(() => selector(store.getState()));
        const subscription = store.subscribe(watcher((newValue, oldValue) => {

            if (newValue === undefined || newValue === 'undefined') {
                ignoreHistoryUpdateWhileStateUpdates = true;

                const locationParams = new URLSearchParams(location.search);
                locationParams.delete(key)

                const newLocationSearchString = `?${locationParams}`
                const oldLocationSearchString = location.search || '?'

                if (newLocationSearchString !== oldLocationSearchString) {
                    const newLocation = {
                        pathname: location.pathname,
                        search: newLocationSearchString,
                        hash: location.hash,
                    }

                    if (param.useBrowserHistory) {
                        history.push(newLocation, [history.state]);
                    } else {
                        history.replace(newLocation, [history.state]);
                    }
                }
                ignoreHistoryUpdateWhileStateUpdates = false;
            }

            if (newValue !== oldValue) {
                ignoreHistoryUpdateWhileStateUpdates = true;

                const locationParams = new URLSearchParams(location.search);
                locationParams.set(key, param.valueToString(newValue))

                const newLocationSearchString = `?${locationParams}`
                const oldLocationSearchString = location.search || '?'

                if (newLocationSearchString !== oldLocationSearchString) {
                    const newLocation = {
                        pathname: location.pathname,
                        search: newLocationSearchString,
                        hash: location.hash,
                    }

                    if (param.useBrowserHistory) {
                        history.push(newLocation, [history.state]);
                    } else {
                        history.replace(newLocation, [history.state]);
                    }
                }
                ignoreHistoryUpdateWhileStateUpdates = false;
            }
        }))
        subscriptions.push(subscription);
    });

    return () => {
        subscriptions.forEach(subscription => subscription())
        unsubscribeHistoryListener();
    }
}


URIEnhancer.enhancer = function makeStoreEnhancer(config) {
    return storeCreator => (reducer, initialState, enhancer) => {
        const store = storeCreator(reducer, initialState, enhancer)
        URIEnhancer({ store, ...config })
        return store
    }
}

export default URIEnhancer;
