import { LatLngBounds } from 'leaflet';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { actionSatelliteSelectAOI, actionSatelliteSetAOIError } from '../../store/Map/SatelliteArchive/actions';
import { selectIsSatelliteBoxSelectActive } from '../../store/Map/SatelliteArchive/selectors';
import SatelliteFeatures from './SatelliteProviders/satellite-features';
import Basemaps from './basemaps';
import CoordinateUpdater from './coordinate-updater';
import SatelliteHighlightedAOI from './satellite-highlighted-aoi';
import Tasks from './Task/tasks';
import PoweredBySoar from './powered-by-soar';
import SearchMap from 'components/Search/search-map';
import MapScaleControl from './MapScale/map-scale-control';
import SatelliteAOIControl from './SatelliteAOITool/satellite-aoi-control';

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = 2.5;
const WORLD_CENTER: [number, number] = [34.5, 0];
const MIN_ZOOM_LEVEL = 2.0;
const MAX_ZOOM_LEVEL = 25;

const MapView = () => {
    const dispatch = useDispatch();
    const isAOIActive = useSelector(selectIsSatelliteBoxSelectActive);
    const handleAOIChange = (aoi: LatLngBounds) => {
        dispatch(actionSatelliteSelectAOI(aoi));
    };

    const handleAOIError = (error: Error | undefined) => {
        dispatch(actionSatelliteSetAOIError(error));
    };

    return (
        <MapContainer
            zoom={WORLD_ZOOM}
            center={WORLD_CENTER}
            maxZoom={MAX_ZOOM_LEVEL}
            minZoom={MIN_ZOOM_LEVEL}
            zoomControl={false}
            id="map"
        >
            <MapScaleControl />
            <CoordinateUpdater />
            <SearchMap />
            <Basemaps />

            <ZoomControl position="topright" />

            {isAOIActive ? <SatelliteAOIControl onAOIChange={handleAOIChange} onAOIError={handleAOIError} /> : null}

            <SatelliteFeatures />
            <SatelliteHighlightedAOI />
            <Tasks />
            <PoweredBySoar />
        </MapContainer>
    );
};

export default MapView;
