import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { FlatButton, LinkButton } from '../Shared/Buttons/styled-button';
import { useDispatch, useSelector } from 'react-redux';
import { selectShoppingCartOrders } from 'store/ShoppingCart/selectors';
import { actionShoppingCartRemoveOrder } from 'store/ShoppingCart/actions';
import { Link } from 'react-router-dom';
import Analytics from 'lib/user-analytics';
import { useEffect, useState } from 'react';
import { Products, Suppliers } from 'api/api-supplier';
import PriceWithCurrency from './price-with-currency';
import ImageComparisonModal from './image-comparison-modal';
import ImagePreview from './image-preview';
import GoogleAnalytics from 'lib/google-analytics';
import { actionClearMap } from 'store/Map/actions';

const moment = require('moment');

const ShoppingCart = () => {
    const dispatch = useDispatch();
    const cartOrders = useSelector(selectShoppingCartOrders);

    const [showComparisonProductKey, setShowComparisonProductKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        GoogleAnalytics.ViewCart(cartOrders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowPreview = (product: string) => {
        setShowComparisonProductKey(product);
        Analytics.Event('Shopping cart', 'Clicked Show Preview');
    };
    return (
        <ShoppingCartContainer>
            {cartOrders.length === 0 ? (
                <OrdersContainer>
                    <HeaderRow>
                        <b>Your cart is empty.</b>
                        &nbsp;&nbsp;
                        <Link
                            to="/satellite"
                            onClick={() => {
                                dispatch(actionClearMap());
                                Analytics.Event(
                                    'Shopping cart drawer',
                                    'Clicked to Browse Satellite Images on empty cart'
                                );
                            }}
                        >
                            <b>Search for more Pics from Space</b>
                        </Link>
                    </HeaderRow>
                </OrdersContainer>
            ) : (
                <OrdersContainer>
                    <HeaderRow>
                        <Col sm={7}>
                            <b>Your Cart</b>
                        </Col>
                        <Col sm={3}>
                            <b>Price</b>
                        </Col>
                        <Col sm={2}></Col>
                    </HeaderRow>
                    <div>
                        {cartOrders.map((item, index) => {
                            return (
                                <ItemRow key={index}>
                                    <Col sm={7}>
                                        {item.orderType === 'ARCHIVE' ? (
                                            <div className="d-flex">
                                                <div>
                                                    <PreviewContainer>
                                                        <ImagePreview previewUrl={item.previewUrl} />
                                                    </PreviewContainer>

                                                    <PreviewButton onClick={() => handleShowPreview(item.productKey)}>
                                                        <span>Show sample</span> <i className="fa fa-search" />
                                                    </PreviewButton>
                                                </div>
                                                <div className="ml-2">
                                                    <SatelliteDetail>
                                                        <b>Image date: </b>
                                                        {moment(item.date).format('MMM Do YYYY')}
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Supplier: </b>
                                                        {Suppliers[item.supplier].name}
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Total Area: </b>
                                                        {item.archiveSelectedAreaInKm}km²
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Resolution: </b>
                                                        {Products[item.productKey]?.resolution}
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Cloud Cover: </b>
                                                        {item.cloud}%
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Nadir: </b>
                                                        {item.rollAngle}&#176;
                                                    </SatelliteDetail>
                                                </div>
                                            </div>
                                        ) : item.orderType === 'NEWCOLLECT' ? (
                                            <div className="d-flex">
                                                <div>
                                                    <PreviewNewCollectImg src="/assets/floating-drawer-icons/icon-new-collect-brown.svg" />
                                                    <PreviewButton
                                                        onClick={() => handleShowPreview(item.productKey)}
                                                        className="newcollect"
                                                    >
                                                        <span>Show sample</span> <i className="fa fa-search" />
                                                    </PreviewButton>
                                                </div>

                                                <div className="ml-2">
                                                    <SatelliteDetail>
                                                        <b>New image! </b>
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Supplier: </b>
                                                        {Suppliers[item.supplier].name}
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Total Area: </b>
                                                        {item.areaInKm}km²
                                                    </SatelliteDetail>
                                                    <SatelliteDetail>
                                                        <b>Resolution: </b>
                                                        {Products[item.productKey]?.resolution}
                                                    </SatelliteDetail>
                                                </div>
                                            </div>
                                        ) : null}
                                    </Col>
                                    <Col sm={3}>
                                        <p>
                                            <PriceWithCurrency value={item.totalPrice} currency={item.currency} />
                                        </p>
                                    </Col>
                                    <Col sm={2}>
                                        <FlatButton
                                            onClick={() => {
                                                dispatch(actionShoppingCartRemoveOrder(item));
                                                GoogleAnalytics.RemoveFromCart(item);
                                            }}
                                        >
                                            <i className="fa fa-trash" />
                                        </FlatButton>
                                    </Col>
                                </ItemRow>
                            );
                        })}
                    </div>
                    <FooterRow>
                        <Col sm={7} className="m-auto d-flex justify-content-center">
                            <b>Total</b>
                        </Col>
                        <Col sm={3} className="m-auto">
                            <b>
                                <PriceWithCurrency
                                    value={cartOrders.reduce((sum, current) => sum + current.totalPrice, 0)}
                                    currency={cartOrders[0].currency}
                                />
                            </b>
                            {cartOrders[0].currency.toLowerCase() === 'aud' && <span className="ml-2">Inc. GST</span>}
                        </Col>
                        <Col sm={2}>
                            <LinkButton
                                to="/checkout"
                                onClick={() => Analytics.Event('Shopping cart drawer', 'Clicked Checkout button')}
                            >
                                CHECKOUT
                            </LinkButton>
                        </Col>
                    </FooterRow>
                    <Row className="justify-content-end mr-2">
                        <div className="text-center">
                            <p>--- or ---</p>
                            <Link
                                to="/satellite"
                                onClick={() => {
                                    dispatch(actionClearMap());
                                    Analytics.Event('Shopping cart drawer', 'Clicked Search more Pics from Space');
                                }}
                            >
                                Search for more Pics from Space
                            </Link>
                        </div>
                        <ImageComparisonModal
                            isOpen={showComparisonProductKey !== undefined}
                            toggle={() => setShowComparisonProductKey(undefined)}
                            productKey={showComparisonProductKey}
                        />
                    </Row>
                </OrdersContainer>
            )}
        </ShoppingCartContainer>
    );
};

export default ShoppingCart;

const ShoppingCartContainer = styled.div`
    background: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    margin: 20px auto;
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    padding: 20px;
    max-width: 1200px;

    &:hover {
        color: ${(props) => props.theme.secondary.hover};
    }
`;

const OrdersContainer = styled.div`
    margin: 20px;
`;

const HeaderRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
`;

const FooterRow = styled(Row)`
    margin-top: 20px;
    font-size: 18px;
    border-top-width: 0.5px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.secondary.color}30;
    padding: 10px;
`;

const ItemRow = styled(Row)`
    margin-top: 10px;
    padding-top: 10px;
    border-top-width: 0.5px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.secondary.color}30;
`;

const PreviewContainer = styled.div`
    div {
        display: flex;
        align-items: center;
        height: 100%;
        img {
            width: 200px;
            height: 200px;
        }
        &.unavailable {
            img {
                height: auto;
            }
        }
    }
`;

const PreviewNewCollectImg = styled.img`
    width: 200px;
    height: 130px;
    padding: 5px 20px 30px;
`;

const SatelliteDetail = styled.div`
    margin: 0px 5px 7px 7px;

    b {
        display: inline-block;
        width: 150px;
    }
`;

const PreviewButton = styled(FlatButton)`
    display: flex;
    align-items: center;

    &.newcollect {
        margin-top: -25px;
    }
    span {
        text-decoration: underline;
        margin-right: 5px;
    }
    i {
        margin-top: 3px;
    }
`;
