import { AppState } from '../root-reducer';

export const selectConfig = (state: AppState) => state.appDomain.config;

export const selectMaintenance = (state: AppState) => state.appDomain.maintenance;

export const selectShowLoginDialog = (state: AppState) => state.appDomain.showLoginDialog;

export const selectMyProfile = (state: AppState) => state.appDomain.myProfile;

export const selectLoggedIn = (state: AppState) => state.appDomain.loggedIn;
