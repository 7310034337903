import { LatLng, LatLngBounds, Polygon } from 'leaflet';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ListingType {
    IMAGE = 'IMAGE',
    TILE_LAYER = 'TILE_LAYER',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    WMS = 'WMS',
    ANIMATED = 'ANIMATED',
    ORDER = 'ORDER',
}

export enum AnalyticsAction {
    VIEW = 'VIEW',
    DOWNLOAD = 'DOWNLOAD',
    SEARCH = 'SEARCH',
    CART_ADD = 'CART_ADD',
    CART_REM = 'CART_REM',
}

export enum AnalyticsNote {
    MY_IMAGERY = 'MY_IMAGERY',
    BROWSE_PAGE = 'BROWSE_PAGE',
    SUBDOMAIN_DRAW = 'SUBDOMAIN_DRAW',
    YOUR_MAPS = 'YOUR_MAPS',
    FEATURED_DRAW = 'FEATURED_DRAW',
    DASHBOARD_TASK = 'DASHBOARD_TASK',
    CLUSTER = 'CLUSTER',
    USER_PROFILE = 'USER_PROFILE',
    SHARE = 'SHARE',
    SEARCH = 'SEARCH',
}

export enum Permission {
    None = -1,
    Read = 0,
    Write = 1,
    Review = 2,
    Admin = 3,
    Owner = 4,
}

export enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

export enum Review {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum SatelliteProvider {
    SINERGISE = 'SINERGISE',
    SOAR = 'SOAR',
    CSW = 'CSW',
    CGSTL = 'CGSTL'
}

// GET v1/listings/
export interface ListingDTO {
    id: number;
    listingType: ListingType;
    geometryWKT: string;
    metadata: string;

    title: string;
    description: string;
    tags: string[];
    categories: string[];

    /**
     * @deprecated to be removed in the future, avoid use where possible
     */
    previewUrl: string;

    filename: string;
    filehash?: string;
    tileUrl: string;
    minZoom?: number; //not used

    totalLikes: number;
    totalComments?: number;
    totalBookmarks?: number;

    //user
    userId: string;
    userName: string;
    avatarUrl: string;
    reputable?: boolean;

    createdAt: number;
    updatedAt?: number;
    public?: boolean;

    //ListingDetailsDTO
    similar?: ListingDTO[];

    //ListingForOwnerOrAdminDTO
    filesize?: number;
    review?: Review;
    error?: string;
    userEmail?: string;
    feedback?: string;
    processing?: boolean;

    //calculated values
    boundingBox: LatLngBounds;
    dateUploaded?: Date;

    //for drone // TODO double check this at end when removed drones
    position?: LatLng;
    distoredGeometry?: string;
    priceUsd: number;
    urlFullRes?: string;

    // WMS Layer properties
    layerName?: string;
    serverUrl?: string;
    layerImageFormat?: string;
    layerSpeedKbps?: number;
}

export interface CSWHistoricalLayer {
    orbitId: number;
    sceneId: number;
    stripId: number;
    mirrorOffNadir: string;
    date: Date;
    cloudPercentage: number;
    boundingBox: LatLng[];
    previewUrl: string;
}

export interface TaskPricePreview {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface CSWCollectionRequestCost {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency?: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface CSWHistoricalRequestCost {
    areaInKm: number;
    effectiveAreaInKm: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency?: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface OrderDTO {
    id: number;
    orderNumber: string;
    supplierCode: string;
    productId: number;
    productCode: string;
    productOption: string;
    deliveryFormat: string;
    countryCode: string;
    areaInKm: number;
    archiveSelectedAreaInKm: number;
    delayInSeconds: number;
    requestedDate: Date;
    created: Date;
    dueBy: Date;
    deliveryFormatNote: string;

    company: string;
    payment: {
        amount: number;
        pricePerKm: number;
        paidAt: Date;
        paymentCaptured: boolean;
        payment: boolean;
        currency: string;
        tax: number;
        paidByStripe: number;
        paidByOtherMethod: number;
        paidByVoucher: number;
        receiptUrl?: string;
        invoiceUrl?: string;
    };

    provider: string;
    geometryWKT: string;

    createdAt: Date;
    files: TaskFile[];
    status: CSWTaskStatus;
    effectiveAreaInKm: number;
    noteRejected?: string;
    currency: string;
    amount: number;
}

export interface OrderDetailsDTO extends OrderDTO {
    previewUrl: string;
    url: string;
    tileUrl: string;
    boundingBox: LatLngBounds;
}
export interface CSWTask {
    geometryWKT: string;
    id: number;
    orderNumber: string;
    deliverableType: string;
    effectiveAreaInKm: number;
    areaInKm: number;
    paidByStripe: number;
    paidByVoucher: number;
    paidByOtherMethod: number;
    additionalTax: number;
    total: number;
    currency?: string;
    totalTax?: number;
    status: CSWTaskStatus;
    createdAt: Date;
    company: string;
    provider: string;
    pricePerKm: number;
    boundingBox: LatLngBounds;
    receiptUrl?: string;
    invoiceUrl?: string;
    previewUrl?: string;
    url?: string;
    tileUrl?: string;
    noteRejected?: string;
    note?: string;
    files: TaskFile[];

    title?: string;

    owner?: { username?: string; userId?: string };

    username?: string;
    userId?: string;
}

interface TaskFile {
    type: string;
    description: string;
    id: string;
    fileName: string;
    contentType: string;
    fileSize: number;
    created: string;
}

export interface TaskForPayment {
    task: CSWTask;
    preview: TaskPricePreview;
}

export interface OrderForPayment {
    order: OrderDTO;
    preview: TaskPricePreview;
}

export enum CSWTaskStatus {
    CREATED = 'CREATED',
    REVIEW_SOAR = 'REVIEW_SOAR',
    REVIEW_PROVIDER = 'REVIEW_PROVIDER',
    COLLECTION = 'COLLECTION',
    QA_SOAR = 'QA_SOAR',
    DELIVERED = 'DELIVERED',
    REJECTED = 'REJECTED',
    REVIEW_SUPPLIER = 'REVIEW_SUPPLIER',
}

export interface ListingMetadata {
    title: string;
    tags: string[];
    description: string;
    categories: string[];
    tc?: boolean;
}

export interface UserDTO {
    firstName?: string;
    lastName?: string;
    userId: string;
    email?: string;
    createdAt?: number;
    bio?: string;
    name?: string;
    avatarUrl?: string;
    listings?: [any];
    twitterUrl?: string;
    ftpUsername?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    websiteUrl?: string;
    userSoarProfileUrl?: string;
    billingInfo?: BillingInfoDTO;
    domains?: DomainPermissionDTO[];

    linkedinUrl?: string;
    longDescription?: string;

    reputable?: boolean;
    roles?: string[];
}

export interface DomainPermissionDTO {
    domain: string;
    permission: Permission;
}

export interface BillingInfoDTO {
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    country?: string;
    city?: string;
    postCode?: string;
    phone?: string;
    paypal?: string;
}

export interface UploadCredentialsDTO {
    stsCredentials: {
        securityToken: string;
        accessKeyId: string;
        accessSecretKey: string;
    };
    ossRegion: string;
    bucketName: string;
    filename: string;
    path: string;
    distoredGeometry?: Polygon;
    listingId?: number;
}

export interface ResultDTO {
    success: boolean;
}

export interface DownloadLinkDTO {
    presignedUrl: string;
    filename: string;
}

export interface TileLayerHash {
    latlngBounds: LatLngBounds;
    listingId: number;
    author?: string;
    title?: string;
}

export interface PurchasedInfo {
    id: string;
    amount: number;
    paidAt: Date;
    createdAt: Date;
    receiptUrl: string;
    currency: string;
    items: PurchasedItemInfo[];
}

export interface PurchasedItemInfo {
    id: string;
    amount: number;
    paidAt: Date;
    createdAt: Date;
    ownerId: string;
    purchaseId: string;
    type: string;
    typeData: string;
    listingId?: string;
    currency: string;
}

export interface CountryInfo {
    country: string;
    countryCode: string;
}

export interface SentinelImageDimension {
    widthPixels: number;
    heightPixels: number;
    resolution: number;
    isAvailable: boolean;
    maxPixels: number;
    quality: 'Best' | 'Good' | 'Low' | 'Poor';
}
