import React from 'react';
import NavigationBar from '../NavigationBar/navbar';
import MapView from './map-view';
import { Outlet } from 'react-router-dom';
import SatelliteDrawer from 'components/Drawer/Satellite/satellite-drawer';
import SearchBar from 'components/Search/search-bar';

const MapLayout = () => {
    return (
        <React.Fragment>
            <NavigationBar />
            <SatelliteDrawer />
            <SearchBar />
            <Outlet />
            <MapView />
        </React.Fragment>
    );
};

export default MapLayout;
