import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSatelliteAOI, selectSatelliteSelectedFeature } from '../../../store/Map/SatelliteArchive/selectors';
import { selectMapZoom } from '../../../store/Map/selectors';
import SatelliteSelectAOI from './SelectAOI/satellite-select-aoi';
import SatelliteZoomIn from './ZoomIn/satellite-zoom-in';
import { useLocation } from 'react-router';
import SatelliteImageSearch from './Select/satellite-image-search';

// TODO: Better calculation of when we should allow the user to select an area of interest
const AREA_OF_INTEREST_MAP_ZOOM_THRESHOLD = 10;

enum DrawerState {
    ZOOM_IN,
    SELECT_AOI,
    SELECT_RESULT,
    VIEW_TASK,
}

const SatelliteDrawer = () => {
    const location = useLocation();
    const mapZoom = useSelector(selectMapZoom);
    const selectedAOI = useSelector(selectSatelliteAOI);
    const selectedFeature = useSelector(selectSatelliteSelectedFeature);
    const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.ZOOM_IN);

    useEffect(() => {
        const locationParts = location.pathname.split('/');
        if (locationParts.length === 3) {
            const taskId = location.pathname.split('/')[2];
            if (taskId && Number(taskId)) {
                console.log('Viewing task: ' + taskId);
                setDrawerState(DrawerState.VIEW_TASK);
                return;
            }
        }

        if (selectedAOI) {
            setDrawerState(DrawerState.SELECT_RESULT);
            return;
        }

        if (mapZoom && mapZoom >= AREA_OF_INTEREST_MAP_ZOOM_THRESHOLD) {
            setDrawerState(DrawerState.SELECT_AOI);
        } else {
            setDrawerState(DrawerState.ZOOM_IN);
        }
    }, [selectedAOI, selectedFeature, mapZoom, location]);

    switch (drawerState) {
        case DrawerState.ZOOM_IN:
            return <SatelliteZoomIn />;

        case DrawerState.SELECT_AOI:
            return <SatelliteSelectAOI />;

        case DrawerState.SELECT_RESULT:
            return <SatelliteImageSearch />;

        // TODO: Drawer for selected task
        default:
            return <React.Fragment />;
    }
};

export default SatelliteDrawer;
