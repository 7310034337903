import React, { useEffect, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetMapBounds, actionSetMapZoom } from '../../store/Map/actions';
import { selectMapBounds } from '../../store/Map/selectors';

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-89.98155760646617, -260],
    [89.99346179538875, 240],
];

const CoordinateUpdater = () => {
    const leaflet = useMap();
    const mapBounds = useSelector(selectMapBounds);
    const dispatch = useDispatch();

    // We prevent the map from wrapping (below) however in Hawaii it's possible to
    // view the map on both sides of the Anti-meridian eg longitude 200 && -150
    // which causes "No maps" and "No sentinel results" bugs like #3039
    // This wraps the viewport back to world bounds -180 .. 180
    const fixAntiMeridianIfRequired = useCallback(() => {
        const center = leaflet.getCenter();
        const zoom = leaflet.getZoom();

        if (zoom >= 5) {
            const wrappedCenter = center.wrap();

            if (!wrappedCenter.equals(center)) {
                leaflet.setView(wrappedCenter, zoom, { animate: false });
            }
        }
    }, [leaflet]);

    const updateMapPosition = useCallback(() => {
        const bounds = leaflet.getBounds();
        const zoom = leaflet.getZoom();
        dispatch(actionSetMapBounds(bounds));
        dispatch(actionSetMapZoom(zoom));
    }, [dispatch, leaflet]);

    useEffect(() => {
        if (mapBounds) {
            leaflet.fitBounds(mapBounds);
        }
    }, [leaflet, mapBounds]);

    useEffect(() => {
        if (leaflet) {
            //Prevent the map from wrapping by forcing in to pan inside world bounds
            leaflet.on('drag', () => {
                leaflet.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            });

            leaflet.on('zoomend', () => {
                console.log('zoomend');
                leaflet.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                fixAntiMeridianIfRequired();
                updateMapPosition();
            });

            leaflet.on('dragend', () => {
                leaflet.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                fixAntiMeridianIfRequired();
                updateMapPosition();
            });

            //execute on initial load
            updateMapPosition();
        }
    }, [fixAntiMeridianIfRequired, leaflet, updateMapPosition]);

    return <React.Fragment></React.Fragment>;
};

export default CoordinateUpdater;
