// One of the only places push is accepted otherwise will display an error and message
// eslint-disable-next-line no-restricted-imports
import { push } from 'connected-react-router';
import { LatLng } from 'leaflet';
import { useLocation } from 'react-router-dom';
import store from '../store/store';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function usePositionQuery(): [position?: LatLng, zoom?: number] {
    const pos = useQuery().get('pos');
    if (pos) {
        const parts = pos.split(',');
        const lat = parseFloat(parts[0]);
        const lng = parseFloat(parts[1]);
        const zoom = Number(parseFloat(parts[2]).toFixed(2));
        return [new LatLng(lat, lng), zoom];
    }
    return [undefined, undefined];
}

// TODO: Remove all URI mutations from this class and all related code paths.
//       In fact we should probably remove this utility class altogether
export default class UriHelper {
    public static POSITION_URI_KEY = 'pos';
    public static TILELAYER_URI_KEY = 'tileLayer';
    public static TASK_URI_KEY = 'task';
    public static BASEMAP_URI_KEY = 'basemap';
    public static ANNOTATION_URI_KEY = 'annotations';
    public static OLD_SENTINEL_URI_KEY = 'service'; // Backward compatibility

    public static addParameterToUri(key, value) {
        if (window.history.replaceState) {
            const params = new URLSearchParams(window.location.search);
            params.set(key, value);
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }

    public static addParametersToUri(obj) {
        if (window.history.replaceState) {
            const params = new URLSearchParams(window.location.search);
            for (const key in obj) {
                const value: string = obj[key];
                if (value && value.length > 0) {
                    params.set(key, value);
                } else {
                    params.delete(key);
                }
            }
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                params.toString().length > 0 ? '?' + params : ''
            }`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }

    public static removeParameterFromUri(key) {
        if (window.history.replaceState) {
            const params = new URLSearchParams(window.location.search);
            params.delete(key);

            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }

    public static tryGetParam(key: string): string | null {
        return this.getParamOrNull(key, new URLSearchParams(window.location.search));
    }

    private static getParamOrNull(key: string, params: URLSearchParams): string | null {
        if (params.has(key)) {
            return params.get(key);
        }
        return null;
    }

    public static removeAllSentinelParametersFromURI = () => {
        UriHelper.removeParameterFromUri('service');
        UriHelper.removeParameterFromUri('request');
        UriHelper.removeParameterFromUri('bbox');
        UriHelper.removeParameterFromUri('description');
        UriHelper.removeParameterFromUri('time');
        UriHelper.removeParameterFromUri('layers');
        UriHelper.removeParameterFromUri('layerKey');
        UriHelper.removeParameterFromUri('title');
        UriHelper.removeParameterFromUri('image_data');
    };

    public static addPositionToPath(path) {
        const pos = UriHelper.tryGetParam('pos');
        if (pos) {
            store.dispatch(push({ pathname: path, search: pos ? `pos=${pos}` : '' }));
            return;
        }
        store.dispatch(push(path));
    }

    public static navigateToSatellite() {
        UriHelper.addPositionToPath('/satellites');
    }
}
