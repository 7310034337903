import { StyledButton } from 'components/Shared/Buttons/styled-button';
import styled from 'styled-components';

const PageNotFoundError = () => {
    return (
        <ErrorBackground>
            <AlertContainer>
                <h1>Oops...</h1>
                <p>
                    It looks like you've got here by accident or we're still <br />
                    working on this section of the service.
                </p>
                <StyledButton onClick={() => window.open('/', '_self')}>BACK TO HOMEPAGE</StyledButton>
            </AlertContainer>
        </ErrorBackground>
    );
};

const ErrorBackground = styled.div`
    background-image: url(/assets/PfS_UI_bg_pixellated_sepia.jpg);
    background-size: cover;
    min-height: 100vh;
    padding: 50px;
    color: ${(props) => props.theme.primary.color};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AlertContainer = styled.div`
    width: 500px;
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    border-radius: 6px;
    background: ${(props) => props.theme.primary.color};
    display: block;
    padding 10px;
    margin-top: -10vh;
`;

export default PageNotFoundError;
