import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

const Skymap50TermsAndConditions = () => {
    return (
        <Skymap50TermsAndConditionsContainer>
            <h1>SkyMap50 Satellite Imagery Terms & Conditions</h1>
            <Container className="privacy-agreement">
                <div className="main main-raised">
                    <div className="section" style={{ marginTop: '-25px' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="col-md-12">
                                    <h5 className="privacy-description">Last updated: 20 July 2021</h5>
                                    <br />

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        Order Specifications
                                    </h5>
                                    <p> All orders, unless otherwise specified will be delivered as:</p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>50 cm pixel resolution </li>
                                        <li>WGS84 Datum and UTM projection</li>
                                        <li>Natural colour corrected (NCC) image</li>
                                        <li>a GeoTIFF or ECW image format </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        New Collection
                                    </h5>
                                    <p>
                                        {' '}
                                        In addition to the above, New Collect orders are supplied on the following
                                        terms:
                                    </p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>Collection will commence within 5 working days of the order</li>
                                        <li>Maximum of 15% cloud cover</li>
                                        <li>
                                            Orders above 15% cloud cover will be reattempted but this will cause a delay
                                            in delivery in worst case scenarios these delay may be months.
                                        </li>
                                        <li>
                                            Delivery of your order will be 2 working days after successful collection.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        Archive Orders
                                    </h5>
                                    <p>
                                        {' '}
                                        In addition to the above, Archive orders are supplied on the following terms:
                                    </p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            All images are ‘As-Is’ with no minimum cloud cover. All preview images
                                            demonstrate the cloud coverage of the image
                                        </li>
                                        <li>
                                            All orders covering more than 1 scene will be mosaiced to the best of
                                            endeavours
                                        </li>
                                        <li>
                                            Archive imagery is not geo- or orthorectified and may contain locational
                                            accuracy errors
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        1. ACCEPTANCE OF TERMS
                                    </h5>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            1.1. The user ("You") agree to and are bound by the Terms of Service set out
                                            below and in any modified or additional terms that Soar.Earth Ltd and any
                                            related entity (together "Soar") may publish from time to time
                                            (collectively, the "Terms").
                                        </li>
                                        <li>
                                            1.2. Soar may change these Terms from time to time. Your continued access or
                                            use of the Site constitutes your acceptance of such changes.
                                        </li>
                                        <li>
                                            1.3. Your access and use of the Site is subject to the current version of
                                            the Terms, rules and guidelines posted on the Site at the time of such use.
                                        </li>
                                        <li>
                                            1.4. Your access and use of any imagery purchased through Soar and supplied
                                            by through this Service is subject to the current version of the
                                            Terms,EULA,rules and guidelines posted on the Site at the time of such use.{' '}
                                        </li>
                                        <li>
                                            1.5. If you do not agree to these Terms, do not access or use the satellite
                                            imagery from this site.
                                        </li>
                                        <li>
                                            1.6. Submitting an order through the Service means you agree to be bound by
                                            the Soar general Terms of Service and these Skymap50 Satellite Imagery Terms
                                            and Conditions.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        2. DEFINITIONS
                                    </h5>
                                    <table className="privacy-table">
                                        <tr>
                                            <td>Archive Imagery</td>
                                            <td>
                                                Imagery previously collected and stored by the Skymap50 satellite prior
                                                to the placement of an order
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Business day</td>
                                            <td>Refers to Monday to Friday between 9 am and 5 pm GMT+8</td>
                                        </tr>
                                        <tr>
                                            <td>Custom order</td>
                                            <td>
                                                <span>An order that is:</span>
                                                <ul>
                                                    <li>
                                                        An area greater than 300 km<sup>2</sup>{' '}
                                                    </li>
                                                    <li>Multispectral imagery</li>
                                                    <li>Stereo imagery</li>
                                                    <li>Raw Imagery; or</li>
                                                    <li>
                                                        Has other requirements that are not capable of being processed
                                                        on the Soar platform directly.
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Derivative Image Product (DIP)</td>
                                            <td>
                                                means a product or information derived from the Products, but it does
                                                not contain any imagery data derived from the Products, and is
                                                irreversible and uncoupled from the source imagery data derived from the
                                                Products. Notwithstanding the foregoing, for the sake of clarity, any
                                                Digital Elevation Model("DEM") or Digital Terrain Model ("DTM") (in any
                                                form whatsoever, i.e. database for instance) derived from the Products
                                                shall never be considered as a DIP.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>End User</td>
                                            <td>
                                                any individual, legal entity or governmental agency receiving the
                                                Product and accepting these Terms & Conditions. "End User" specifies the
                                                individual, entity or agency with its address that the Product is
                                                delivered, it may be the branches or business entities or offices of the
                                                said individual, entity or agency only when there is a prior written
                                                agreement between You and Soar.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>New Collect imagery</td>
                                            <td>
                                                Imagery from data collection over an area specified by You and is not
                                                Archive Imagery
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Product</td>
                                            <td>
                                                Means any New Collect or Archive Imagery supplied by Soar under the
                                                SkyMap50 Brand
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SkyMapEXP</td>
                                            <td>
                                                Means an Urgent New Collect order request with a 24-hour turn around
                                                from order acknowledgement by Soar.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Value Added Product (VAP)</td>
                                            <td>
                                                means any product developed by You from the Products through technical
                                                manipulation and /or addition of other data, and resulting in a
                                                significant modification of the corresponding product. Notwithstanding
                                                the foregoing, any DEM or DTM derived from the Products is regarded as a
                                                VAP.
                                            </td>
                                        </tr>
                                    </table>
                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        3. SKYMAP50 IMAGERY ORDERS AND SPECIFICATIONS
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            3.1. Any imagery is supplied under, and the use is limited by theEnd User
                                            License Agreement described below
                                        </li>
                                        <li>
                                            3.2. All imagery is subject to minimum and maximum order size for the Area
                                            of Interest (AOI) for the order and these are:
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                3.2.1 For New Collect orders the minimum area for collection is 50 km
                                                <sup>2</sup>.
                                            </li>
                                            <li>
                                                3.2.2 For New Collect Imagery the minimum area for collection is 28 km
                                                <sup>2</sup>.
                                            </li>
                                            <li>
                                                3.2.3 All order have a maximum AOI of 300 km
                                                <sup>2</sup>
                                            </li>
                                            <li>3.2.4 All orders must have a minimum length or width of 5 km.</li>
                                        </ul>
                                        <li>
                                            3.3. New Collect and Archive imagery will be delivered as natural colour
                                            composite (NCC) pan sharpened images in WGS84 projection in either GeoTIFF
                                            or ECW format
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                3.3.1 Where imagery contains more than one scene, these multiple scenes
                                                will be mosaiced to the best of endeavours.
                                            </li>
                                        </ul>
                                        <li>
                                            3.4 All New Collect orders will be delivered with less than 15% cloud cover.
                                            For the purposes of these Terms, cloud cover includes haze, shadows and
                                            smoke. If the New collection exceeds this requirement, the capture will be
                                            reflown at no additional cost and this may take additional time (see Clause
                                            4 below.)
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                {' '}
                                                If an area of the image that is covered is considered important or
                                                critical to You, that image is in specification regarding cloud cover
                                                and all imagery is supplied “as-is”. No refund will be considered if the
                                                supplied image is within these specifications.
                                            </li>
                                            <li>
                                                3.4.2 • Orders processed under SkyMapEXP service for urgent New Collect
                                                has no minimum cloud cover and will not be recollected. You accept the
                                                risk of urgent tasking and the risk of excess cloud or obscuration of
                                                areas of specific interest to You.
                                            </li>
                                            <li> 3.5 For Archive imagery:</li>
                                            <ul style={{ listStyle: 'none' }}>
                                                <li>
                                                    3.5.1 Image previews are an indication of final cloud cover and all
                                                    images supplied from Archive as “as-is”. Please ensure that archive
                                                    scenes are chosen carefully to minimise the impact of cloud cover on
                                                    your image.{' '}
                                                </li>
                                                <li>
                                                    {' '}
                                                    Imagery is not geo or ortho rectified and may contain locational
                                                    accuracy errors.
                                                </li>
                                            </ul>
                                            <li>
                                                3.6. Where orders are required that cannot be processed directly through
                                                the Service they must be processed manually by Soar (“Custom Order”).
                                                Any request for Custom Orders will be discussed directly with you.
                                                Custom Order may attract a surcharge.
                                            </li>
                                            <li>
                                                3.6. All orders are dependent on acceptance by the satellite provider
                                                and if an order is rejected by the Satellite Provider you will be
                                                informed via email.
                                            </li>
                                        </ul>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        4. COLLECTION AND DELIVERY TIMES
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            4.1. Initial collection attempts of any New Collect order will occur within
                                            5 business days of placement of your order.
                                        </li>
                                        <li>
                                            4.2. SkyMapEXP collect and of a New Collect order within 24 hours of receipt
                                            of your order on any Business Day except Friday.
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                4.2.1 A SkyMapEXP order placed outside the working hours of a Business
                                                Day will be processed the next available business day.
                                            </li>
                                            <li>
                                                4.2.2 A SkyMapEXP order placed on a Friday will be delivered the
                                                following Monday.
                                            </li>
                                        </ul>
                                        <li>
                                            4.3. If Collection fails to meet the cloud content requirements (See Clause
                                            3.3 above) recollection will be attempted at no additional cost. This may
                                            delay collection and depending on local cloud conditions may take up to
                                            several months.
                                        </li>
                                        <li>
                                            4.4. Orders for New Collect imagery will be delivered within 2 working days
                                            of collection.
                                        </li>
                                        <li>
                                            4.5. Orders for Archive imagery will typically be delivered within 2-5
                                            working days of order placement depending on size but sometimes may take
                                            longer.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        5. PURCHASING IMAGERY AND PAYMENTS
                                    </h5>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            5.1. All purchases will be paid for at the time of ordering and when you
                                            place your order, we will take an authorisation on your credit card. Once
                                            you order has been confirmed as being accepted by Soar and the Satellite
                                            Operator, Your credit card will be charged for the full amount.
                                        </li>
                                        <li>
                                            5.2. Where an order is declined by the Satellite Operator as being unable to
                                            be collected, Your credit card authorisation will be cancelled.
                                        </li>
                                        <li>
                                            5.3. You will be issued an invoice for your purchase which will be sent to
                                            your nominated email address.
                                        </li>
                                    </ul>
                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        6. IMAGE DELIVERY
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            6.1. All images purchased through this service will be available for
                                            download from your user account management panel under the ‘My Satellite
                                            Queue’ Tab on the Soar Platform.
                                        </li>
                                        <li>
                                            6.2. You may download any images that you have purchased for use in
                                            accordance with the End User License Agreement terms below.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        7. EXCLUDED FROM ORDERING OR RECEIVING DATA
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            7.1. You may not order or receive satellite imagery if You or a person on
                                            whose behalf You are making the purchase are:
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                7.1.1. from any country where such distribution or use would be contrary
                                                to law or regulation or which would subject Soar to any registration
                                                requirement within such jurisdiction or country; or
                                            </li>
                                            <li>
                                                1.1.1.from (or to a national or resident of) any country to which the
                                                Australia has embargoed goods; or{' '}
                                            </li>
                                            <li>
                                                7.1.3. on the U.S. Treasury Department's list of Specially Designated
                                                Nationals or the U.S. Commerce Department's Table of Deny Orders; or
                                            </li>
                                            <li>
                                                7.1.4. a person, including government, state or organisation, who is
                                                listed as under sanction through the authority of:
                                            </li>
                                            <ul style={{ listStyle: 'none' }}>
                                                <li>7.1.4.1. the United Nations Charter Act 1945 (Cth);</li>
                                                <li>7.1.4.2. the Autonomous Sanctions Act 2011 (Cth) </li>
                                                <li>7.1.4.3. the US OFAC consolidated list; or</li>
                                                <li>
                                                    7.1.4.4. any other statute or regulation which would make supply of
                                                    any Product illegal.
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        8. END USER LICENSE AGREEMENT{' '}
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            8.1. This END USER LICENSE AGREEMENT ("EULA") is made between the Satellite
                                            Operator and ("You") and the Satellite Operator is willing to provide a
                                            non-exclusive license of the Product to You.{' '}
                                        </li>
                                        <li>
                                            8.2. By accessing and using the Product, you acknowledge that you have read
                                            and agreed to be bound by:
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>8.2.1. downloading, accessing, installing or using the Product;</li>
                                            <li>8.2.2. producing any derivatives based on the Product;</li>
                                            <li>8.2.3. damaging or destroying the Products, and</li>
                                            <li>
                                                8.2.4. keeping the Product for 5 days after its delivery to you via
                                                Soar.{' '}
                                            </li>
                                        </ul>
                                        <li>
                                            8.3. Grant of License - Subject to Your compliance with the terms and
                                            conditions of the EULA, the Satellite Operator grants You a non-exclusive,
                                            non-transferable and limited license to:{' '}
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                8.3.1. install the Product on a computer or via Your Intranet or cloud
                                                service;
                                            </li>
                                            <li>8.3.2. use the Product for internal needs;</li>
                                            <li>8.3.3. modify the Product in order to create a VAP and / or DIP;</li>
                                            <li>8.3.4. use a VAP for your own internal needs </li>
                                            <li>
                                                8.3.5. grant any contractor and / or consultant to access the Product
                                                and/or VAP subject to a prior written agreement to make sure of their
                                                compliance with the same terms and conditions of the EULA and return of
                                                the same Product or VAP to You without retaining any copy{' '}
                                            </li>
                                            <li>
                                                8.3.6. post a sample image of a Product or VAP (without corresponding
                                                metadata or georeferencing) to publicly accessible Internet web sites
                                                provided that the size is no bigger than 1024 x 1024 pixels for
                                                non-Commercial Purposes and in a non-distributable/transferrable/
                                                disposable / rentable manner;{' '}
                                            </li>
                                            <li>
                                                8.3.7. print and hand out any sample image of a product or VAP at a size
                                                no bigger than 1024 x 1024 pixels for demonstration and non-Commercial
                                                Purposes and containing the following notice conspicuously displayed in
                                                connection with the Product or VAP work: ; and / or{' '}
                                            </li>
                                            <li>8.3.8. use, apply and allocate a DIP as you need.</li>
                                        </ul>
                                        <li>
                                            8.4. Limitations of License - You recognize and agree that the Product is
                                            the property of the Satellite Operator, You will not, or will not permit any
                                            sub-Reseller and consultant in accordance with 8.3.5 to{' '}
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                8.4.1. use or access the Products for any purpose not expressly
                                                permitted in Article 8.3 above., and/or
                                            </li>
                                            <li>
                                                8.4.2. change or remove any trademark, logo or ownership signs over or
                                                inside the Product.
                                            </li>
                                        </ul>
                                        <li>8.5. IP OWNERSHIP</li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                8.5.1. You acknowledge that IP ownership rights to the Product belong to
                                                the Satellite Operator
                                            </li>
                                            <li>
                                                8.5.2. The Products are protected by local and international copyright
                                                laws.
                                            </li>
                                        </ul>
                                        <li>8.6. WARRANTIES AND LIABILITIES</li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                8.6.1. The Satellite Operator warrants to own full rights of the
                                                Products in connection with the terms and conditions of this EULA;
                                            </li>
                                            <li>
                                                8.6.2. As for the complexity of the Products, Soar and the Satellite
                                                Operator warrants the resolution and cloud percentage consistent with
                                                this Agreement but does not warrant the Products must be free of fault
                                                and errors in Your applications or meet Your plan.
                                            </li>
                                            <li>
                                                8.6.3. The Satellite Operator will, at its own expense and as its sole
                                                obligation, replace any defective Product reported by You and accepted
                                                by Soar and the Satellite Operator. A request for the replacement shall
                                                be notified by You to Soar within seven (7) days after you receive the
                                                Product, otherwise Soar and the Satellite Operator will deem your
                                                acceptance of the Product and will not assume any liability hereof.{' '}
                                            </li>
                                            <li>
                                                8.6.4. In no event will Soar or the Satellite Operator or its staff in
                                                connection with development and (or) production and (or) delivery of the
                                                Product be liable for any claim, damage or loss caused or incurred by
                                                You, including but not limited to any incidental, consequential,
                                                special, exemplary, or indirect damage arising from, or relating to, the
                                                EULA or the Product. No lawsuit shall be raised for this damage.{' '}
                                            </li>
                                            <li>
                                                8.6.5. Soar or its staff’s total cumulative liability in connection with
                                                development and (or) production and (or) delivery of the Product, if
                                                any, whether in contract or tort or otherwise, will not exceed the
                                                amount of fees paid to Soar for the Product.
                                            </li>
                                        </ul>
                                        <li>8.7. OTHER MATTERS</li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                8.7.1. This EULA shall commence as of the acceptance of any order placed
                                                with Soar and shall remain in effect for an indefinite period.{' '}
                                            </li>
                                            <li>
                                                8.7.2. Soar may obtain all the indemnities by means of this EULA or
                                                related laws, or terminate immediately upon a written notice to the You,
                                                if You breach any material obligation under the EULA and fails to cure
                                                the breach to the notifying’s Soar’s satisfaction. In this condition,
                                                You shall not claim for any refund upon the termination of the EULA and
                                                return the Product and VAP to the Satellite Operator upon the
                                                termination.{' '}
                                            </li>
                                            <li>
                                                8.7.3. You cannot transfer its rights and obligations under this EULA,
                                                in part or in whole, without the prior written consent of the Satellite
                                                Operator;{' '}
                                            </li>
                                            <li>
                                                8.7.4. If any provision of this EULA is held to be non-effective, such
                                                provision will be changed and interpreted to accomplish the objectives
                                                of such provision to the greatest extent under applicable law, and the
                                                remaining provisions will continue in full force and effect;
                                            </li>
                                            <li>
                                                8.6.5. This EULA will be governed and interpreted by the laws of
                                                Australia. All disputes arising out of or in connection with this EULA
                                                shall be finally settled in A court of competent jurisdiction in
                                                Australia.{' '}
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Skymap50TermsAndConditionsContainer>
    );
};

export default Skymap50TermsAndConditions;

const Skymap50TermsAndConditionsContainer = styled.div`
    padding-top: 100px;
    color: white !important;
    -webkit-text-fill-color: white;
    text-align: left !important;

    h1 {
        margin: 10px 75px;
        font-size: 1.75rem;
    }

    h5 {
        color: rgb(238, 217, 38) !important;
        -webkit-text-fill-color: rgb(238, 217, 38);
        text-align: left !important;

        :first-child {
            color: rgba(255, 255, 255, 0.8);
            -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
            font-size: 14px;
            text-align: center !important;
        }
    }

    li,
    ul {
        color: white !important;
        -webkit-text-fill-color: white;
        font-size: 16px !important;
        font-size: 1rem;
    }

    .privacy-table {
        margin: 10px 0px;
        tr > td {
            :first-child {
                color: rgb(238, 217, 38) !important;
                -webkit-text-fill-color: rgb(238, 217, 38) !important;
                vertical-align: top;
                width: 170px;
            }
        }
    }
`;
