import { useEffect } from 'react';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';

const Maintenance = () => {
    useEffect(() => {
        Analytics.Event('Maintenance', 'Displayed maintenance page');
    }, []);

    return (
        <DivContainer>
            <DivContent>
                <Title>We'll be back soon!</Title>
                <p>Sorry for the inconvenience but we're performing site maintenance at the moment.</p>
                <p>We'll be back online shortly!</p>
                <p>The Pics from Space Team</p>
                <Logo src="/assets/logos/pics-from-space-logo-cream@2x.png" />
            </DivContent>
        </DivContainer>
    );
};

export default Maintenance;

const DivContainer = styled.div`
    background-image: url(/assets/PfS_UI_bg_pixellated_sepia.jpg);
    background-size: cover;
    min-height: 100vh;
    padding: 50px;
    color: ${(props) => props.theme.primary.color};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const DivContent = styled.div`
    display: block;
    text-align: center;
    & p {
        color: ${(props) => props.theme.primary.color};
        font-size: 20px;
    }
`;

const Title = styled.div`
    color: ${(props) => props.theme.primary.color};
    font-size: 40px;
    margin-bottom: 25px;
`;

const Logo = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 40px;
`;
