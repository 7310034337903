import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMapZoom } from '../../../../store/Map/selectors';

const MIN_ZOOM = 4.5;
const MAX_ZOOM = 10.0;

const SatelliteZoomInHint = () => {
    const mapZoom = useSelector(selectMapZoom);
    const [zoomProgress, setZoomProgress] = useState(0);

    useEffect(() => {
        if (mapZoom) {
            const percentage = Math.max(0, ((mapZoom - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)) * 100);
            setZoomProgress(percentage);
        }
    }, [mapZoom]);

    return (
        <Container>
            <ProgressBar>
                <ProgressBarPercentage percentage={zoomProgress}>
                    <ProgressBarHintText>Zoom in to begin search</ProgressBarHintText>
                </ProgressBarPercentage>
            </ProgressBar>
        </Container>
    );
};

export default SatelliteZoomInHint;

const Container = styled.div`
    margin: 60px 12px;
    height: 100%;
    border-radius: ${(props) => props.theme.borderRadius};
`;

const ProgressBar = styled.div`
    height: 76px;
    position: relative;
    background: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.secondary.text};
    border-radius: ${(props) => props.theme.borderRadius};
`;

const ProgressBarPercentage = styled.div<{ percentage: number }>`
    background: rgba(255, 255, 255, 0.3);
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100%;
    width: ${(props) => props.percentage}%;
    border-top-left-radius: ${(props) => props.theme.borderRadius};
    border-bottom-left-radius: ${(props) => props.theme.borderRadius};
    transition: width 0.25s linear;
`;

const ProgressBarHintText = styled.span`
    display: inline-block;
    position: absolute;
    color: ${(props) => props.theme.secondary.text};
    width: 100%;
    margin-top: 12px;
    left: 0;
`;
