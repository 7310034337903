import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

/**
 *  usePageVisitCount
 *  Takes in routes and number of times a user can visit routes before returning a boolean.
 *
 * @param routes string[] - add routes that are to be watched
 * @param maxRouteVisitCount number - sets number of expected visits before returning true
 * @returns boolean
 */

export const usePageVisitCount = (routes: string[], maxRouteVisitCount: number) => {
    const [visitedCount, setVisitedCount] = useState<number>(0);
    const [hasHitVisitCount, setHasHitVisitCount] = useState<boolean>(false);
    const [currentPath, setCurrentPath] = useState<string>('');

    const location: Location = useLocation();

    useEffect(() => {
        if (visitedCount === maxRouteVisitCount) {
            setHasHitVisitCount(true)
        }
    }, [maxRouteVisitCount, visitedCount]);

    useEffect(() => {
        const checkingLocation = (location: Location, pathname: string) => {
            const isValidPathname = location.pathname.includes(pathname) ? true : false;
            const isValidLocation = location.pathname.replace(pathname, '').length !== 0 ? true : false;
            const path = location.pathname;
            if (isValidPathname && isValidLocation && path !== currentPath) {
                setVisitedCount(visitedCount + 1)
                setCurrentPath(path)
            }
        };

        if (location && !hasHitVisitCount) {
            routes.map((path) => {
                if (!path) return;
                checkingLocation(location, path);
            })
        }
    }, [currentPath, hasHitVisitCount, location, routes, visitedCount]);

    return { hasHitVisitCount };
};
