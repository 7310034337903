import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from './store/store';
import { history } from './store/history';
import HttpsRedirect from 'react-https-redirect';
import ConfiguredRoot from './root-configured';
import ErrorBoundary from './components/Error/error-boundary';
import { theme } from './styles/theme';
import { BrowserRouter } from 'react-router-dom';

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

const Root: React.FC = (_) => {
    return (
        <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
            <ErrorBoundary>
                <Provider store={store}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            <ConfiguredRoot />
                        </ThemeProvider>
                    </BrowserRouter>
                </Provider>
            </ErrorBoundary>
        </HttpsRedirect>
    );
};

export default Root;
