import React from 'react';
import styled from 'styled-components';

interface DrawerProps {
    children: React.ReactNode;
}

const Drawer = (props: DrawerProps) => {
    return <DrawerContainer>{props.children}</DrawerContainer>;
};

export default Drawer;

export const DrawerContainer = styled.div`
    position: absolute;
    border-radius: 6px;
    background: ${(props) => props.theme.primary.color};
    z-index: 1000;
    left: 12px;
    top: 84px;
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    width: 286px;
`;
