import Api from './api';
import {
    BillingInfoDTO,
    DomainPermissionDTO,
    ListingDTO,
    Permission,
    PurchasedInfo,
    PurchasedItemInfo,
    UploadCredentialsDTO,
    UserDTO,
} from './model';
import { OssUploader } from './oss-uploader';
import Constants from '../constants';
import UserHelper from '../lib/user-helper';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiUser extends Api {
    public static getMyUserProfile(): Promise<UserDTO> {
        return this.axios
            .get('/v1/user')
            .then((res) => {
                const profile: UserDTO = res.data;

                let domains: DomainPermissionDTO[] = [];
                if (res.data.domains) {
                    domains = res.data.domains.map((d) => {
                        const dp: DomainPermissionDTO = {
                            domain: d.domain,
                            permission: this.apiPermissionsToEnum(d.permission.toString()),
                        };
                        return dp;
                    });
                }
                profile.domains = domains;
                return profile;
            })
            .catch((error) => {
                //most likely an authentication problem, invalidate token
                UserHelper.resetToken();
                return Promise.reject(error);
            });
    }

    public static getUserListings(): Promise<ListingDTO[]> {
        return this.axios.get('/v1/user/listings', { cache: false }).then((res) => {
            return res.data.listings;
        });
    }

    public static userPermissionForDomain(user: UserDTO): Permission | undefined {
        const subdomainName = this.getSubdomain();
        if (!user.domains) return undefined;
        const match = user.domains.find((d) => d.domain === subdomainName);
        return match ? match.permission : undefined;
    }

    public static getAvatarUploadCredentials(filename: string): Promise<UploadCredentialsDTO> {
        return this.axios.get('/v1/user/avatar/' + filename, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static updateMyProfile(
        name?: string,
        bio?: string,
        twitterUrl?: string,
        facebookUrl?: string,
        instagramUrl?: string,
        websiteUrl?: string,
        linkedinUrl?: string,

        firstName?: string,
        lastName?: string,
        longDescription?: string,

        avatar?: File,
        avatarUrl?: string
    ): Promise<any> {
        const data = {
            name: name,
            bio: bio,
            twitterUrl: twitterUrl,
            facebookUrl: facebookUrl,
            instagramUrl: instagramUrl,
            websiteUrl: websiteUrl,
            linkedinUrl: linkedinUrl,

            firstName: firstName,
            lastName: lastName,
            longDescription: longDescription,

            avatarUrl: avatarUrl,
        };

        if (avatar) {
            return this.getAvatarUploadCredentials(avatar.name).then((credentials) => {
                const ossUploader = new OssUploader(credentials);
                return ossUploader.uploadFileToStorage(avatar, credentials.filename).then(() => {
                    const avatarUrl = Constants.AVATAR_URL + '/' + credentials.filename;
                    data['avatarUrl'] = avatarUrl;
                    return this.axios.put('/v1/user', data);
                });
            });
        } else {
            return this.axios.put('/v1/user', data);
        }
    }

    public static signup(
        email: string,
        password: string,
        name: string,
        firstName: string,
        lastName: string,
        newsletterSignUp: boolean
    ): Promise<string> {
        const data = {
            email: email,
            password: password,
            name: name,
            firstName: firstName,
            lastName: lastName,
            newsletterSignUp: newsletterSignUp,
        };
        return this.axios.post('/v1/user', data).then((res) => {
            return res.data.idToken;
        });
    }

    public static deleteUser(id: any): Promise<string> {
        return this.axios.delete('/v1/user', id).then((res) => {
            return res.data;
        });
    }

    public static requestPasswordResetPromise(email: string): Promise<any> {
        return this.axios.get(`/v1/user/reset_password/?email=${encodeURIComponent(email)}`, { cache: false });
    }

    public static resetPasswordPromise(id: string, email: string, password?: string): Promise<any> {
        return this.axios.post('/v1/user/reset_password', {
            email: email,
            password: password,
            id: id,
        });
    }

    public static putBillingInfo(billingInfo: BillingInfoDTO): Promise<any> {
        return this.axios.put('/v1/user/billing', billingInfo).then((res) => {
            return res.data;
        });
    }

    public static getUserProfileByEth(wallet: string): Promise<UserDTO> {
        return this.axios.get('/v1/user/eth/' + wallet, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static getUserProfile(userId: string): Promise<UserDTO> {
        return this.axios.get('/v1/users/' + userId, { cache: true }).then((res) => {
            return res.data;
        });
    }

    public static getMyPurchases(): Promise<PurchasedInfo[]> {
        return this.axios.get('/v1/user/purchases', { cache: false }).then((result) => {
            const purchaseResults: PurchasedInfo[] = result.data.purchases.map((data) => {
                const purchaseItems: PurchasedItemInfo[] = data.items.map((item) => {
                    const amount = item.currency === 'USD' ? item.amount / 1000 : item.amount / (18 * 10);

                    const purchaseItemInfo: PurchasedItemInfo = {
                        id: item.id,
                        amount: amount,
                        currency: item.currency,
                        ownerId: item.ownerId,
                        paidAt: new Date(item.paidAt * 1000),
                        createdAt: new Date(item.createdAt * 1000),
                        purchaseId: item.purchaseId,
                        type: item.type,
                        typeData: item.typeData,
                        listingId: item.listingId ? item.listingId.toString() : '',
                    };
                    return purchaseItemInfo;
                });

                const purchaseInfo: PurchasedInfo = {
                    id: data.id,
                    amount: data.amount,
                    currency: data.currency,
                    paidAt: new Date(data.paidAt * 1000),
                    createdAt: new Date(data.createdAt * 1000),
                    receiptUrl: data.receiptUrl,
                    items: purchaseItems,
                };

                return purchaseInfo;
            });

            const orderPurchaseByDate = purchaseResults.sort((a, b) => b.paidAt.getTime() - a.paidAt.getTime());
            return orderPurchaseByDate;
        });
    }

    public static getSMSVerificationCode(mobile: string, countryCode: string, geometry: string): Promise<any> {
        const twilioNumber = '+'.concat(mobile);
        return this.axios.post('/v1/sms', {
            mobile: twilioNumber,
            countryCode: countryCode,
            geometry: geometry,
        });
    }

    public static validateSMSVerificationCode(
        mobile: string,
        verificationCode: string,
        geometry: string,
        smsId: string
    ): Promise<any> {
        const twilioNumber = '+'.concat(mobile);
        return this.axios.post('/v1/sms/check', {
            mobile: twilioNumber,
            code: verificationCode,
            geometry: geometry,
            smsId: smsId,
        });
    }
}
