import Api from './api';
import { AnalyticsAction, AnalyticsNote, SatelliteProvider } from './model';

export default class ApiAnalytics extends Api {

    public static postAnalytics(
        supplier: string,
        action: AnalyticsAction,
        product: string,
        geometryWKT: string,
        id?: string,
        previewUrl?: string
    ): Promise<void> {
        const data = {
            provider: supplier,
            action: action,
            product: product,
            geometryWKT: geometryWKT,
            id: id,
            previewUrl: previewUrl,
        };
        return (
            this.axios
                .post('/v1/analytics', data)
                .then((res) => {
                    return res.data;
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }

    public static postAnalyticsSatellite(
        url: string,
        provider: SatelliteProvider,
        satellite: string,
        action: AnalyticsAction
    ): Promise<void> {
        const data = {
            url: url,
            provider: provider.toString(),
            satellite: satellite,
            action: action,
        };
        return (
            this.axios
                .post('/v1/analytics/satellite', data)
                .then((res) => {
                    return res.data;
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }

    public static postAnalyticsListing(
        action: AnalyticsAction,
        note: AnalyticsNote,
        listingId: number,
        isTask?: boolean
    ): Promise<void> {
        const data = {
            action: action, // view or download
            note: note, //  description of event
            id: listingId,
            isTask,
        };
        return (
            this.axios
                .post('/v1/analytics/listing', data)
                .then((res) => {
                    return res.data;
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }
}
