import { LatLngBounds } from 'leaflet';
import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';
import { SatelliteArchiveImageryDTO } from '../../../api/api-supplier';

interface SatelliteArchiveStore {
    isSatelliteBoxSelectActive: boolean;
    satelliteAOI: LatLngBounds | undefined;
    satelliteSelectedFeature: SatelliteArchiveImageryDTO | undefined;
    satelliteHighlightedFeature: SatelliteArchiveImageryDTO | undefined;
    satelliteZIndex: { [sceneId: number]: number };
    satelliteAOIError: Error | undefined;
}

const initialState: SatelliteArchiveStore = {
    isSatelliteBoxSelectActive: false,
    satelliteAOI: undefined,
    satelliteSelectedFeature: undefined,
    satelliteHighlightedFeature: undefined,
    satelliteZIndex: {},
    satelliteAOIError: undefined,
};

export default (state: SatelliteArchiveStore = initialState, action: Action): SatelliteArchiveStore => {
    switch (action.type) {
        case at.SATELLITE_BEGIN_BOX_SELECT:
            return {
                ...state,
                isSatelliteBoxSelectActive: true,
            };

        case at.SATELLITE_END_BOX_SELECT:
            return {
                ...state,
                isSatelliteBoxSelectActive: false,
            };

        case at.SATELLITE_SELECT_AOI:
            return {
                ...state,
                isSatelliteBoxSelectActive: true,
                satelliteAOI: action.payload,
            };

        case at.SATELLITE_RESET_AOI:
            return {
                ...state,
                satelliteAOI: undefined,
                isSatelliteBoxSelectActive: false,
            };

        case at.SATELLITE_SET_FEATURE_ACTIVE:
            console.log('Action');
            return {
                ...state,
                satelliteSelectedFeature: action.payload,
            };

        case at.SATELLITE_SET_FEATURE_INACTIVE:
            return {
                ...state,
                satelliteSelectedFeature: undefined,
            };

        case at.SATELLITE_RESET_ACTIVE_FEATURES:
            return {
                ...state,
                satelliteSelectedFeature: undefined,
            };

        case at.SATELLITE_FEATURE_MOUSE_OVER:
            return {
                ...state,
                satelliteHighlightedFeature: action.payload,
            };

        case at.SATELLITE_FEATURE_MOUSE_OUT:
            return {
                ...state,
                satelliteHighlightedFeature: undefined,
            };

        case at.SATELLITE_REORDER_Z_INDEX:
            return {
                ...state,
                satelliteZIndex: action.payload,
            };
        case at.SATELLITE_SET_AOI_ERROR:
            return {
                ...state,
                satelliteAOIError: action.payload,
            };

        default:
            return state;
    }
};
