import { ReactNode } from 'react';
import { Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';

interface SoarModalProps {
    title?: string | JSX.Element;
    isOpen: boolean;
    toggle?: () => void;
    width?: string;
    marginTop?: string;
    onClose?: () => void;
    children?: ReactNode;
}

const Modal = (props: SoarModalProps) => {
    const { title, isOpen, toggle, width, marginTop, onClose, children } = props;
    return (
        <StyledModal isOpen={isOpen} toggle={toggle} zIndex={100000} width={width} margin={marginTop}>
            <StyledHeader>
                {(toggle || onClose) && (
                    <StyledCloseIcon
                        onClick={() => {
                            if (toggle) {
                                toggle();
                            }
                            if (onClose) {
                                onClose();
                            }
                        }}
                    />
                )}
                {title}
            </StyledHeader>
            <StyledBody>{children}</StyledBody>
        </StyledModal>
    );
};

export default Modal;

// eslint-disable-next-line
const StyledModal = styled<any>(ReactstrapModal)`
    width: ${(props) => (props.width ? props.width : '')};
    max-width: ${(props) => (props.width ? props.width : '500px')};
    margin-top: ${(props) =>
        props.margin ? props.margin : '100px'}; // this is set but possibly needs to be percent for mobile?
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    background-color: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    margin-bottom: 20px;

    div.modal-content {
        background-color: transparent !important;
        margin: 0 auto;
    }

    @media (max-width: 650px) {
        width: 100% !important;
        margin-top: 50px; // this is set but possibly needs to be percent for mobile?
    }
`;

const StyledHeader = styled(ModalHeader)`
    color: ${(props) => props.theme.secondary.color};
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};

    h5 {
        color: ${(props) => props.theme.secondary.color};
        font-size: 25px;
        width: 100%;
        text-align: left;

        span {
            color: ${(props) => props.theme.secondary.color};
        }
        span.small {
            font-size: 20px;
        }
    }

    span {
        color: ${(props) => props.theme.secondary.color};
        font-size: 25px;
    }
`;

const StyledBody = styled.div`
    color: ${(props) => props.theme.primary.hover};

    span {
        color: ${(props) => props.theme.secondary.color};
    }
`;

const StyledCloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-top: 10px;
    cursor: pointer;
    float: right;
`;

export const StyledModalBody = styled(ModalBody)`
    color: ${(props) => props.theme.primary.text};
    font-size: 16px;

    p {
        color: ${(props) => props.theme.primary.text};
    }
`;

export const StyledModalFooter = styled(ModalFooter)<{ borderTop?: boolean }>`
    border-top: ${(props) => props.theme.secondary.color};
    justify-content: right;
    padding-bottom: 20px;

    p {
        color: ${(props) => props.theme.primary.text};
    }
`;
