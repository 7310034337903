import { Table } from 'reactstrap';
import styled from 'styled-components';
import { OrderDTO } from '../../../api/model';
import OrderItem from './order-item';

interface ProfileTasksListProps {
    orders: OrderDTO[];
}

const OrderList = (props: ProfileTasksListProps) => {
    const { orders: filteredTasks } = props;
    return (
        <TasksTable>
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Type</th>
                    <th>Order Number</th>
                    <th>Date</th>
                    <th>Size</th>
                    {/* <th>Discount</th> */}
                    <th>Paid</th>
                    {/* <th>Total Price</th> */}
                    <th>Receipt</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {filteredTasks.map((task) => {
                    return <OrderItem key={`task-item-${task.id}`} task={task} />;
                })}
            </tbody>
        </TasksTable>
    );
};

export default OrderList;

const TasksTable = styled(Table)`
    background-color: transparent;
    color: ${(props) => props.theme.primary.text};

    th {
        color: ${(props) => props.theme.primary.text} !important;
        border-top: none;
        border-bottom: none !important;
        font-size: 16px;
    }

    tbody {
        font-size: 14px;
    }
`;
