import React, { useEffect, useState } from 'react';

interface ImagePreviewProps {
    previewUrl: string;
}

const ImagePreview = ({ previewUrl }: ImagePreviewProps) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(false);
    }, [previewUrl]);

    if (isError || !previewUrl) {
        return (
            <div className="unavailable">
                <img src="/assets/image-unavailable/preview-not-available.svg" alt="" />
            </div>
        );
    } else {
        return (
            <div>
                <img src={previewUrl} alt="" onError={() => setIsError(true)} />
            </div>
        );
    }
};

export default ImagePreview;
