import React, { useEffect } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { CSWTaskStatus, AnalyticsAction, AnalyticsNote, OrderDTO, OrderDetailsDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import ApiOrder from '../../../api/api-order';
import { StyledButton } from 'components/Shared/Buttons/styled-button';
import profileIconUrl from '../profile-icon-url';
import OrderStatus from './order-status';
import PriceWithCurrency from 'components/Shared/price-with-currency';
import { useNavigate } from 'react-router-dom';

const moment = require('moment');

interface OrderItemProps {
    task: OrderDTO;
}

const OrderItem = (props: OrderItemProps) => {
    const { task } = props;
    const { id } = task;

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [order, setOrder] = React.useState<OrderDetailsDTO | undefined>(undefined);

    const navigate = useNavigate();
    const handleClickView = () => {
        Analytics.Event('My Satellite Queue', 'Clicked to view satellite task', id);
        navigate(`/task/${id}`);
    };

    useEffect(() => {
        if (dropdownOpen && !order) {
            ApiOrder.getOrderDetails(id).then(setOrder);
        }
    }, [dropdownOpen, order, id]);

    const handleClickDownload = (taskFileId: string) => {
        Analytics.Event('My Satellite Queue', 'Clicked to download satellite task', taskFileId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.DOWNLOAD, AnalyticsNote.DASHBOARD_TASK, id, true);
        ApiOrder.getOrderDownloadLink(id, taskFileId)
            .then((downloadLink) => {
                const link = document.createElement('a');
                link.href = downloadLink;
                link.download = 'true';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => console.log(err));
    };

    const typeStringForProvider = (type: string): string => {
        if (type === 'CSW' || type === 'CSW_CUSTOM') return 'New Collection';
        if (type === 'CSW_ARCHIVE' || type === 'CSW_CUSTOM_ARCHIVE') return 'Archival Imagery';
        if (type === 'CSW_CUSTOM_URGENT') return 'Urgent Collection';
        if (type === 'CSW_CUSTOM_STEREO') return 'Stereo Collection';
        if (type === 'CSW_CUSTOM_MONITORING') return 'Monitoring Collection';
        return 'Collection';
    };

    const handleReceiptClicked = (task: OrderDTO) => {
        const receiptUrl = task.payment.receiptUrl ? task.payment.receiptUrl : task.payment.invoiceUrl;
        if (receiptUrl) {
            window.open(receiptUrl, 'popUpWindow', 'height=600,width=700,left=10,top=10,scrollbars=yes,menubar=no');
            Analytics.Event('My Satellite Queue', 'Clicked to view satellite order receipt');
        }
    };

    const handleClickedInvoice = () => {
        const invoiceUrl = task.payment.invoiceUrl ? task.payment.invoiceUrl : task.payment.receiptUrl;
        if (invoiceUrl) {
            window.open(invoiceUrl, 'popUpWindow', 'height=600,width=700,left=10,top=10,scrollbars=yes,menubar=no');
            Analytics.Event('My Satellite Queue', 'Clicked to view satellite order invoice');
        }
    };

    return (
        <>
            <TaskListTr>
                <TaskImageTd>
                    <div>{task.status && <OrderStatus status={task.status} />}</div>

                    <img src={profileIconUrl.satelliteImageUrl} />
                </TaskImageTd>
                <td>{typeStringForProvider(task.provider)}</td>
                <td>{task.orderNumber}</td>
                <td>{moment(task.created).format('MMM Do YYYY')}</td>
                <td>{task.effectiveAreaInKm?.toFixed(2)} km²</td>
                <td>
                    {task.payment.paidByStripe !== 0 && (
                        <PriceWithCurrency value={task.payment.paidByStripe / 100} currency={task.payment.currency} />
                    )}
                </td>
                <td>
                    {task.payment.receiptUrl ? (
                        <TaskButton onClick={() => handleReceiptClicked(task)}>
                            <TaskButtonDetails className="receipt">
                                <span>
                                    <i className="fa fa-external-link" />
                                </span>
                                RECEIPT
                            </TaskButtonDetails>
                        </TaskButton>
                    ) : (
                        'NO RECEIPT'
                    )}
                </td>
                <td>
                    <TaskButtons>
                        {task.status === CSWTaskStatus.DELIVERED && (
                            <TaskButton onClick={handleClickView}>
                                <TaskButtonDetails className="view">
                                    <i className="fa fa-eye " />
                                    <span>VIEW</span>
                                </TaskButtonDetails>
                            </TaskButton>
                        )}

                        {task.status === CSWTaskStatus.DELIVERED && (
                            <TaskButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                <TaskDropdownToggle caret>
                                    <i className="fa fa-download" />
                                    <span>DOWNLOAD</span>
                                </TaskDropdownToggle>
                                <DropdownMenu>
                                    {!order ? (
                                        <DropdownItem>
                                            <Spinner />
                                        </DropdownItem>
                                    ) : (
                                        order.files.map((item) => {
                                            return (
                                                <DropdownItem
                                                    key={`task_file_${item.id}`}
                                                    onClick={() => handleClickDownload(item.id)}
                                                >
                                                    {item.type}
                                                </DropdownItem>
                                            );
                                        })
                                    )}
                                </DropdownMenu>
                            </TaskButtonDropdown>
                        )}
                        {task.status !== CSWTaskStatus.REJECTED && task.payment.invoiceUrl && (
                            <TaskButton onClick={handleClickedInvoice}>
                                <TaskButtonDetails className="invoice">
                                    <i className="fa fa-external-link" />
                                    <span>INVOICE</span>
                                </TaskButtonDetails>
                            </TaskButton>
                        )}
                    </TaskButtons>
                    {task.status === CSWTaskStatus.REJECTED && task.noteRejected ? (
                        <TaskRejectionNotes>{task.noteRejected}</TaskRejectionNotes>
                    ) : null}
                </td>
            </TaskListTr>
        </>
    );
};

export default OrderItem;

const TaskListTr = styled.tr`
    color: ${(props) => props.theme.primary.text};

    td {
        color: ${(props) => props.theme.primary.text};
        vertical-align: inherit;
    }
`;

const TaskImageTd = styled.td`
    padding: 10px;
    width: 50px !important;

    img {
        width: 40px;
    }
`;

const TaskButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

const TaskButton = styled(StyledButton)`
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;

    .view,
    .receipt,
    .invoice {
        @media only screen and (max-width: 1350px) {
            span {
                display: none;
            }
        }
    }
`;

const TaskButtonDetails = styled.div`
    display: flex;
    flex-direction: row;
    width: max-content;
    i {
        color: ${(props) => props.theme.primary.color};
        margin: 3px;
    }

    span {
        color: ${(props) => props.theme.primary.color};
    }
`;

const TaskDropdownToggle = styled(DropdownToggle)`
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    display: block;
    background-color: ${(props) => props.theme.secondary.color} !important;
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.primary.color};
    -webkit-text-fill-color: ${(props) => props.theme.primary.color};

    i {
        margin-right: 3px;
    }

    span {
        font-size: 12px !important;
        color: ${(props) => props.theme.primary.color} !important ;
    }

    :hover {
        background-image: none !important;
        transition: none !important;
    }

    ::after {
        color: ${(props) => props.theme.primary.color};
        font-size: 12px;
    }
`;

const TaskRejectionNotes = styled.div`
    overflow: auto;
    color: ${(props) => props.theme.primary.text};

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px;
        -webkit-box-shadow: inset 0 0 6px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.secondary.color};
        border-radius: 4px;
    }
`;

const TaskButtonDropdown = styled(ButtonDropdown)`
    span {
        color: ${(props) => props.theme.primary.text};
    }
`;
