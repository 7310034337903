import { LatLng, LatLngBounds } from 'leaflet';
import { actionTypes as at } from './constants';
import { BasemapKey } from './model';
import { actionShoppingCartOpenDrawer } from 'store/ShoppingCart/actions';
import {
    actionSatelliteSelectAOI,
    actionSatelliteEndBoxSelect,
    actionSatelliteMouseOut,
    actionSatelliteSetFeatureInactive,
    actionSatelliteSetAOIError,
} from './SatelliteArchive/actions';

export const actionFlyTo = (position: LatLng | LatLngBounds, zoom?: number, immediately?: boolean) => {
    return {
        type: at.FLY_TO,
        payload: {
            position: position,
            zoom: zoom,
            immediately: immediately ? immediately : false,
        },
    };
};

export const actionFlyToOnMap = (location: LatLng | LatLngBounds | undefined) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FLY_TO_POSITION,
            payload: location,
        });
    };
};

export const actionFlyToInvalidate = () => {
    return {
        type: at.FLY_TO_POSITION_INVALIDATE,
    };
};

export const actionMapLoaded = () => {
    return {
        type: at.MAP_LOADED,
    };
};

export const actionMapUnloaded = () => {
    return {
        type: at.MAP_UNLOADED,
    };
};

export const actionSetMapBounds = (bounds: LatLngBounds) => {
    return {
        type: at.SET_MAP_BOUNDS,
        payload: bounds,
    };
};

export const actionSetMapZoom = (zoom: number | undefined) => {
    return {
        type: at.SET_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionSetBasemap = (basemapKey: BasemapKey) => {
    return {
        type: at.SET_BASEMAP,
        payload: basemapKey,
    };
};

export const actionClearMap = () => {
    return (dispatch, getState) => {
        dispatch(actionSatelliteSelectAOI(undefined));
        dispatch(actionSatelliteSetAOIError(undefined));
        dispatch(actionSatelliteEndBoxSelect());
        dispatch(actionSatelliteMouseOut());
        dispatch(actionShoppingCartOpenDrawer(undefined));
        dispatch(actionSatelliteSetFeatureInactive());
    };
};
