import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { Input } from 'reactstrap';
import styled from 'styled-components';

interface OpacitySliderProps {
    onChange: (value: number) => void;
}

const OpacitySlider = ({ onChange }: OpacitySliderProps) => {
    const map = useMap();
    const [opacity, setOpacity] = useState(100);

    return (
        <SliderContainer
            className="opacity-slider"
            onMouseOver={() => map.dragging.disable()}
            onMouseOut={() => map.dragging.enable()}
        >
            <SliderText>
                Transparency:
                <SliderValue>{opacity.toFixed()}%</SliderValue>
            </SliderText>

            <SliderInputContainer>
                <Slider
                    type="range"
                    value={opacity}
                    step={1}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value);
                        setOpacity(opacity);
                        onChange(opacity);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default OpacitySlider;

const SliderContainer = styled.div`
    position: fixed;
    bottom: 45px;
    left: 10px;
    height: 45px;
    background: rgb(255 255 255 / 25%);
    z-index: 1000;
    width: 130px;
    padding: 5px 10px;
    border-radius: 5px;
`;

const SliderText = styled.div`
    vertical-align: center;
    color: white;
    font-size: 11px;
    margin: 0;
    padding: 0;
    text-shadow: 1px 0 0 #808080, 0 -1px 0 #808080, 0 1px 0 #808080, -1px 0 0 #808080;
`;

const SliderValue = styled.span`
    color: white;
    margin: 0px 5px;
    padding: 0;
`;

const SliderInputContainer = styled.div`
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #e9d7ad ${(props) => 100 - props.value}%
    );
    height: 3px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 11px;

    &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #e9d7ad;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #e9d7ad;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }
`;
