import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { LatLng, LatLngBounds } from 'leaflet';
import { BasemapKey } from './model';

interface StoreMap {
    mapLoaded: boolean;
    mapPosition?: LatLng | LatLngBounds;
    mapPositionZoom: number | undefined;
    shouldMapPositionUpdateImmediately: boolean;
    mapBounds: LatLngBounds | undefined;
    mapZoom: number | undefined;
    selectedBasemap: BasemapKey;
}

const initialAppState: StoreMap = {
    mapLoaded: false,
    mapPosition: undefined,
    mapPositionZoom: undefined,
    shouldMapPositionUpdateImmediately: false,
    mapBounds: undefined,
    mapZoom: undefined,
    selectedBasemap: 'Google Street',
};

export default (state: StoreMap = initialAppState, action: Action): StoreMap => {
    switch (action.type) {
        case at.MAP_LOADED:
            return {
                ...state,
                mapLoaded: true,
            };

        case at.MAP_UNLOADED:
            return {
                ...state,
                mapLoaded: false,
            };

        case at.FLY_TO:
            return {
                ...state,
                mapPosition: action.payload.position,
                mapPositionZoom: action.payload.zoom,
                shouldMapPositionUpdateImmediately: action.payload.immediately,
            };

        case at.FLY_TO_POSITION:
            return {
                ...state,
                mapPosition: action.payload,
            };

        case at.FLY_TO_POSITION_INVALIDATE:
            return {
                ...state,
                mapPosition: undefined,
                mapPositionZoom: undefined,
                shouldMapPositionUpdateImmediately: false,
            };

        case at.SET_MAP_BOUNDS:
            return {
                ...state,
                mapBounds: action.payload,
            };

        case at.SET_MAP_ZOOM:
            return {
                ...state,
                mapZoom: action.payload,
            };

        case at.SET_BASEMAP:
            return {
                ...state,
                selectedBasemap: action.payload,
            };

        default:
            return state;
    }
};
