import { AppState } from '../root-reducer';

export const selectFlyToMapPosition = (state: AppState) => state.mapDomain.mapPosition;

export const selectShouldMapUpdatePositionAtZoomLevel = (state: AppState) => state.mapDomain.mapPositionZoom;

export const selectMapZoom = (state: AppState) => state.mapDomain.mapZoom;

export const selectBasemap = (state: AppState) => state.mapDomain.selectedBasemap;

export const selectMapBounds = (state: AppState) => state.mapDomain.mapBounds;
