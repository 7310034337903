import moment from 'moment';
import styled from 'styled-components';

export interface DateRange {
    startDate: Date;
    endDate: Date;
    displayText: string;
}

const DateRanges = (oldest: Date) => {
    const dates: DateRange[] = [];

    const previousMonth = moment(new Date()).subtract(1, 'months');
    const beginning = moment(oldest).add(1, 'months').date(1);
    const date = beginning;

    while (date.isBefore(previousMonth)) {
        const endDate = date.clone();
        const startDate = date.clone().subtract(1, 'months');
        dates.push({
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            displayText: startDate.format('MMMM YYYY'),
        });
        date.add(1, 'months');
    }

    //add last 30 days
    dates.push({
        startDate: moment(new Date()).subtract(1, 'months').toDate(),
        endDate: new Date(),
        displayText: 'Last 30 days',
    });
    //add all
    dates.push({
        startDate: moment(oldest).date(1).toDate(),
        endDate: new Date(),
        displayText: 'Display by date',
    });

    return dates.reverse();
};

export const getDateRanges = (oldest: Date) => {
    return DateRanges(oldest);
};

interface SatelliteDateFilterProps {
    date: DateRange;
    setDate: (dateRange: DateRange) => void;
    dateRanges: DateRange[];
}

const SatelliteDateFilter = (props: SatelliteDateFilterProps) => {
    return (
        <Container>
            <SelectIcon>
                <img src="/assets/sentinel-filter/calendar.svg" />
            </SelectIcon>
            <Select
                value={props.date.displayText}
                onChange={(e) => {
                    const displayText = e.target.value as string;
                    const value = props.dateRanges.find((t) => t.displayText === displayText) ?? props.dateRanges[0];
                    props.setDate(value);
                }}
            >
                {props.dateRanges.map((t, index) => {
                    return (
                        <Option key={index} value={t.displayText}>
                            {t.displayText}
                        </Option>
                    );
                })}
            </Select>
        </Container>
    );
};

export default SatelliteDateFilter;

const Container = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.primary.text}36;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const Select = styled.select`
    background-color: ${(props) => props.theme.primary.color};
    width: 100%;
    background-position: 200px 3px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline-width: 0;
    color: ${(props) => props.theme.primary.text};
    margin: 2px 2px 2px 0px;
    border: none;
    padding: 0px 7px;
    height: 22px;
    display: block;
    font-size: 14px;
    cursor: pointer;

    background-image: url(/assets/arrows/down-arrow.svg);
    background-size: 15px;
    background-repeat: no-repeat;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme.secondary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.secondary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme.primary.color};
        border-radius: 4px;
    }
`;

const Option = styled.option`
    color: ${(props) => props.theme.primary.text};
    padding: 2px 4px;
`;

const SelectIcon = styled.div`
    img {
        height: 16px;
        margin-top: -6px;
        opacity: 0.6;
    }
    height: 22px;
    margin: 2px;
    margin-right: 0px;
    padding: 0 6px 0 5px;
    display: inline-block;
`;
