interface PriceWithCurrencyProps {
    value?: number;
    currency?: string;
}

const PriceWithCurrency = ({ value, currency }: PriceWithCurrencyProps) => {

    const price = value ? value / 100 : 0;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return <>{`${currency?.toLowerCase() === 'aud' ? 'AUD' : 'US'} ${formatter.format(price)}`}</>;
};

export default PriceWithCurrency;
