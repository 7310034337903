import L from 'leaflet';
import { useEffect } from 'react';
import { ScaleControlProps, useMap } from 'react-leaflet';

const MapScaleControl = () => {
    const map = useMap();

    useEffect(() => {
        const scaleProps: ScaleControlProps = {
            position: 'bottomleft',
            maxWidth: 150,
        };

        const control = new L.Control.Scale(scaleProps);
        control.addTo(map);

        return () => {
            map.removeControl(control);
        };
    });

    return null;
};

export default MapScaleControl;
