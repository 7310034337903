import styled from 'styled-components';
import MenuSoar from './Menu/menu-soar';
import NavigationBar from '../NavigationBar/navbar';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import UserHelper from 'lib/user-helper';
import { useDispatch } from 'react-redux';
import { actionClearMap } from 'store/Map/actions';

const DashboardLayout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionClearMap());
    }, [dispatch]);

    if (!UserHelper.validateToken()) {
        return <Navigate replace to="/satellite" />;
    }

    return (
        <React.Fragment>
            <NavigationBar />
            <ProfileContainer>
                <MenuContainer>
                    <MenuSoar />
                </MenuContainer>
                <ContentContainer>
                    <Outlet />
                </ContentContainer>
            </ProfileContainer>
        </React.Fragment>
    );
};

export default DashboardLayout;

const ProfileContainer = styled.div`
    background-color: ${(props) => props.theme.secondary.color};
    color: white;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    display: flex;
    @media (max-width: 600px) {
        flex-direction: column;
        padding: 60px 5px 5px 5px;
    }
`;

const MenuContainer = styled.div`
    margin-left: 7px;
    margin-right: 10px;

    @media (max-width: 600px) {
        margin-right: 0px;
    }
`;

const ContentContainer = styled.div`
    width: calc(100vw - 368px);
    color: white !important;

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding-right: 0px;
    }
`;
