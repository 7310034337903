import { actionTypes as at } from './constants';
import { SatelliteArchiveOrderDTO, OrderType } from '../../api/api-supplier';

export const actionShoppingCartAddOrder = (order: SatelliteArchiveOrderDTO) => {
    return {
        type: at.SHOPPING_CART_ADD_SATELLITE_ARCHIVE,
        payload: order,
    };
};

export const actionShoppingCartRemoveOrder = (order: SatelliteArchiveOrderDTO) => {
    return {
        type: at.SHOPPING_CART_REMOVE_SATELLITE_ARCHIVE,
        payload: order,
    };
};

export const actionShoppingCartClear = () => {
    return {
        type: at.SHOPPING_CART_CLEAR,
    };
};

export const actionShoppingCartInitialize = (orders: SatelliteArchiveOrderDTO[]) => {
    return {
        type: at.SHOPPING_CART_INITIALIZE,
        payload: orders,
    };
};

export const actionShoppingCartOpenDrawer = (orderType: OrderType | undefined) => {
    return {
        type: at.SHOPPING_CART_OPEN_DRAWER,
        payload: orderType,
    };
};
