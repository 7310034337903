import styled from 'styled-components';
import React from 'react';

interface Props {
    header?: JSX.Element;
    children: React.ReactNode;
}

const PageContainer = (props: Props) => {
    return (
        <Container>
            {props.header && <ContentHeader>{props.header}</ContentHeader>}
            <ContentBody>{props.children}</ContentBody>
        </Container>
    );
};

export default PageContainer;

export const Container = styled.div`
    background-color: ${(props) => props.theme.primary.color};
`;

export const ContentHeader = styled.div`
    position: fixed;
    justify-content: space-between;
    top: 60px;
    height: 60px;
    width: 100vw;
    display: flex;
    background-color: ${(props) => props.theme.secondary.color};
    color: ${(props) => props.theme.primary.color};
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 10px;
    z-index: 1;

    h3 {
        color: ${(props) => props.theme.primary.color};
        justify-content: center;
        align-items: center;
        align-self: center;
        display: flex;
        font-size: ${(props) => props.theme.fontSize.large};
        font-weight: 600;
        margin: 0px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        display: flex;
        align-items: center;
        margin: unset;
        margin-right: 20px;
        text-wrap: nowrap;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        height: 55px;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        padding-top: 0px;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }

    @media only screen and (max-width: 800px) {
        h3 {
            font-size: 1rem;
        }
    }
`;

export const ContentBody = styled.div`
    padding: 120px 10px 10px 10px;

    color: ${(props) => props.theme.primary.text};
`;
