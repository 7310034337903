import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { LatLng, LatLngBounds } from 'leaflet';
import { Config } from './model';
import { UserDTO } from '../../api/model';

const defaultConfig: Config = {
    IS_LOADING_CONFIG: true,
    IS_AVAILABLE_FOR_PURCHASE: false,
    SUBDOMAIN: '',
    LOGO: '/assets/logos/soar_logo.png',
    MOBILE_LOGO: '/assets/logos/soar-icon.png',
    MOBILE_LOGO_ACTIVE: '/assets/logos/soar-icon-active.png',
    BEHAVIOURS: {
        FORCED_LOGIN: false,
        ROUTE_AFTER_LOGIN: '',
    },
    FEATURES: {
        SHOPPING_CART: false, // TODO can probably be removed
    },
};

interface StoreApp {
    config: Config;
    maintenance: boolean;
    showTutorial: boolean;
    showLoginDialog: boolean;
    showNavbarDropdown: boolean;
    showUploadDialog: boolean;
    loggedIn: boolean;
    myProfile?: UserDTO;
}

const initialAppState: StoreApp = {
    config: defaultConfig,
    maintenance: false,
    showTutorial: false,
    showLoginDialog: false,
    showNavbarDropdown: false,
    showUploadDialog: false,
    loggedIn: false,
    myProfile: undefined,
};

export default (state: StoreApp = initialAppState, action: Action): StoreApp => {
    switch (action.type) {
        case at.UPDATE_CONFIG:
            return { ...state, maintenance: false };
        case at.UPDATE_CONFIG_SUCCESS:
            return { ...state, maintenance: false, config: action.payload };
        case at.UPDATE_CONFIG_FAILURE:
            return { ...state, maintenance: true };

        case at.SHOW_TUTORIAL:
            return {
                ...state,
                showTutorial: true,
            };

        case at.DISMISS_TUTORIAL:
            return {
                ...state,
                showTutorial: false,
                showNavbarDropdown: false,
            };

        case at.SHOW_LOGIN_DIALOG:
            return {
                ...state,
                showLoginDialog: action.payload,
            };

        case at.SHOW_NAVBAR_DROPDOWN:
            return {
                ...state,
                showNavbarDropdown: action.payload,
            };

        case at.SHOW_UPLOAD_DIALOG:
            return {
                ...state,
                showUploadDialog: action.payload,
            };

        case at.API_GET_MY_PROFILE:
            return { ...state, myProfile: undefined, loggedIn: false };
        case at.API_GET_MY_PROFILE_SUCCESS:
            return { ...state, myProfile: action.payload, loggedIn: true };
        case at.API_GET_MY_PROFILE_ERROR:
            return { ...state, myProfile: undefined, loggedIn: false };
        case at.API_LOGIN_SUCCESS:
            return { ...state, loggedIn: true };
        case at.API_LOGIN_ERROR:
            return { ...state, myProfile: undefined, loggedIn: false };

        default:
            return state;
    }
};
