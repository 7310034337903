import React from 'react';
import styled from 'styled-components';

interface NoImageryModalProps {
    icon: string;
    title: string;
    text?: string;
    mobileText?: string;
}

const NoImageryModal = (props: NoImageryModalProps) => {
    const { icon, title, text } = props;
    return (
        <React.Fragment>
            <NoImageryContainer>
                <NoImageryImage src={icon} />
                <StyledTitle>
                    <h3>{title}</h3>
                </StyledTitle>
                {text ? <StyledText>{text}</StyledText> : null}
            </NoImageryContainer>
        </React.Fragment>
    );
};

export default NoImageryModal;
const NoImageryContainer = styled.div`
    text-align: center;
    height: 60vh;
    width: 100%;
    color: ${(props) => props.theme.primary.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -10px 0px -90px 0px;
    overflow-y: hidden;
`;

const NoImageryImage = styled.img`
    width: 130px;
    height: 130px;
`;

const StyledTitle = styled.div`
    max-width: 400px;
    color: ${(props) => props.theme.primary.text};

    h3 {
        color: ${(props) => props.theme.primary.text};
        margin-top: 10px;
    }
`;

const StyledText = styled.p`
    color: ${(props) => props.theme.primary.text};
    margin-top: 10px;
    max-width: 400px;
    font-size: 12px;
`;
