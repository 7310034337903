import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class CookieHelper {
    private static VISITED_KEY = 'visited';
    private static UTM_KEY = 'soar-utm';

    public static isFirstTime(): boolean {
        if (cookies.get(CookieHelper.VISITED_KEY)) {
            return false;
        } else {
            cookies.set(CookieHelper.VISITED_KEY, true, { path: '/' });
            return true;
        }
    }

    public static setTrackerCode(trackerCode: string) {
        cookies.set(CookieHelper.UTM_KEY, trackerCode, { path: '/' });
    }

    public static getTrackerCode(): string | undefined {
        return cookies.get(CookieHelper.UTM_KEY);
    }
}
