import { useEffect } from 'react';
import { LayersControl, TileLayer as LeafletTileLayer, useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../constants';
import { actionSetBasemap } from '../../store/Map/actions';
import { BasemapKey } from '../../store/Map/model';
import { selectBasemap } from '../../store/Map/selectors';

const GOOGLE_STREET_URL = Constants.MAPS.GOOGLE_STREET;
const GOOGLE_HYBRID_URL = Constants.MAPS.GOOGLE_HYBRID;
const OSM_STREET_URL = Constants.MAPS.OSM_STREET;
const OPEN_TOPO_URL = Constants.MAPS.OSM_TOPO;

const GOOGLE_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.GOOGLE_ATTRIBUTION;
const OSM_TOPO_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_TOPO_ATTRIBUTION;
const OSM_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_ATTRIBUTION;


const Basemaps = () => {
    const dispatch = useDispatch();
    const leaflet = useMap();
    const selectedBasemap = useSelector(selectBasemap);

    useEffect(() => {
        if (leaflet) {
            leaflet.on('baselayerchange', (e) => {
                dispatch(actionSetBasemap(e.name as BasemapKey));
            })
        }
    }, [leaflet, dispatch])

    return (
        <LayersControl position="bottomright">

            <LayersControl.BaseLayer name="Open Elevation" checked={selectedBasemap === 'Open Elevation'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={17} url={OPEN_TOPO_URL} attribution={OSM_TOPO_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="OpenStreetMaps" checked={selectedBasemap === 'OpenStreetMaps'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={OSM_STREET_URL} attribution={OSM_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Street" checked={selectedBasemap === 'Google Street'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={GOOGLE_STREET_URL} attribution={GOOGLE_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Hybrid" checked={selectedBasemap === 'Google Hybrid'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={GOOGLE_HYBRID_URL} attribution={GOOGLE_ATTRIBUTION} />
            </LayersControl.BaseLayer>

        </LayersControl>
    );
};

export default Basemaps;
