import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import { selectMyProfile } from 'store/App/selectors';

interface Props {
    toggle: () => void;
}

const RegistrationConfirmationModal = ({ toggle }: Props) => {
    const myProfile = useSelector(selectMyProfile);

    useEffect(() => {
        Analytics.Event('Registration', 'User successfully registered');
    }, []);

    return (
        <ModalContainer isOpen={true} zIndex={100000} toggle={toggle}>
            <CloseIcon onClick={toggle} />
            <ModalContent>
                <h3>
                    Congratulations <span>{myProfile?.firstName}</span>, you have officially registered to Pics from
                    Space!
                </h3>
            </ModalContent>
        </ModalContainer>
    );
};

export default RegistrationConfirmationModal;

const ModalContainer = styled(Modal)`
    margin-top: 100px;
    background-color: ${(props) => props.theme.primary.color};
    border-radius: 9px;
    border: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    width: 100%;

    h3 {
        text-align: left;
        color: ${(props) => props.theme.secondary.color};
        font-size: 1.7rem;
        margin-top: 15px;

        span {
            overflow-wrap: break-word;
        }
    }
`;

const ModalContent = styled.div`
    max-width: 500px;
    padding: 20px 15px 10px;
    border: none;
    background-color: ${(props) => props.theme.primary.color};
`;
const CloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='%236e322b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 25px;
    margin: 8px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    font-weight: bold;
`;
