export const actionTypes = {
    UPDATE_CONFIG: 'CONFIG/UPDATE_CONFIG',
    UPDATE_CONFIG_SUCCESS: 'CONFIG/UPDATE_CONFIG_SUCCESS',
    UPDATE_CONFIG_FAILURE: 'CONFIG/UPDATE_CONFIG_FAILURE',

    SHOW_TUTORIAL: 'TUTORIAL/SHOW_TUTORIAL',
    DISMISS_TUTORIAL: 'TUTORIAL/DISMISS_TUTORIAL',
    SHOW_LOGIN_DIALOG: 'LOGIN/SHOW_DIALOG',
    SHOW_NAVBAR_DROPDOWN: 'NAVBAR/SHOW_DROPDOWN',

    SHOW_UPLOAD_DIALOG: 'UPLOAD/SHOW_DIALOG',

    API_GET_MY_PROFILE: 'API/GET_MY_PROFILE',
    API_GET_MY_PROFILE_SUCCESS: 'API/GET_MY_PROFILE_SUCCESS',
    API_GET_MY_PROFILE_ERROR: 'API/GET_MY_PROFILE_ERROR',
    API_LOGIN_SUCCESS: 'API/LOGIN_SUCCESS',
    API_LOGIN_ERROR: 'API/LOGIN_ERROR',
};
