import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import countryList from 'react-select-country-list';
import { StyledButton } from '../Shared/Buttons/styled-button';
import { useDispatch, useSelector } from 'react-redux';
import { selectShoppingCartOrders } from 'store/ShoppingCart/selectors';
import { useEffect, useRef, useState } from 'react';
import { Industry } from 'api/model';
import StyledInput from 'components/Shared/Input/input';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import UserHelper from 'lib/user-helper';
import ApiSupplier, { Products } from 'api/api-supplier';
import { actionShoppingCartClear, actionShoppingCartInitialize } from 'store/ShoppingCart/actions';
import Analytics from 'lib/user-analytics';
import CheckoutTermsList from './checkout-terms-list';
import { useNavigate } from 'react-router-dom';
import LocalStorageUtil from 'lib/local-storage-util';
import PriceWithCurrency from './price-with-currency';
import ImagePreview from './image-preview';
import GoogleAnalytics from 'lib/google-analytics';

const moment = require('moment');

interface Props {
    stripe?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    onOrderComplete: () => void;
}

interface IFormErrors {
    name?: string;
    industry?: string;
    country?: string;
    summary?: string;
}

const Checkout = (props: Props) => {
    const cartOrders = useSelector(selectShoppingCartOrders);
    const [stripeError, setStripeError] = useState<Error | undefined>(undefined);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [stripeReady, setStripeReady] = useState(false);
    const [country, setCountry] = useState<string>(LocalStorageUtil.getCountry());

    const nameRef = useRef<HTMLInputElement>(null);
    const industryRef = useRef<HTMLInputElement>(null);
    const countryRef = useRef<HTMLInputElement>(null);
    const acceptTermsRef = useRef<HTMLInputElement>(null);

    const [formErrors, setFormErrors] = useState<IFormErrors>({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        LocalStorageUtil.saveCountry(country);

        //update shopping cart prices based on country change
        ApiSupplier.recalCalculatePrices(country).then((orders) => {
            dispatch(actionShoppingCartInitialize(orders));
            GoogleAnalytics.BeginCheckout(orders);
        });
    }, [country, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        //check for required fields: name, industry, country
        setFormErrors({});
        const errors: IFormErrors = {};
        let hasError = false;
        if (!nameRef.current?.value) {
            errors.name = 'Please enter your organisation or name';
            hasError = true;
        }
        if (!industryRef.current?.value || industryRef.current?.value === 'Undefined') {
            errors.industry = 'Please select your industry';
            hasError = true;
        }
        if (!countryRef.current?.value) {
            errors.country = 'Please select your country';
            hasError = true;
        }

        if (hasError) {
            errors.summary = 'Please complete the Billing information';
            setFormErrors(errors);
            return;
        }

        // Should be logged in, Should never happen
        else if (!UserHelper.validateToken()) {
            setStripeError(Error('You are not signed in.  Please sign in and try again'));
            return;
            // Precondition: User has sign in credentials
        } else if (props.stripe === undefined) {
            setStripeError(Error("Stripe.js hasn't loaded"));
            return;
        }

        setStripeLoading(true);
        setStripeError(undefined);

        props.stripe
            .createToken()
            .then((payload) => {
                if (payload.error) {
                    throw new Error(payload.error.message);
                }

                if (!payload.token) {
                    // Unsure if this can happen - no error and no token from stripe;
                    throw new Error('There was an error with the payment gateway.  Please try again');
                }

                return payload.token.id;
            })
            .then((stripeToken) => {
                if (!!acceptTermsRef.current && !acceptTermsRef.current.checked) {
                    throw new Error('Please accept the terms and agreement.');
                } else {
                    setStripeError(undefined);
                    ApiSupplier.createMultipleOrders(
                        cartOrders,
                        nameRef.current?.value || '',
                        industryRef.current?.value || '',
                        countryRef.current?.value || '',
                        stripeToken
                    )
                        .then((orders) => {
                            dispatch(actionShoppingCartClear());
                            props.onOrderComplete();
                            GoogleAnalytics.Purchased(stripeToken, orders);
                        })
                        .catch((error) => setStripeError(error))
                        .finally(() => setStripeLoading(false));
                }
            })
            .catch((error) => {
                setStripeError(error);
                setStripeLoading(false);
            });
        Analytics.Event('Checkout', 'Clicked Place order button');
    };

    const handleBack = () => {
        navigate('/cart');
        Analytics.Event('Checkout', 'Clicked Back');
    };

    return (
        <CheckoutContainer>
            {cartOrders.length === 0 ? (
                <FloatingContainer>
                    <h2>Your cart is empty</h2>
                </FloatingContainer>
            ) : (
                <Row>
                    <Col sm={7}>
                        <FloatingContainer>
                            <FixedWidthContainer className="ml-5 mr-5 mt-3">
                                <h2>Billing information</h2>

                                <FormGroup>
                                    <Label for="fullName">Name/Company</Label>
                                    <StyledInput
                                        id="fullName"
                                        innerRef={nameRef}
                                        invalid={!!formErrors.name}
                                        onClick={() => Analytics.Event('Checkout', 'Clicked Name on billing details')}
                                        onChange={(e) => {
                                            if (e.currentTarget.value) {
                                                setFormErrors({ ...formErrors, name: undefined, summary: undefined });
                                            }
                                        }}
                                    />
                                    <FormFeedback>{formErrors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="industry">Industry</Label>
                                    <StyledInput
                                        id="industry"
                                        type="select"
                                        innerRef={industryRef}
                                        invalid={!!formErrors.industry}
                                        onClick={() =>
                                            Analytics.Event('Checkout', 'Clicked Industry on billing details')
                                        }
                                        onChange={(e) => {
                                            if (e.currentTarget.value) {
                                                setFormErrors({
                                                    ...formErrors,
                                                    industry: undefined,
                                                    summary: undefined,
                                                });
                                            }
                                        }}
                                    >
                                        {Object.keys(Industry).map((key) => {
                                            return (
                                                <option key={key} value={key}>
                                                    {Industry[key]}
                                                </option>
                                            );
                                        })}
                                    </StyledInput>
                                    <FormFeedback>{formErrors.industry}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="country">Billing Country</Label>
                                    <StyledInput
                                        id="country"
                                        type="select"
                                        innerRef={countryRef}
                                        defaultValue={country}
                                        invalid={!!formErrors.country}
                                        onClick={() =>
                                            Analytics.Event('Checkout', 'Clicked Country on billing details')
                                        }
                                        onChange={(e) => {
                                            if (e.currentTarget.value) {
                                                setCountry(e.currentTarget.value);
                                                setFormErrors({
                                                    ...formErrors,
                                                    country: undefined,
                                                    summary: undefined,
                                                });
                                            }
                                        }}
                                    >
                                        <option key="Your country" value="">
                                            Select country
                                        </option>
                                        {countryList()
                                            .getData()
                                            .map((key) => {
                                                if (key.label === 'Antarctica') return null;
                                                return (
                                                    <option key={key.label} value={key.value}>
                                                        {key.label}
                                                    </option>
                                                );
                                            })}
                                    </StyledInput>
                                    <FormFeedback>{formErrors.country}</FormFeedback>
                                </FormGroup>
                            </FixedWidthContainer>
                            <div className="ml-5 mr-5 mt-5">
                                <h2>Payment details</h2>

                                <Form onSubmit={handleSubmit}>
                                    <FixedWidthContainer>
                                        <FormGroup data-sentry-block>
                                            <Label>Card Number</Label>
                                            <SoarCreditNumberElement onReady={() => setStripeReady(true)} />
                                            <CreditCardIcons src="/assets/payments/powered_by_stripe.svg" />
                                        </FormGroup>

                                        <ExpiryAndCVCContainer data-sentry-block>
                                            <ExpiryContainer>
                                                <Label>Expiry</Label>
                                                <SoarCardExpiryElement />
                                            </ExpiryContainer>

                                            <CVCContainer>
                                                <Label>CVC</Label>
                                                <SoarCardCVCElement />
                                            </CVCContainer>
                                        </ExpiryAndCVCContainer>

                                        <FormGroup className="mt-3">
                                            <Label>Card holder name</Label>
                                            <StyledInput
                                                data-sentry-block
                                                name="cc-name"
                                                type="text"
                                                id="name"
                                                placeholder="Name on card"
                                                onClick={() => Analytics.Event('Checkout', 'Clicked Card Holder Name')}
                                            />
                                        </FormGroup>
                                    </FixedWidthContainer>

                                    <div className="mt-4">
                                        <CheckoutTermsList orderTypes={cartOrders.map((o) => o.orderType)} />
                                        <div className="ml-3">
                                            <FormGroup>
                                                <StyledInput
                                                    name="checked-accept-all"
                                                    type="checkbox"
                                                    innerRef={acceptTermsRef}
                                                    id="accept-terms"
                                                    onClick={() => Analytics.Event('Checkout', 'Clicked Accept Terms')}
                                                />
                                                <Label for="accept-terms" className="mb-0">
                                                    <span>
                                                        By processing with my order, I confirm that I have read,
                                                        understood and agree to comply with the aforementioned&nbsp;
                                                        <a href="/terms" target="_blank">
                                                            Terms of Service
                                                        </a>
                                                        &nbsp;and&nbsp;
                                                        <a href="/cg_sat_eula" target="_blank">
                                                            EULA
                                                        </a>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                        </div>

                                        <div className="d-flex flex-column align-items-center">
                                            {formErrors.summary ? (
                                                <ErrorMessage>{formErrors.summary}</ErrorMessage>
                                            ) : stripeError ? (
                                                <ErrorMessage>{stripeError.message}</ErrorMessage>
                                            ) : null}

                                            <div className="d-flex ">
                                                {!stripeLoading && (
                                                    <StyledButton onClick={handleBack}>
                                                        <i className="fa fa-chevron-left mr-2"></i>BACK
                                                    </StyledButton>
                                                )}
                                                <PlaceOrderButton
                                                    disabled={stripeLoading || !stripeReady}
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                    className="ml-3"
                                                >
                                                    {stripeLoading ? (
                                                        <LoadingSpinner
                                                            data-testid="satellite-order-loading"
                                                            className="fa fa-spinner fa-spin"
                                                        />
                                                    ) : (
                                                        'YES! PLACE MY ORDER'
                                                    )}
                                                </PlaceOrderButton>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </FloatingContainer>
                    </Col>
                    <Col sm={5}>
                        <FloatingContainer>
                            <OrderSummaryHeader>
                                <h2>Order summary</h2>
                            </OrderSummaryHeader>
                            <div>
                                {cartOrders.map((item, index) => {
                                    return (
                                        <ItemRow key={index}>
                                            <Col sm={8}>
                                                {item.orderType === 'ARCHIVE' ? (
                                                    <div className="d-flex">
                                                        <PreviewContainer>
                                                            <ImagePreview previewUrl={item.previewUrl} />
                                                        </PreviewContainer>
                                                        <DetailsContainer className="ml-2">
                                                            <SatelliteDetail>
                                                                <b>Image date: </b>
                                                                {moment(item.date).format('MMM Do YYYY')}
                                                            </SatelliteDetail>
                                                            <SatelliteDetail>
                                                                <b>Total Area: </b>
                                                                {item.archiveSelectedAreaInKm}km²
                                                            </SatelliteDetail>
                                                            <SatelliteDetail>
                                                                <b>Resolution: </b>
                                                                {Products[item.productKey]?.resolution}
                                                            </SatelliteDetail>
                                                            <SatelliteDetail>
                                                                <b>Cloud Cover: </b>
                                                                {item.cloud}%
                                                            </SatelliteDetail>
                                                        </DetailsContainer>
                                                    </div>
                                                ) : item.orderType === 'NEWCOLLECT' ? (
                                                    <div className="d-flex">
                                                        <PreviewContainer>
                                                            <div>
                                                                <img
                                                                    src="/assets/floating-drawer-icons/icon-new-collect-brown.svg"
                                                                    className="p-3 newcollect"
                                                                />
                                                            </div>
                                                        </PreviewContainer>
                                                        <DetailsContainer className="ml-2">
                                                            <SatelliteDetail>
                                                                <b>New image! </b>
                                                            </SatelliteDetail>
                                                            <SatelliteDetail>
                                                                <b>Total Area: </b>
                                                                {item.areaInKm}km²
                                                            </SatelliteDetail>
                                                            <SatelliteDetail>
                                                                <b>Resolution: </b>
                                                                {Products[item.productKey]?.resolution}
                                                            </SatelliteDetail>
                                                        </DetailsContainer>
                                                    </div>
                                                ) : null}
                                            </Col>
                                            <Col sm={4}>
                                                <PriceDetail>
                                                    <PriceWithCurrency
                                                        value={item.totalPrice}
                                                        currency={item.currency}
                                                    />
                                                </PriceDetail>
                                            </Col>
                                        </ItemRow>
                                    );
                                })}
                            </div>
                            <FooterRow>
                                <Col sm={8} className="m-auto d-flex justify-content-center">
                                    <b>Total</b>
                                </Col>
                                <Col sm={4} className="m-auto">
                                    <PriceDetail>
                                        <b>
                                            <PriceWithCurrency
                                                value={cartOrders.reduce((sum, current) => sum + current.totalPrice, 0)}
                                                currency={cartOrders[0].currency}
                                            />
                                        </b>
                                        {cartOrders[0].currency.toLowerCase() === 'aud' && <div>Inc. GST</div>}
                                    </PriceDetail>
                                </Col>
                            </FooterRow>
                        </FloatingContainer>
                    </Col>
                </Row>
            )}
        </CheckoutContainer>
    );
};

export default injectStripe(Checkout);

const CheckoutContainer = styled.div`
    margin: 20px auto;
    padding: 20px;
`;

const FloatingContainer = styled.div`
    background: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    box-shadow: ${(props) => props.theme.boxShadow.deep};
    padding: 20px;
`;

const DetailsContainer = styled.div`
    overflow-wrap: anywhere;
`;

const FixedWidthContainer = styled.div`
    max-width: 600px;
`;

const Form = styled.form`
    padding: 0px;

    a:hover {
        color: ${(props) => props.theme.primary.text};
    }
`;

const ExpiryAndCVCContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
`;

const ExpiryContainer = styled.div`
    flex: 1;
    margin: 0 18px 0 0;
    width: 120px;
`;

const CVCContainer = styled.div`
    flex: 1;
    width: 120px;
`;

const ErrorMessage = styled.p`
    color: #dc3545;
`;

const CreditCardCSS = `
    text-align: left;
    display: block;
    height: 40px;
    padding: 12px 12px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.2em;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Exo 2', sans-serif !important;

    background-color: transparent !important;
    color: #6E322Bcc !important;
    border: 1px solid #6E322B99 !important;

    :hover,
    :active,
    :focus, 
    :focus-visible {
        border: 1.5px solid #6E322B99 !important;
        box-shadow: 0 0 0 0.2rem #6E322B1f !important;
    }
`;

const SoarCreditNumberElement = styled(CardNumberElement)`
    ${CreditCardCSS}
    width: 100%;
`;

const SoarCardExpiryElement = styled(CardExpiryElement)`
    ${CreditCardCSS}
    width: 90px;
`;

const SoarCardCVCElement = styled(CardCVCElement)`
    ${CreditCardCSS}
    width: 90px;
`;

const CreditCardIcons = styled.img`
    max-height: 30px;
    float: right;
    margin-top: -35px;
    margin-right: 3px;
`;

const FooterRow = styled(Row)`
    margin-top: 20px;
    font-size: 18px;
    border-top-width: 0.5px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.secondary.color}30;
    padding: 10px;
`;

const ItemRow = styled(Row)`
    margin-top: 10px;
    padding-top: 10px;
    border-top-width: 0.5px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.secondary.color}30;
`;

const PreviewContainer = styled.div`
    div {
        margin-top: 5px;
        width: 90px;
        height: 90px;
        text-align: center;

        img {
            width: 90px;
            height: 90px;

            &.newcollect {
                width: 80px;
                height: 80px;
            }
        }
    }
`;

const SatelliteDetail = styled.div`
    margin: 0px 5px 5px 0px;
    text-wrap: nowrap;

    b {
        display: inline-block;
        width: 110px;
    }
`;

const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LoadingSpinner = styled.i`
    position: relative;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: ${Rotate} 1s linear infinite;
    color: ${(props) => props.theme.primary.text};
`;

const OrderSummaryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    button {
        margin: unset;
        padding: 8px 15px;
    }
`;

const PriceDetail = styled.div`
    text-wrap: nowrap;
    text-align: right;
`;

const PlaceOrderButton = styled(StyledButton)`
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 1rem;
`;
